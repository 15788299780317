import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import Box from "../../layout/Box";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../_foundation/_buttons";

class AdminLicense extends Component {
  state = {
    license: null,
    availableModules: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <AdminLayout
        nav="license"
        breadcrumbs={["admin_dashboard", "admin_license"]}
      >
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={24}>
              <h1>{_c.translate("pageTitles.admin.license")}</h1>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={24}>
              {this.view__showLicense()}
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showLicense() {
    const { license, availableModules } = this.state;
    const _c = this.props.context;

    if (!license) {
      return;
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            <Box>
              <h3>{_c.translate("admin.license.instance.name")}</h3>

              <p>{_c.translate("admin.license.instance.text")}</p>

              <AdminDataField
                value={license.instance}
                editValue={license.instance}
                editType="text"
                label={_c.translate("admin.license.instance.name")}
                editable={false}
                edit={false}
              />
            </Box>
          </Cell>
          <Cell sm={24} md={12}>
            <Box>
              <h3>{_c.translate("admin.license.active_until.name")}</h3>

              <div className="admin-license-active-until">
                {license.active_until_f}
              </div>
            </Box>
            <Box>
              <h3>{_c.translate("admin.license.modules.name")}</h3>

              <div className="admin-license-modules">
                <ul>
                  {availableModules.map((moduleKey, index) => {
                    let classes = "admin-license-module";
                    let v__icon = <Icon icon="square-o" />;

                    if (
                      license.modules.includes(moduleKey) ||
                      moduleKey === "home" ||
                      moduleKey === "admin"
                    ) {
                      classes = `selected ${classes}`;
                      v__icon = <Icon icon="check-square-o" />;
                    } else {
                      classes = `not-selected ${classes}`;
                    }

                    return (
                      <li key={index} className={classes}>
                        {v__icon} {_c.translate(`modules.${moduleKey}`)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Box>
          </Cell>
        </Row>
      </Grid>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.license");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { license, availableModules } = apiResponseData;

            this.setState({ license, availableModules });
          }

          _c.setPageTitle(_c.translate("pageTitles.admin.dashboard"));
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:license:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:license:init");
      });
  }
}

export default withContext(AdminLicense);
