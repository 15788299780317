import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import { Icon } from "../../_foundation/_buttons";
import Loading from "../../_reusables/Loading";
import ColorPicker from "../../_reusables/ColorPicker";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

class AdminDesign extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_design"],
    styles: null,
    fonts: null,
    status: "LOADING",
    changesWereMade: false,
    delayTimer: null,
    delayTime: 500, // Milliseconds
    reset: null,
    previewUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/home`,
    categories: {
      default_colors: {
        items: [
          {
            key: "$primaryColor",
            type: "color",
          },
          {
            key: "$secondaryColor",
            type: "color",
          },
          {
            type: "separator",
          },
          {
            key: "$bodyBackground",
            type: "color",
          },
          {
            type: "separator",
          },
          {
            key: "$textColor",
            type: "color",
          },
          {
            key: "$textGray",
            type: "color",
          },
          {
            key: "$textLightGray",
            type: "color",
          },
          {
            key: "$linkColor",
            type: "color",
          },
          {
            key: "$headingColor",
            type: "color",
          },
          {
            key: "$hoverColor",
            type: "color",
          },
          {
            key: "$modalBackground",
            type: "color",
          },
        ],
      },
      fonts: {
        items: [
          {
            key: "primaryFontFamily",
            type: "font",
          },
          {
            type: "separator",
          },
          {
            key: "headingFontFamily",
            type: "font",
          },
        ],
      },
      boxes: {
        items: [
          {
            key: "$boxBackground",
            type: "color",
          },
          {
            key: "$boxBorderColor",
            type: "color",
          },
        ],
      },
      buttons: {
        items: [
          {
            key: "buttons",
            type: "title",
          },
          {
            key: "$buttonBackground",
            type: "color",
          },
          {
            key: "$buttonColor",
            type: "color",
          },
          {
            key: "$buttonBorderColor",
            type: "color",
          },
          {
            key: "hollowButtons",
            type: "title",
          },
          {
            key: "$hollowButtonBackground",
            type: "color",
          },
          {
            key: "$hollowButtonColor",
            type: "color",
          },
          {
            key: "$hollowButtonBorderColor",
            type: "color",
          },
        ],
      },
      topbar: {
        items: [
          {
            key: "topbar",
            type: "title",
          },
          {
            key: "$topBackground",
            type: "color",
          },
          {
            key: "$topUsernameColor",
            type: "color",
          },
          {
            key: "$topBorderColor",
            type: "color",
          },
          {
            key: "breadcrumbs",
            type: "title",
          },
          {
            key: "$breadcrumbsBackground",
            type: "color",
          },
          {
            key: "$breadcrumbsColor",
            type: "color",
          },
          {
            key: "$breadcrumbsLinkColor",
            type: "color",
          },
          {
            key: "$breadcrumbsBottomBorderColor",
            type: "color",
          },
        ],
      },
      main_menu: {
        items: [
          {
            key: "$menuLinkColor",
            type: "color",
          },
          {
            key: "$menuLinkHoverBackground",
            type: "color",
          },
          {
            key: "$menuLinkHoverColor",
            type: "color",
          },
        ],
      },
      forms: {
        items: [
          {
            key: "$inputBackground",
            type: "color",
          },
          {
            key: "$inputColor",
            type: "color",
          },
        ],
      },
      community: {
        items: [
          {
            key: "$communityPostLink",
            type: "color",
          },
          {
            key: "$communityFormBackground",
            type: "color",
          },
          {
            key: "$communityFormColor",
            type: "color",
          },
        ],
      },
      routines: {
        items: [
          {
            key: "thisMonth",
            type: "title",
          },
          {
            key: "$routinesBorderColorThisMonth",
            type: "color",
          },
          {
            key: "$routinesTextColorThisMonth",
            type: "color",
          },
          {
            key: "notThisMonth",
            type: "title",
          },
          {
            key: "$routinesBorderColorNotThisMonth",
            type: "color",
          },
          {
            key: "$routinesInactiveBackground",
            type: "color",
          },
          {
            key: "$routinesInactiveColor",
            type: "color",
          },
          {
            key: "routinesToday",
            type: "title",
          },
          {
            key: "$routinesBorderColorToday",
            type: "color",
          },
          {
            key: "routinesWeekend",
            type: "title",
          },
          {
            key: "$routinesWeekendBackground",
            type: "color",
          },
          {
            key: "$routinesWeekendColor",
            type: "color",
          },
        ],
      },
      footer: {
        items: [
          {
            key: "$footerBackground",
            type: "color",
          },
          {
            key: "$footerColor",
            type: "color",
          },
        ],
      },
    },
    view: "lobby",
  };

  componentDidMount() {
    /*window.onbeforeunload = function () {
      return "Änderungen werden nicht gespeichert. Möchtest Du wirklich fortfahren?";
    };*/
    const _c = this.props.context;
    _c.showLoadingScreen();

    const handler = (event) => {
      const { previewUrl } = this.state;
      try {
        if (event.data) {
          const data = JSON.parse(event.data);
          //console.log("URL?", data.url);
          //console.log("PreviewURL?", previewUrl);
          if (data && data.url && data.url !== previewUrl) {
            //console.log("WHY NOT HERE?");
            this.setState({ previewUrl: data.url });
          }
        }
      } catch {}
    };

    window.addEventListener("message", handler);

    this.init();
  }

  render() {
    const { breadcrumbs, changesWereMade } = this.state;
    const _c = this.props.context;

    return (
      <div className="hide-nav">
        <AdminLayout nav="design" breadcrumbs={breadcrumbs} fullpage={true}>
          <div className="admin-design-menu">
            {this.view__showButtons()}
            <div className="admin-design-menu-content">
              <div className="admin-back-button">
                <Link
                  to={_c.config("urls.admin.admin")}
                  onClick={(e) => {
                    if (
                      changesWereMade === true &&
                      !window.confirm(
                        _c.translate("admin.design.leave.confirm")
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                >
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zum
                    Administrationsbereich
                  </small>
                </Link>
              </div>
              <h3>{_c.translate("admin.design.lobby.title")}</h3>
              <div>{_c.translate("admin.design.lobby.text")}</div>
              <div>&nbsp;</div>
              {this.view__showMenu()}
            </div>
          </div>
          <div className="admin-design-content">{this.view__showIframe()}</div>
        </AdminLayout>
      </div>
    );
  }

  view__showButtons() {
    const _c = this.props.context;

    return (
      <div className="admin-design-menu-buttons">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        {/*<button
          className="small secondary hollow button"
          data-tip={_c.translate("admin.design.buttons.reset.tooltip")}
        >
          <Icon icon="refresh" left />{" "}
          {_c.translate("admin.design.buttons.reset.label")}
        </button>*/}
        <button
          onClick={this.handle__save}
          className="small primary hollow button"
          data-tip={_c.translate("admin.design.buttons.save.tooltip")}
        >
          <Icon icon="check" left />{" "}
          {_c.translate("admin.design.buttons.save.label")}
        </button>
      </div>
    );
  }

  view__showMenu() {
    const { styles, categories, view } = this.state;

    if (!styles) {
      return null;
    }

    if (categories[view]) {
      return this.view__showCategory(view);
    } else {
      return this.view__showLobby();
    }
  }

  view__showLobby() {
    const { categories } = this.state;
    const _c = this.props.context;

    return (
      <div className="admin-design-menu-lobby">
        <ul>
          {Object.keys(categories).map((categoryKey, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  this.setState({ view: categoryKey });
                }}
              >
                <div className="float-right">
                  <Icon icon="angle-right" />
                </div>
                {_c.translate(`admin.design.categories.${categoryKey}.title`)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showCategory(categoryKey) {
    const _c = this.props.context;
    const { categories } = this.state;
    const category = categories[categoryKey];

    return (
      <div className="admin-design-menu-category">
        <button
          className="admin-design-menu-title"
          onClick={() => {
            this.setState({ view: "lobby" });
          }}
        >
          <Icon icon="angle-left" left />{" "}
          {_c.translate(`admin.design.categories.${categoryKey}.title`)}
        </button>
        <div className="admin-design-menu-text">
          {_c.translate(`admin.design.categories.${categoryKey}.text`)}
        </div>
        <div>&nbsp;</div>
        {category.items.map((item, index) => {
          return (
            <div key={index}>{this.view__showStyle(item.key, item.type)}</div>
          );
        })}
      </div>
    );
  }

  view__showStyle(key, type) {
    const { styles, reset } = this.state;
    const _c = this.props.context;

    let v__output;

    if (type === "separator") {
      v__output = <hr />;
    } else if (type === "title") {
      v__output = (
        <div className="admin-design-subtitle">
          {_c.translate(`admin.design.titles.${key}`)}
        </div>
      );
    } else if (type === "color") {
      v__output = (
        <Grid type="full">
          <Row>
            <Cell sm={6}>
              <ColorPicker
                size="small"
                color={styles[key].admin_value}
                reset={reset}
                onChange={(color) => {
                  this.handle__saveStyle(key, color);
                }}
              />
            </Cell>
            <Cell sm={18}>
              <span className="admin-design-color-text">
                {_c.translate(`admin.design.styles.${key.replace("$", "")}`)}
              </span>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "font") {
      const { fonts } = this.state;

      v__output = (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <div className="admin-design-font-select">
                <select
                  value={styles[key].admin_value}
                  onChange={(e) => {
                    const fontKey = e.target.value;
                    this.handle__saveStyle(key, fontKey);
                  }}
                >
                  {Object.keys(fonts).map((fontKey, index) => {
                    return (
                      <option key={index} value={fontKey}>
                        {fonts[fontKey].name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin-design-font-text">
                {_c.translate(`admin.design.styles.${key.replace("$", "")}`)}
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }

  view__showIframe() {
    const { styles, status, previewUrl } = this.state;

    if (status === "LOADING") {
      return (
        <div>
          <Loading show={true} />
        </div>
      );
    }

    if (!styles) {
      return null;
    }

    return (
      <React.Fragment>
        <iframe
          ref={this.iframeRef}
          title="Design"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          src={previewUrl}
        ></iframe>
      </React.Fragment>
    );
  }

  saveStyle = (key, value) => {
    this.setState({ status: "LOADING" });

    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.designStyle");

    axios
      .patch(
        apiUrl,
        {
          key,
          value,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { styles } = response.data.data;
            this.setState({ styles, status: "LOADED", changesWereMade: true });
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:design:style:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:design:style");
      });
  };

  handle__saveStyle = (key, value) => {
    let { delayTimer, delayTime, styles } = this.state;

    styles[`$bodyBackground`].admin_value = value;
    this.setState({ styles });

    clearTimeout(delayTimer);
    delayTimer = setTimeout(
      function () {
        this.saveStyle(key, value);
      }.bind(this),
      delayTime
    );
    this.setState({ delayTimer });
  };

  handle__save = () => {
    const _c = this.props.context;

    if (!window.confirm(_c.translate("admin.design.buttons.save.confirm"))) {
      return null;
    }

    const apiUrl = _c.apiUrl("admin.design");

    axios
      .patch(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { styles } = response.data.data;
            this.setState({ styles, status: "LOADED", changesWereMade: false });

            _c.createNotifictation(
              _c.translate("admin.design.buttons.save.saved.title"),
              _c.translate("admin.design.buttons.save.saved.text"),
              "success"
            );
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:design:save:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:design:save");
      });
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.design");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { styles, fonts } = response.data.data;
            this.setState({ styles, fonts, status: "LOADED" });
          }
          _c.setPageTitle(_c.translate("pageTitles.admin.design"));
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:design:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:design:init");
      });
  }
}

export default withContext(AdminDesign);
