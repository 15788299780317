import React, { Component } from "react";
import Breadcrumbs from "../../topbars/Breadcrumbs";
import MainTopbar from "../../topbars/MainTopbar";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Footer from "../../layout/Footer";
import withContext from "../../../context/contextHOC";

class CourseLayout extends Component {
  state = {
    course: this.props.course,
    lessons: this.props.lessons,
    lesson: this.props.lesson,
    breadcrumbs: this.props.breadcrumbs,
    buttons: this.props.buttons,
    menu: this.props.menu,
    status: this.props.status,
    help: this.props.help,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson });
    }

    if (prevProps.breadcrumbs !== this.props.breadcrumbs) {
      this.setState({ breadcrumbs: this.props.breadcrumbs });
    }

    if (prevProps.buttons !== this.props.buttons) {
      this.setState({ buttons: this.props.buttons });
    }

    if (prevProps.menu !== this.props.menu) {
      this.setState({ menu: this.props.menu });
    }
  }

  render() {
    const { breadcrumbs, help, buttons, menu } = this.state;
    const _c = this.props.context;

    const showHelp = _c.custom("general.showHelp");

    let mainContentStyles = {};

    let backgroundUrl = _c.setting(`design_bg_course`);
    if (backgroundUrl) {
      mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
    }

    if (showHelp === true && help) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={20}>
              <div className="page page-course page-with-breadcrumbs">
                <MainTopbar type="course" />
                <Breadcrumbs
                  breadcrumbs={breadcrumbs}
                  buttons={buttons}
                  menu={menu}
                />
                <main className="content" style={mainContentStyles}>
                  <Grid>
                    <Row>
                      <Cell sm={24}>{this.view__showContent()}</Cell>
                    </Row>
                  </Grid>
                </main>
                <Footer />
              </div>
            </Cell>
            <Cell md={4} className="hide-for-small-only global-help">
              <div className="global-help-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate(`help.${help}.text`),
                  }}
                />
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <div className="page page-course page-with-breadcrumbs">
          <MainTopbar type="course" />
          <Breadcrumbs breadcrumbs={breadcrumbs} buttons={buttons} />
          <main className="content" style={mainContentStyles}>
            <Grid>
              <Row>
                <Cell sm={24}>{this.view__showContent()}</Cell>
              </Row>
            </Grid>
          </main>
          <Footer />
        </div>
      );
    }
  }

  view__showContent() {
    const { status, course, lesson, lessons } = this.state;
    const _c = this.props.context;

    if (
      (status === "INIT" || !course || !lesson || !lessons) &&
      !status.includes("NOTFOUND")
    ) {
      return _c.loading();
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withContext(CourseLayout);
