import React, { useContext, useEffect } from "react";
import { PtContext } from "../../../context/ptProvider";
import Loading from "../../_reusables/Loading";
import AffiliateHelper from "./AffiliateHelper";

function Affiliate(props) {
  const _c = useContext(PtContext);

  useEffect(() => {
    let affiliateIdentifier;
    try {
      affiliateIdentifier = props.match.params.affiliateIdentifier;
      if (affiliateIdentifier) {
        AffiliateHelper.setAffiliate(affiliateIdentifier);
      }
    } catch (e) {
      //
    }

    AffiliateHelper.countClick(affiliateIdentifier, _c, () => {
      window.location.href = "https://www.membermate.de";
    });
  }, [props]);

  return (
    <div className="text-center" style={{ padding: "100px 0" }}>
      <Loading show={true} />
    </div>
  );
}

export default Affiliate;
