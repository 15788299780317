import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../_foundation/foundation";
import { DateTime } from "luxon";

class Countdown extends Component {
  state = {
    date: this.props.date,
    type: this.props.type,
    remainingTime: null,
    timer: null,
  };

  componentDidMount() {
    this.startCountdown();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date) {
      this.setState({ date: this.props.date }, () => {
        this.calculateRemainingTime();
      });
    }
  }

  render() {
    return (
      <div className="mm-countdown" id={this.props.id || "countdown"}>
        {this.show__content()}
      </div>
    );
  }

  show__content() {
    const { remainingTime } = this.state;

    if (remainingTime !== null) {
      if (remainingTime.total <= 0) {
        return this.props.children || "";
      } else {
        if (
          remainingTime.days <= 0 &&
          remainingTime.hours <= 0 &&
          remainingTime.minutes <= 10
        ) {
          return (
            <div className="mm-countdown-alert">{this.show__countdown()}</div>
          );
        }

        return this.show__countdown();
      }
    } else {
      return "----";
    }
  }

  show__countdown() {
    const { remainingTime } = this.state;
    const _c = this.props.context;

    let days = _c.translate("live.countdown.days");
    if (remainingTime.days === 1) {
      days = _c.translate("live.countdown.day");
    }

    let hours = _c.translate("live.countdown.hours");
    if (remainingTime.hours === 1) {
      hours = _c.translate("live.countdown.hour");
    }

    let minutes = _c.translate("live.countdown.minutes");
    if (remainingTime.minutes === 1) {
      minutes = _c.translate("live.countdown.minute");
    }

    let seconds = _c.translate("live.countdown.seconds");
    if (remainingTime.seconds === 1) {
      seconds = _c.translate("live.countdown.second");
    }

    if (remainingTime.days === 0) {
      return (
        <Grid>
          <Row>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">{hours}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">{minutes}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.seconds || "0"}
              </div>
              <div className="mm-countdown-caption">{seconds}</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Row>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.days || "0"}
              </div>
              <div className="mm-countdown-caption">{days}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">{hours}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">{minutes}</div>
            </Cell>
          </Row>
        </Grid>
      );
    }

    /*if (type === "home") {
      return (
        <Grid>
          <Row>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.days || "0"}
              </div>
              <div className="mm-countdown-caption">{days}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">{hours}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">{minutes}</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "live") {
      return (
        <Grid>
          <Row>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.days || "0"}
              </div>
              <div className="mm-countdown-caption">{days}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">{hours}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">{minutes}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.seconds || "0"}
              </div>
              <div className="mm-countdown-caption">{seconds}</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "live") {
      return (
        <Grid>
          <Row>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.days || "0"}
              </div>
              <div className="mm-countdown-caption">{days}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">{hours}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">{minutes}</div>
            </Cell>
            <Cell sm={6} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.seconds || "0"}
              </div>
              <div className="mm-countdown-caption">{seconds}</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "live-grid") {
      if (remainingTime.days !== 1) {
        days = "Tage";
      }

      return (
        <Grid>
          <Row>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.days || "0"}
              </div>
              <div className="mm-countdown-caption">{days}</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.hours || "0"}
              </div>
              <div className="mm-countdown-caption">Std</div>
            </Cell>
            <Cell sm={8} classes="text-center">
              <div className="mm-countdown-time">
                {remainingTime.minutes || "0"}
              </div>
              <div className="mm-countdown-caption">Min</div>
            </Cell>
          </Row>
        </Grid>
      );
    }*/
  }

  calculateRemainingTime() {
    const { date } = this.state;

    const t = date - DateTime.local();
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));

    const remainingTime = {
      total: t,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };

    this.setState({ remainingTime });

    //console.log(remainingTime);

    if (t <= 0) {
      const { timer } = this.state;
      clearInterval(timer);
      this.setState({ timer });
      if (this.props.onZero) {
        this.props.onZero();
      }
    }
  }

  startCountdown() {
    let timerSeconds = 1;

    this.calculateRemainingTime();
    const timer = setInterval(
      this.calculateRemainingTime.bind(this),
      timerSeconds * 1000
    );
    this.setState({ timer });
  }
}

export default withContext(Countdown);
