import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import DefaultLayout from "../../layout/DefaultLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";

import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import { cloneDeep } from "lodash";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class Visionboard extends Component {
  state = {
    breadcrumbs: ["home", "visionboard"],
    visionboard: null,
    newImage: null,
    popupImageUpload: false,
    popupImageCrop: false,
    selectedImage: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 3 / 2,
    },
    showDialogDeletePicture: false,
    selectedPictureDelete: null,
    showLayoutSelect: false,
    layoutSelectLoading: false,
    photoIndex: 0,
    showLightbox: false,
    images: [],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("visionboard");

    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <DefaultLayout
          breadcrumbs={breadcrumbs}
          page="visionboard"
          help="visionboard"
        >
          <h1>{_c.translate("visionboard.title")}</h1>
          <p
            className="lead"
            dangerouslySetInnerHTML={{
              __html: _c.translate("visionboard.text"),
            }}
          />

          {this.view__showSelectLayout()}
          <div className="visionboard">{this.view__images()}</div>
          {this.view__showLightbox()}
          {this.view__showPopups()}
        </DefaultLayout>
      </React.Fragment>
    );
  }

  view__showSelectLayout() {
    const { showLayoutSelect, layoutSelectLoading } = this.state;
    const _c = this.props.context;

    const userSettingVisionboardLayout = _c.userSetting(
      "visionboard_layout",
      "layout1"
    );

    const layouts = _c.custom(`visionboard.layouts`);

    let v__popupContent = (
      <div className="visionboard-select-layout-select">
        <Grid type="full">
          <Row margin="y">
            {Object.keys(layouts).map((layoutKey, index) => {
              let layoutClasses = "visionboard-select-layout-select-layout";
              let v__icon = (
                <div className="visionboard-select-layout-select-layout-icon">
                  <Icon icon="check" />
                </div>
              );

              if (layoutKey === userSettingVisionboardLayout) {
                layoutClasses = `selected ${layoutClasses}`;
              }

              return (
                <Cell sm={12} md={6} key={index}>
                  <button
                    className={layoutClasses}
                    onClick={() => {
                      this.setState({ layoutSelectLoading: true }, () => {
                        _c.setUserSetting(
                          "visionboard_layout",
                          layoutKey,
                          () => {
                            this.setState({ layoutSelectLoading: false });
                          }
                        );
                      });
                    }}
                  >
                    <img src={layouts[layoutKey].image} alt="" />
                    {v__icon}
                  </button>
                </Cell>
              );
            })}
          </Row>
        </Grid>
        <div>&nbsp;</div>
      </div>
    );

    if (layoutSelectLoading === true) {
      v__popupContent = (
        <div className="visionboard-select-layout-loading">
          <Icon icon="circle-o-notch fa-spin" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="visionboard-select-layout">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} className="text-right">
                <button
                  className="small primary hollow button"
                  onClick={() => {
                    this.setState({ showLayoutSelect: true });
                  }}
                >
                  <Icon icon="th" left />{" "}
                  {_c.translate("visionboard.layouts.button.label")}
                </button>
              </Cell>
            </Row>
          </Grid>
        </div>
        <MMPopup
          size="small"
          show={showLayoutSelect === true}
          handleClose={() => {
            this.setState({ showLayoutSelect: false });
          }}
        >
          <h3>{_c.translate("visionboard.layouts.popup.title")}</h3>
          <p>{_c.translate("visionboard.layouts.popup.text")}</p>
          {v__popupContent}
        </MMPopup>
      </React.Fragment>
    );
  }

  view__images() {
    const { visionboard, newImage } = this.state;
    const _c = this.props.context;

    if (!visionboard || !visionboard.images) {
      return _c.loading();
    }

    const { images } = visionboard;

    const visionboardImages = cloneDeep(images);
    visionboardImages.push("new");

    let userSettingVisionboardLayout = _c.userSetting(
      "visionboard_layout",
      "layout1"
    );

    let layout = _c.custom(
      `visionboard.layouts.${userSettingVisionboardLayout}`
    );

    let schemeIndex = -1;

    return (
      <div className="visionboard-images">
        <Grid type="full">
          <Row margin="xy">
            {visionboardImages.map((image, index) => {
              let layoutWidth, layoutClass;
              schemeIndex++;
              const { scheme, classes } = layout;
              if (schemeIndex > scheme.length - 1) {
                schemeIndex = 0;
              }

              layoutWidth = scheme[schemeIndex];
              layoutClass = classes[layoutWidth];

              if (image === "new") {
                return (
                  <Cell key={index} sm={24} md={layoutWidth}>
                    <ReactTooltip
                      place={_c.getToolTipSetting("position")}
                      type={_c.getToolTipSetting("type")}
                      effect={_c.getToolTipSetting("effect")}
                    />
                    <button
                      data-tip={_c.translate("visionboard.add.tooltip")}
                      className={`${layoutClass} visionboard-image visionboard-image-add`}
                      onClick={() => {
                        this.setState({ selectedImage: newImage });
                        this.showPopupImageUpload();
                        //console.log(newImage);
                      }}
                    >
                      <div className="wrapper">
                        <div className="center-vertically">
                          <Icon icon="plus" />
                        </div>
                      </div>
                    </button>
                  </Cell>
                );
              } else {
                let v__edit = this.view__imageButtons(image);

                let v__content;
                if (image) {
                  v__content = (
                    <div
                      className={`${layoutClass} visionboard-image`}
                      style={{ backgroundImage: `url(${image.url})` }}
                      onClick={(e) => {
                        //console.log(e.target.localName);
                        if (
                          e.target.className !== "visionboard-image-buttons" &&
                          e.target.localName !== "button" &&
                          e.target.localName !== "i"
                        ) {
                          this.setState({
                            photoIndex: index,
                            showLightbox: true,
                          });
                        }
                      }}
                    >
                      {v__edit}
                    </div>
                  );
                }

                return (
                  <Cell key={index} sm={24} md={layoutWidth}>
                    {v__content}
                  </Cell>
                );
              }
            })}
            {/*layout.map((layoutInfo, index) => {
              let image;
              images.map((listImage) => {
                if (listImage.position === index + 1) {
                  image = listImage;
                }
                return null;
              });

              let v__edit = this.view__imageButtons(image, layoutInfo);

              let v__content;
              if (image) {
                v__content = (
                  <div
                    className={`${layoutInfo.class} visionboard-image`}
                    style={{ backgroundImage: `url(${image.url})` }}
                  >
                    {v__edit}
                  </div>
                );
              }

              return (
                <Cell key={index} sm={24} md={layoutInfo.width}>
                  {v__content}
                </Cell>
              );
            })*/}
          </Row>
        </Grid>
      </div>
    );
  }

  view__imageButtons(image) {
    const _c = this.props.context;

    if (!image) {
      return null;
    }

    let v__featuredIcon = (
      <button
        className="visionboard-image-featured-icon"
        data-tip={_c.translate("visionboard.makeFeatures")}
        onClick={() => {
          this.handle__toggleFeatured(image);
        }}
      >
        <Icon icon="star-o" />
      </button>
    );

    if (image.featured) {
      v__featuredIcon = (
        <button
          className="visionboard-image-featured-icon"
          data-tip={_c.translate("visionboard.deleteFeatures")}
          onClick={() => {
            this.handle__toggleFeatured(image);
          }}
        >
          <Icon icon="star" />
        </button>
      );
    }

    if (_c.setting("home_allow_visionboard") !== true) {
      v__featuredIcon = "";
    }

    let output = (
      <div className="visionboard-image-buttons">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <button
          data-tip={_c.translate("visionboard.deletePicture")}
          onClick={() => {
            this.setState({
              showDialogDeletePicture: true,
              selectedPictureDelete: image,
            });
          }}
        >
          <Icon icon="trash" />
        </button>
        {v__featuredIcon}
        <button
          data-tip={_c.translate("visionboard.changePicture")}
          onClick={() => {
            this.showPopupImageUpload();
            this.setState({ selectedImage: image });
          }}
        >
          <Icon icon="cog" />
        </button>
      </div>
    );

    return output;
  }

  view__showLightbox() {
    const { photoIndex, showLightbox, images } = this.state;

    if (showLightbox === true) {
      return (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => this.setState({ showLightbox: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      );
    }
  }

  view__showPopups() {
    const {
      popupImageUpload,
      popupImageCrop,
      cropUrl,
      crop,
      showDialogDeletePicture,
    } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={popupImageUpload}
          size="medium"
          nopadding
          handleClose={() => {
            this.hidePopupImageUpload();
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h2>
                      {_c.translate("visionboard.image.uploadPopup.title")}
                    </h2>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate(
                            "visionboard.image.uploadPopup.text"
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={popupImageCrop}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h2>{_c.translate("visionboard.image.cropPopup.title")}</h2>
          </div>

          <ReactCrop
            src={cropUrl}
            crop={crop}
            onChange={this.onCrop}
            circularCrop={false}
          />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="text-right">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate("visionboard.image.cropPopup.buttons.save")}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
        <MMDialog
          show={showDialogDeletePicture}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("visionboard.image.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("buttons.deletePermanently"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDeletePicture: false });
          }}
          onButton_2={this.handle__deletePicture}
        />
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  showPopupImageUpload() {
    this.setState({ popupImageUpload: true });
  }

  hidePopupImageUpload() {
    this.setState({ popupImageUpload: false });
  }

  showPopupImageCrop() {
    this.setState({ popupImageCrop: true });
  }

  hidePopupImageCrop() {
    this.setState({ popupImageCrop: false });
  }

  uploadImage = () => {
    const { cropImage, crop, selectedImage } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));
    formData.append("image", selectedImage.id);

    let apiUrl = _c.apiUrl("visionboard.upload");

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, data } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { visionboard, newImage } = data;

            newImage.id = "new";

            this.setState({ visionboard, newImage, selectedImage: null });
            this.hidePopupImageCrop();
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "visionboard:init:response"
          );
        }

        /*const { path } = response.data;
        if (path) {
          selectedImage.url = path;
          this.setState({ selectedImage });
          this.hidePopupImageCrop();
          this.setState({ selectedImage: null });
        }*/
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  handle__toggleFeatured = (image) => {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("visionboard.featured").replace("{image}", image.id);

    axios
      .post(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, data } = response.data;

          if (status === "SUCCESS") {
            _c.handleApiResponse(response.data, true);

            const { visionboard, newStatus } = data;
            this.setState({ visionboard });

            if (newStatus === "set") {
              _c.createNotifictation(
                _c.translate("visionboard.toggleFeatured.set.title"),
                _c.translate("visionboard.toggleFeatured.set.text"),
                "success"
              );
            } else if (newStatus === "unset") {
              _c.createNotifictation(
                _c.translate("visionboard.toggleFeatured.unset.title"),
                _c.translate("visionboard.toggleFeatured.unset.text"),
                "success"
              );
            }
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "visionboard:featured:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "visionboard:featured");
        }
      });
  };

  handle__deletePicture = () => {
    const { selectedPictureDelete } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("visionboard.image", {
      image: selectedPictureDelete.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { visionboard } = apiResponseData;
            this.setState({
              visionboard,
              selectedPictureDelete: null,
              showDialogDeletePicture: false,
            });

            _c.createNotifictation(
              _c.translate("visionboard.messages.deleted.title"),
              _c.translate("visionboard.messages.deleted.text"),
              "success"
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "visionboard:image:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "visionboard:image:delete");
      });
  };

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("visionboard.visionboard");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, data } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { visionboard, newImage } = data;

            let images = [];
            visionboard.images.map((image) => {
              images.push(image.url);
              return null;
            });

            newImage.id = "new";

            this.setState({ visionboard, newImage, images });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "visionboard:init:response"
          );
        }
        _c.setPageTitle("Visionboard");
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "visionboard:init");
        }
      });
  }
}

export default withContext(Visionboard);
