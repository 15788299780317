import React, { Component } from "react";
import withContext from "../../context/contextHOC";

class Index extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    this.props.history.push(_c.config("urls.home"));
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default withContext(Index);
