import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import Box from "../../layout/Box";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Link } from "react-router-dom";
import Avatar from "../../user/Avatar";
import { Icon } from "../../_foundation/_buttons";

class Profile extends Component {
  state = {
    username: this.props.match.params.username,
    account: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.setLoadedModule("community");
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.setState({ username: this.props.match.params.username });
    }
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("community_main_layout");
    if (!layout) {
      layout = "layout1";
    }

    let v__content = (
      <div className={`community-${layout}`}>
        <CommunityLayout
          view="profile"
          layout={layout}
          breadcrumbs={breadcrumbs}
          user={_c.getUser()}
          help="communityProfile"
        >
          {/*<div className="community-header">{this.view__showHeader()}</div>*/}
          {this.view__showPageButtons()}
          {this.view__showProfile()}
        </CommunityLayout>
      </div>
    );

    return <div className="community-profile">{v__content}</div>;
  }

  /*view__showHeader() {
    const { account, status } = this.state;

    if (status === "NOTFOUND" || !account || status !== "LOADED") {
      return null;
    }

    return <h1>{account.displayname}</h1>;
  }*/

  view__showPageButtons() {
    const { account, status } = this.state;
    const _c = this.props.context;

    if (!account || status !== "LOADED") {
      return;
    }

    const user = _c.getUser();

    let v__content;
    if (user.id === account.id && user.email === account.email) {
      let v__ownHiddenProfile;
      if (!user.settings.profile_show_profile.value) {
        v__ownHiddenProfile = (
          <div className="warning callout text-center">
            <Icon icon="exclamation-circle" />{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: _c
                  .translate("community.profile.notpublic")
                  .replace(
                    "{link}",
                    `<a href="${_c.config("urls.privacy")}">${_c.translate(
                      "community.profile.notpublicLinkText"
                    )}</a>`
                  ),
              }}
            />
          </div>
        );
      }

      v__content = (
        <div className="text-right" style={{ marginBottom: "20px" }}>
          {v__ownHiddenProfile}
          <Link
            to={_c.config("urls.privacy")}
            className="small primary hollow button"
          >
            <Icon icon="user-secret" left />{" "}
            {_c.translate("community.profile.editPrivacy")}{" "}
          </Link>
          &nbsp;
          <Link to={_c.config("urls.account")} className="small primary button">
            <Icon icon="edit" left />{" "}
            {_c.translate("community.profile.editMyProfile")}{" "}
          </Link>
        </div>
      );
    }

    return v__content;
  }

  view__showProfile() {
    const { account, status } = this.state;
    const _c = this.props.context;

    if (status === "NOTFOUND") {
      return (
        <p className="text-center">
          {_c.translate("community.profile.notfound")}
        </p>
      );
    } else if (!account || status !== "LOADED") {
      return _c.loading();
    }

    return (
      <div>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={6}>
              {this.view__showLeftBar()}
            </Cell>
            <Cell sm={24} md={18}>
              {this.view__showPersonalInfo()}
              {this.view__showSocialLinks()}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showLeftBar = (post) => {
    const { account } = this.state;
    const _c = this.props.context;

    // AVATAR
    let show_avatar = _c.setting("community_post_show_avatar");
    if (!show_avatar) {
      show_avatar = true;
    }

    let v__avatar;
    if (show_avatar === true && show_avatar !== false) {
      v__avatar = (
        <div className="profile-avatar">
          <Link
            to={_c
              .custom("urls.communityProfile")
              .replace(":username", account.username)}
          >
            <Avatar user={account} size="userprofile" />
          </Link>
        </div>
      );
    }

    // USER TITLE
    let show_usertitle = _c.setting("community_post_show_usertitle");
    if (!show_usertitle && show_usertitle !== false) {
      show_usertitle = true;
    }

    let v__usertitle;
    if (show_usertitle === true && account.community_title) {
      v__usertitle = (
        <div className="profile-usertitle">{account.community_title}</div>
      );
    }

    // REG DATE
    let show_regdate = _c.setting("community_post_show_regdate");
    if (!show_regdate && show_regdate !== false) {
      show_regdate = true;
    }

    let v__regdate;
    if (show_regdate === true) {
      v__regdate = (
        <div className="profile-regdate">
          {_c.translate("community.post.membersince")}{" "}
          {account.registration_date}
        </div>
      );
    }

    // POST COUNT
    let show_postcount = _c.setting("community_post_show_postcount");
    if (!show_postcount && show_postcount !== false) {
      show_postcount = true;
    }

    let v__postcount;
    if (show_postcount === true) {
      v__postcount = (
        <div className="profile-postcount">
          {_c.translate("community.post.postcount")}: {account.postcount}
        </div>
      );
    }

    let extraBox = false;
    let v__extraBox;

    // PROFESSION
    let v__profession;
    if (account.profile.profession) {
      extraBox = true;
      v__profession = (
        <div className="profile-postcount">
          {_c.translate("community.profile.profession")}:{" "}
          {account.profile.profession}
        </div>
      );
    }

    if (extraBox === true) {
      v__extraBox = <Box>{v__profession}</Box>;
    }

    return (
      <div className="profile-box">
        <Box>
          {v__avatar}
          <div className="profile-name">{account.displayname}</div>
          {v__usertitle}
        </Box>
        {v__extraBox}
        <Box>
          {v__regdate}
          {v__postcount}
        </Box>
        {this.view__showExtraBox()}
      </div>
    );
  };

  view__showExtraBox() {
    const { account } = this.state;
    const _c = this.props.context;

    let v__birthday, v__gender;

    if (account.birthday) {
      v__birthday = (
        <div className="profile-birthday">
          <Grid type="full">
            <Row>
              <Cell sm={12}>{_c.translate("community.profile.birthday")}:</Cell>
              <Cell sm={12} className="text-right">
                {account.birthday_date} ({account.age})
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    } else if (!account.birthday && account.age) {
      v__birthday = (
        <div className="profile-birthday">
          <Grid type="full">
            <Row>
              <Cell sm={12}>{_c.translate("community.profile.age")}:</Cell>
              <Cell sm={12} className="text-right">
                {account.age}
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }

    if (account.gender) {
      v__gender = (
        <div className="profile-gender">
          <Grid type="full">
            <Row>
              <Cell sm={12}>{_c.translate("community.profile.gender")}:</Cell>
              <Cell sm={12} className="text-right">
                {_c.getGender(account.gender)}
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }

    if (v__birthday || v__gender) {
      return (
        <Box>
          {v__birthday}
          {v__gender}
        </Box>
      );
    }

    return;
  }

  view__showPersonalInfo() {
    const { account } = this.state;
    const _c = this.props.context;

    return (
      <div className="profile-personal-info">
        <Box>
          <h3>{_c.translate("community.profile.bio")}</h3>
          <div dangerouslySetInnerHTML={{ __html: account.profile.bio_html }} />
        </Box>
        <Box>
          <h3>{_c.translate("community.profile.offering")}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: account.profile.offering_html }}
          />
        </Box>
        <Box>
          <h3>{_c.translate("community.profile.searching")}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: account.profile.searching_html }}
          />
        </Box>
      </div>
    );
  }

  view__showSocialLinks() {
    const { account } = this.state;
    const _c = this.props.context;

    if (_c.setting("community_profile_allow_sociallinks") !== true) {
      return;
    }

    const platforms = _c.custom("community.platforms");

    return (
      <div className="profile-social-links">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              {account.profile.links.map((link, index) => {
                let v__linkIcon = <Icon icon="link" />;

                if (platforms[link.type]) {
                  v__linkIcon = <Icon icon={platforms[link.type].icon} />;
                }

                return (
                  <div className="profile-social-link" key={index}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box size="small">{v__linkIcon}</Box>
                    </a>
                  </div>
                );
              })}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  init() {
    const { username } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("community.profile", { username });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { account } = apiResponseData;

            const bc1 = {
              id: "community_profile",
              to: `/`,
              name: account.displayname,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            this.setState({
              account,
              status: "LOADED",
              breadcrumbs,
            });
          } else if (status === "NOTFOUND") {
            this.setState({ status: "NOTFOUND" });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:profile:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:profile:init");
      });
  }
}

export default withContext(Profile);
