import React, { Component } from "react";
import { Grid, Row, Cell } from "../_foundation/_grid";
import MainTopbar from "../topbars/MainTopbar";
import Breadcrumbs from "../topbars/Breadcrumbs";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";
import Footer from "../layout/Footer";

class AdminLayout extends Component {
  state = {
    nav: this.props.nav, // Selected Navigation Item
    fullpage: this.props.fullpage,
    breadcrumbs: this.props.breadcrumbs,
  };

  render() {
    const { breadcrumbs, fullpage } = this.state;

    let v__breadcrumbs = null;
    if (breadcrumbs) {
      v__breadcrumbs = <Breadcrumbs breadcrumbs={breadcrumbs} />;
    }

    let type;
    if (fullpage) {
      type = "full";
    }

    return (
      <div className="page page-admin page-with-breadcrumbs">
        <MainTopbar type="admin" />
        {v__breadcrumbs}
        <main className="content">
          <Grid type={type}>
            <Row>
              <Cell sm={24} md={24}>
                <div className="admin-area">
                  {/*<div className="admin-mobile-header show-for-small-only">
                    <h1>{_c.translate("pageTitles.admin.administration")}</h1>
                  </div>*/}
                  <div className="admin-nav">{this.view__showAdminMenu()}</div>
                  <div className="admin-content">{this.props.children}</div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </main>
        <Footer />
      </div>
    );
  }

  view__showAdminMenu() {
    const { nav } = this.state;
    const _c = this.props.context;
    const adminMenuItems = _c.getAdminMenuItems();

    return (
      <React.Fragment>
        <div className="admin-menu-title">
          <strong>Administration</strong>
        </div>
        <ul className="admin-menu">
          {adminMenuItems.map((navItem, index) => {
            /*let navItemIcon;
            if (navItem.icon) {
              navItemIcon = <Icon icon={navItem.icon} />;
            }*/

            let navClasses = "";
            if (nav === navItem.name) {
              navClasses += " selected";
            }

            return (
              <li key={index} className={navClasses}>
                <Link to={navItem.url}>
                  <Grid type="full">
                    <Row>
                      {/*<Cell sm={3} md={3}>
                        {navItemIcon}
                      </Cell>*/}
                      <Cell sm={24} md={24}>
                        {navItem.title}
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

export default withContext(AdminLayout);
