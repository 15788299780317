import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import { Icon } from "../../_foundation/_buttons";
import Dropzone from "react-dropzone";
import Loading from "../../_reusables/Loading";
import Box from "../../layout/Box";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";

class AdminToolImport extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_tools", "admin_tools_import"],
    status: "UPLOAD",
    csv: null,
    courses: null,
    result: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="tools" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <h1>{_c.translate("admin.tools.import.title")}</h1>
              <p
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: _c.translate("admin.tools.import.text2"),
                }}
              />
            </Cell>
          </Row>
        </Grid>
        {this.view__showContent()}
      </AdminLayout>
    );
  }

  view__showContent() {
    const { status } = this.state;

    if (status === "LOADING") {
      return (
        <div>
          <Loading show={true} />
        </div>
      );
    } else if (status === "UPLOAD") {
      return this.view__showUpload();
    } else if (status === "TABLE") {
      return this.view__showTable();
    } else if (status === "SUCCESS") {
      return this.view__showSuccess();
    }
  }

  view__showUpload() {
    const _c = this.props.context;

    return (
      <div className="admin-tools-import-upload">
        <Dropzone
          onDrop={(acceptedFiles) => {
            this.setState({ status: "LOADING" });
            const csvFile = acceptedFiles[0];

            this.uploadCSV(csvFile);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div id="avatarDropZone">
                  <h3>{_c.translate("admin.tools.import.dropzone.title")}</h3>
                  <div id="avatarDropZoneText">
                    <p>
                      <Icon icon="upload" />
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: _c.translate(
                          "admin.tools.import.dropzone.text"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }

  view__showTable() {
    const { csv, courses } = this.state;
    const _c = this.props.context;

    let v__counter;

    const recordsTotal = this.countRecords("total");
    const recordsSelected = this.countRecords("selected");

    v__counter = (
      <div className="admin-tools-import-counter">
        <Box>
          {recordsSelected} / {recordsTotal}{" "}
          {_c.translate(
            `admin.tools.import.table.counter.${
              recordsSelected === 1 ? "record" : "records"
            }`
          )}
        </Box>
      </div>
    );

    let v__thead = (
      <thead>
        <tr>
          <th>&nbsp;</th>
          {csv.columns.map((column, index) => {
            return (
              <th key={index}>
                <select
                  value={column.key}
                  onChange={(e) => {
                    let newKey = e.target.value;
                    csv.columnNames.map((columnName) => {
                      if (columnName.key === newKey) {
                        column = columnName;
                      }
                      return null;
                    });
                    csv.columns[index] = column;
                    this.setState({ csv });
                  }}
                >
                  {csv.columnNames.map((columnName, columnNameIndex) => {
                    return (
                      <option value={columnName.key} key={columnNameIndex}>
                        {columnName.label}
                      </option>
                    );
                  })}
                </select>
              </th>
            );
          })}
        </tr>
      </thead>
    );

    let v__purchase = (
      <div className="admin-tools-import-purchase">
        <Box>
          <h3>{_c.translate("admin.tools.import.table.purchase.title")}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.tools.import.table.purchase.text"),
            }}
          />
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <select
                  value={csv.course}
                  onChange={(e) => {
                    csv.course = e.target.value;
                    this.setState({ csv });
                  }}
                >
                  <option value={"none"}>
                    {_c.translate("admin.tools.import.table.purchase.nocourse")}
                  </option>
                  {courses.map((course, index) => {
                    return (
                      <option value={course.id} key={index}>
                        {course.name}
                      </option>
                    );
                  })}
                </select>
              </Cell>
            </Row>
          </Grid>
        </Box>
      </div>
    );

    const v__buttons = (
      <div className="page-buttons">
        <button
          className="small primary hollow button"
          onClick={this.handle__cancelImport}
        >
          <Icon icon="times" left /> {_c.translate("buttons.cancel")}
        </button>
        <button
          className="small primary button"
          onClick={this.handle__import}
          disabled={this.countRecords("selected") === 0 ? true : false}
        >
          <Icon icon="check" left />{" "}
          {_c.translate("admin.tools.import.table.buttons.import.label")}
        </button>
      </div>
    );

    return (
      <div className="admin-tools-import-table">
        <hr />
        <h3>{_c.translate("admin.tools.import.table.title")}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: _c.translate("admin.tools.import.table.text"),
          }}
        />
        {v__counter}
        <div className="table-scroll">
          <table>
            {v__thead}
            <tbody>
              {csv.rows.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.selected}
                        onChange={() => {
                          if (row.selected === true) {
                            row.selected = false;
                          } else {
                            row.selected = true;
                          }
                          this.setState({ csv });
                        }}
                      />
                    </td>
                    {row.columns.map((column, columnIndex) => {
                      return (
                        <td key={columnIndex}>
                          <input
                            type="text"
                            value={column}
                            onChange={(e) => {
                              row.columns[columnIndex] = e.target.value;
                              csv.rows[index] = row;
                              this.setState({ csv });
                            }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="admin-tools-import-explanation">
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate(
                "admin.tools.import.table.explanation.datetime"
              ),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.tools.import.table.explanation.date"),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.tools.import.table.explanation.time"),
            }}
          />
        </div>
        {v__purchase}
        {v__buttons}
      </div>
    );
  }

  view__showSuccess() {
    const { result } = this.state;
    const _c = this.props.context;

    if (!result) {
      return;
    }

    return (
      <div className="admin-tools-import-success">
        <Box>
          <h3>{_c.translate("admin.tools.import.success.title")}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.tools.import.success.text"),
            }}
          />
          <div>&nbsp;</div>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={2} md={6} className="text-right">
                <strong>{result.records}</strong>
              </Cell>
              <Cell sm={22} md={18}>
                {_c.translate("admin.tools.import.success.details.records")}
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={2} md={6} className="text-right">
                <strong>{result.mails}</strong>
              </Cell>
              <Cell sm={22} md={18}>
                {_c.translate("admin.tools.import.success.details.mails")}
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={2} md={6} className="text-right">
                <strong>{result.errors.length}</strong>
              </Cell>
              <Cell sm={22} md={18}>
                <div>
                  {_c.translate("admin.tools.import.success.details.errors")}
                </div>
                <ul>
                  {result.errors.map((error, index) => {
                    return (
                      <li key={index}>
                        <code>{error}</code>
                      </li>
                    );
                  })}
                </ul>
              </Cell>
            </Row>
          </Grid>
        </Box>
        <div className="page-buttons">
          <Link
            to={_c.config("urls.admin.tools.tools")}
            className="small primary hollow button"
          >
            <Icon icon="check" left />{" "}
            {_c.translate("admin.tools.import.success.buttons.close.label")}
          </Link>
          <a
            href={_c.config("urls.admin.tools.import")}
            className="small primary button"
          >
            {_c.translate("admin.tools.import.success.buttons.another.label")}{" "}
            <Icon icon="arrow-right" right />
          </a>
        </div>
      </div>
    );
  }

  countRecords = (type) => {
    const { csv } = this.state;

    let result = 0;

    if (type === "total") {
      result = csv.rows.length;
    } else if (type === "selected") {
      csv.rows.map((row) => {
        if (row.selected === true) {
          result++;
        }
        return null;
      });
    }

    return result;
  };

  handle__cancelImport = () => {
    const _c = this.props.context;

    if (
      window.confirm(_c.translate("admin.tools.import.table.cancel.confirm"))
    ) {
      window.location.href = _c.config("urls.admin.tools.tools");
    }
  };

  handle__import = () => {
    const { csv, courses } = this.state;
    const _c = this.props.context;

    let confirmMessage = _c
      .translate("admin.tools.import.table.save.confirm")
      .replace("{records}", this.countRecords("selected"));
    if (csv.course && csv.course !== "none") {
      let courseName;
      courses.map((course) => {
        if (parseInt(csv.course) === parseInt(course.id)) {
          courseName = course.name;
        }
        return null;
      });

      confirmMessage = `${confirmMessage}\r\n\r\n${_c
        .translate("admin.tools.import.table.save.confirmPurchase")
        .replace("{course}", courseName)}`;
    } else {
      confirmMessage = `${confirmMessage}\r\n\r\n${_c.translate(
        "admin.tools.import.table.save.confirmNoPurchase"
      )}`;
    }

    if (!window.confirm(confirmMessage)) {
      return null;
    }

    this.setState({ status: "LOADING" });

    const apiUrl = _c.apiUrl("admin.tools.import.import");

    const formData = cloneDeep(csv);

    // No need to send columnNames back to API
    delete formData.columnNames;

    axios
      .post(
        apiUrl,
        { csv: formData },
        {
          headers: _c.headers,
        }
      )
      .then((response) => {
        try {
          const { status } = response.data;
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { result } = response.data.data;
            this.setState({ status: "SUCCESS", result });
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:tools:import:import:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:tools:import:import");
      });
  };

  uploadCSV = (csvFile) => {
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", csvFile);
    formData.append("name", csvFile.name);

    const apiUrl = _c.apiUrl("admin.tools.import.uploadCsv");

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        try {
          const { status } = response.data;
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { csv } = response.data.data;
            csv.course = "none";
            this.setState({ status: "TABLE", csv });
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:tools:import:uploadcsv:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:tools:import:uploadcsv");
      });
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.tools.import.init");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          const { courses } = response.data.data;

          if (status === "SUCCESS") {
            //this.setState({ csv, courses, status: "TABLE" });
            this.setState({ courses, status: "UPLOAD" });
          }
          _c.setPageTitle(_c.translate("pageTitles.admin.tools.import"));
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:tools:import:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:tools:import:init");
      });
  }
}

export default withContext(AdminToolImport);
