import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import Avatar from "../../user/Avatar";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import AdminLog from "../AdminLog";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import cloneDeep from "lodash/cloneDeep";
import AdminMemberPurchases from "./AdminMemberPurchases";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import AdminDotsMenu from "../AdminDotsMenu";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";

class AdminMember extends Component {
  state = {
    loadingError: false,
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_members"],
    tabs: {},
    selectedTab: "info",
    member: undefined,
    original: undefined,
    purchases: undefined,
    newPurchase: undefined,
    courses: undefined,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    showDotsMenu: false,
    showConfirmationLinkPopup: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    return (
      <AdminLayout nav="members" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={_c.config("urls.admin.members")} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={10}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={14} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showPopups() {
    const { showConfirmationLinkPopup, member } = this.state;
    const _c = this.props.context;

    if (!member) {
      return null;
    }

    if (showConfirmationLinkPopup === true) {
      let confirmationUrl = `${window.location.protocol}//${
        window.location.host
      }${_c.config("urls.auth.signup")}?code=${member.signup_code}`;

      return (
        <MMPopup
          size="small"
          show={showConfirmationLinkPopup}
          handleClose={() => {
            this.setState({ showConfirmationLinkPopup: false });
          }}
        >
          <h3>{_c.translate("admin.members.confirmationlink.title")}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.members.confirmationlink.text"),
            }}
          />
          <p>
            {_c.translate("admin.members.confirmationlink.link")}:{" "}
            <a href={confirmationUrl} target="_blank" rel="noopener noreferrer">
              {confirmationUrl}
            </a>
          </p>
        </MMPopup>
      );
    }
  }

  view__showDialogs() {
    const { showDialogDelete, member } = this.state;
    const _c = this.props.context;

    if (!member) {
      return null;
    }

    let displayName;
    if (member.firstname && member.lastname) {
      displayName = `${member.firstname} ${member.lastname}`;
    } else if (member.firstname && !member.lastname) {
      displayName = member.firstname;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c
                  .translate("admin.members.delete.confirm")
                  .replace("{member}", displayName),
              }}
            />
          }
          button_1={{
            title: _c.translate("buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("buttons.deletePermanently"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { member, original, requestedId } = this.state;
    const _c = this.props.context;

    if (isUndefined(member)) {
      return null;
    } else if (member === null) {
      return null;
    } else if (member && requestedId === "new") {
      return <h1>{_c.translate("admin.members.addMember")}</h1>;
    } else if (member && member.id && member.email) {
      let v__admin;
      if (member.admin && member.admin.fulladmin === true) {
        v__admin = (
          <Link to={_c.config("urls.admin.admins")}>
            <span className="primary label member-admin-label">
              <Icon icon="check" left /> ADMIN
            </span>
          </Link>
        );
      }

      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">{_c.translate("admin.members.member")}</div>
          <h1>
            {_c.getFullName(original)} {v__admin}
          </h1>
        </React.Fragment>
      );
    } else {
      return <h1>{_c.translate("errors.unknownError")}</h1>;
    }
  }

  view__showPageActions() {
    const { editMode, member, showDotsMenu } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return null;
    }

    if (!member) {
      return null;
    }

    let v__addAdmin;
    if ((member.admin && !member.admin.fulladmin) || !member.admin) {
      v__addAdmin = (
        <button className="hollow button" onClick={this.toggleAdminPermission}>
          <Icon icon="star" left />{" "}
          {_c.translate("admin.admins.fulladmin.toggle.no.button")}
        </button>
      );
    } else if (member.admin && member.admin.fulladmin) {
      v__addAdmin = (
        <button className="hollow button" onClick={this.toggleAdminPermission}>
          <Icon icon="star" left />{" "}
          {_c.translate("admin.admins.fulladmin.toggle.yes.button")}
        </button>
      );
    }

    let v__editButton = (
      <button className="button" onClick={this.handle__toggleEditMode}>
        <Icon icon="cog" left /> {_c.translate("admin.members.editMember")}
      </button>
    );

    let v__deleteButton = (
      <button
        className="button"
        onClick={() => {
          this.setState({ showDialogDelete: true });
        }}
      >
        <Icon icon="trash" left /> {_c.translate("admin.members.deleteMember")}
      </button>
    );

    if (member.id === 1) {
      v__deleteButton = null;
      v__editButton = null;
      v__addAdmin = null;
    }

    let sendWelcomeMailButtonClass = "";
    if (member.password_set_at) {
      sendWelcomeMailButtonClass = "disabled";
    }

    let v__dotsmenu = (
      <React.Fragment>
        <button
          className="button"
          id="buttonDotsMenu"
          onClick={() => {
            if (this.state.showDotsMenu === true) {
              this.setState({ showDotsMenu: false });
            } else {
              this.setState({ showDotsMenu: true });
            }
          }}
        >
          <Icon icon="ellipsis-h" />
        </button>
        <AdminDotsMenu show={showDotsMenu} button="buttonDotsMenu">
          <ul>
            <li>
              <a
                href={_c
                  .custom("urls.communityProfile")
                  .replace(":username", member.username)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="user" left />{" "}
                {_c.translate("admin.members.dotsmenu.communityProfile")}
              </a>
            </li>
            <li>
              <button
                className={sendWelcomeMailButtonClass}
                onClick={this.handle__sendWelcomeMail}
              >
                <Icon icon="envelope-o" left />{" "}
                {_c.translate("admin.members.dotsmenu.welcomeMail")}
              </button>
            </li>
            <li>
              <button
                className={sendWelcomeMailButtonClass}
                onClick={this.handle__showConfirmationLink}
              >
                <Icon icon="key" left />{" "}
                {_c.translate("admin.members.dotsmenu.confirmationLink")}
              </button>
            </li>
          </ul>
        </AdminDotsMenu>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="small primary hollow button-group no-gaps">
          {v__addAdmin}
          {v__editButton}
          {v__deleteButton}
          {v__dotsmenu}
        </div>
      </React.Fragment>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab, requestedId } = this.state;

    let isNew = requestedId === "new" ? true : false;

    return (
      <div className="admin-tabs">
        <MMTabs
          isNew={isNew}
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button className="small primary button" onClick={this.handle__save}>
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;
    const _c = this.props.context;

    if (editLock === true) {
      const message = _c
        .translate("editLock.message")
        .replace("{editLockUser}", editLockUser);

      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showContent() {
    const {
      member,
      purchases,
      newPurchase,
      courses,
      loadingError,
      selectedTab,
    } = this.state;
    const _c = this.props.context;

    if (isUndefined(member)) {
      return _c.loading(loadingError);
    } else if (member === null) {
      return _c.notfound(_c.translate("admin.members.notfound"));
    }

    if (selectedTab === "info") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxNameInfo()}
                {this.view__showBoxContactInfo()}
                {this.view__showBoxAddressInfo()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxPersonalInfo()}

                {this.view__showBoxAccountInfo()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "purchases") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminMemberPurchases
                  member={member}
                  purchases={purchases}
                  courses={courses}
                  newPurchase={newPurchase}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxNameInfo() {
    const { member, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.members.titles.name")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.firstname}
                editValue={member.firstname}
                editType="text"
                label={_c.translate("admin.members.form.firstname.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("firstname", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.lastname}
                editValue={member.lastname}
                editType="text"
                label={_c.translate("admin.members.form.lastname.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("lastname", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxPersonalInfo() {
    const { member, editMode } = this.state;
    const _c = this.props.context;

    let selectValuesGenders = [];
    selectValuesGenders = _c.getGenders();

    let memberGender = member.gender;
    if (!memberGender) {
      memberGender = "";
    }

    let memberGender_f = _c.getGender(member.gender);
    if (!memberGender_f) {
      memberGender_f = "";
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.members.titles.personal")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={16}>
              <Grid type="full">
                <Row margin="x">
                  <Cell sm={24}>
                    <AdminDataField
                      value={member.birthday_date || ""}
                      editValue={member.birthday || ""}
                      editType="date"
                      label={_c.translate("admin.members.form.birthday.label")}
                      editable={true}
                      edit={editMode}
                      onUpdate={(newValue) => {
                        this.handle__edit("birthday", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24}>
                    <AdminDataField
                      value={memberGender_f}
                      editValue={memberGender}
                      editType="select"
                      label={_c.translate("admin.members.form.gender.label")}
                      selectValues={selectValuesGenders}
                      editable={true}
                      edit={editMode}
                      onUpdate={(newValue) => {
                        this.handle__edit("gender", newValue);
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Cell>
            <Cell sm={24} md={8} className="text-center">
              <Avatar user={member} size="admin-user-view" />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxContactInfo() {
    const { member, original, editMode, requestedId } = this.state;
    const _c = this.props.context;

    let emailNote = null;
    if (
      editMode === true &&
      requestedId !== "new" &&
      member.email !== original.email
    ) {
      emailNote = (
        <div className="small warning callout">
          <small>
            <strong>
              <Icon icon="exclamation-circle" />{" "}
              {_c.translate("admin.members.messages.email.title")}
            </strong>{" "}
            {_c.translate("admin.members.messages.email.text")}
          </small>
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.members.titles.contact")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              {emailNote}
              <AdminDataField
                value={member.email}
                editValue={member.email}
                editType="text"
                label={_c.translate("admin.members.form.email.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("email", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.phone}
                editValue={member.phone}
                editType="text"
                label={_c.translate("admin.members.form.phone.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("phone", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAddressInfo() {
    const { member, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.members.titles.address")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.street}
                editValue={member.street}
                editType="text"
                label="Straße"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("street", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={6}>
              <AdminDataField
                value={member.zip}
                editValue={member.zip}
                editType="text"
                label={_c.translate("admin.members.form.zip.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("zip", newValue);
                }}
              />
            </Cell>
            <Cell sm={24} md={18}>
              <AdminDataField
                value={member.city}
                editValue={member.city}
                editType="text"
                label={_c.translate("admin.members.form.city.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("city", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.country}
                editValue={member.country}
                editType="text"
                label={_c.translate("admin.members.form.country.label")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("country", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAccountInfo() {
    const { member, editMode, requestedId } = this.state;
    const _c = this.props.context;

    let accountActive;
    if (member.active === true) {
      //if (editMode === true) {
      accountActive = (
        <React.Fragment>
          <Switch
            height={20}
            width={40}
            onChange={() => {
              this.handle__toggleActive(member.id);
            }}
            checked={member.active}
          />{" "}
          <label className="admin-active">
            {_c.translate("admin.members.account.status.active")}
          </label>
        </React.Fragment>
      );
      /*} else {
        accountActive = (
          <p className="admin-active">
            <small>
              <Icon icon="unlock" left />{" "}
              {_c.translate("admin.members.account.status.active")}
            </small>
          </p>
        );
      }*/
    } else if (member.active === false) {
      //if (editMode === true) {
      accountActive = (
        <React.Fragment>
          <Switch
            height={20}
            width={40}
            onChange={() => {
              this.handle__toggleActive(member.id);
            }}
            checked={member.active}
          />{" "}
          <label className="admin-inactive">
            {_c.translate("admin.members.account.status.inactive")}
          </label>
        </React.Fragment>
      );
      /*} else {
        accountActive = (
          <p className="admin-inactive">
            <small>
              <Icon icon="lock" left />{" "}
              {_c.translate("admin.members.account.status.inactive")}
            </small>
          </p>
        );
      }*/
    }

    if (requestedId === "new") {
      return (
        <div className="admin-member-account-box">
          <Box>
            <Grid type="full">
              <Row margin="x">
                <Cell sm={24}>
                  <h3>{_c.translate("admin.members.titles.account")}</h3>
                </Cell>
              </Row>

              <Row margin="x">
                <Cell sm={24}>
                  <div className="text-center" style={{ paddingTop: "20px" }}>
                    <AdminDataField
                      value={member.send_mail}
                      editValue={member.send_mail}
                      editType="singleCheckbox"
                      label={_c.translate("admin.members.form.send_mail.label")}
                      editable={true}
                      edit={editMode}
                      onUpdate={(newValue) => {
                        this.handle__edit("send_mail", newValue);
                      }}
                    />
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Box>
        </div>
      );
    } else {
      return (
        <div className="admin-member-account-box">
          <Box>
            <Grid type="full">
              <Row margin="x">
                <Cell sm={24}>
                  <h3>{_c.translate("admin.members.titles.account")}</h3>
                </Cell>
              </Row>
              <Row margin="x">
                <Cell sm={24} className="text-center">
                  {accountActive}
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={member.last_visit_datetime}
                    editValue={member.last_visit_datetime}
                    editType="text"
                    label={_c.translate("admin.members.form.lastvisit.label")}
                    editable={false}
                    edit={editMode}
                  />
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={member.password_set_at_date}
                    editValue={member.password_set_at_date}
                    editType="text"
                    label={_c.translate("admin.members.form.passwordset.label")}
                    editable={false}
                    edit={editMode}
                  />
                </Cell>
              </Row>

              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={member.email_verified_at_date}
                    editValue={member.email_verified_at_date}
                    editType="text"
                    label={_c.translate(
                      "admin.members.form.emailverified.label"
                    )}
                    editable={false}
                    edit={editMode}
                  />
                </Cell>
              </Row>

              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={member.registration_datetime}
                    editValue={member.registration_datetime}
                    editType="text"
                    label={_c.translate("admin.members.form.regdate.label")}
                    editable={false}
                    edit={editMode}
                  />
                </Cell>
              </Row>
            </Grid>
          </Box>
        </div>
      );
    }
  }

  view__showLogs() {
    const { member } = this.state;

    if (!member || !member.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={member.logs} />
      </React.Fragment>
    );
  }

  toggleAdminPermission = () => {
    const { member } = this.state;
    const _c = this.props.context;

    let v__confirm;
    if ((member.admin && !member.admin.fulladmin) || !member.admin) {
      v__confirm = _c.translate("admin.admins.fulladmin.toggle.no.confirm");
    } else if (member.admin && member.admin.fulladmin) {
      v__confirm = _c.translate("admin.admins.fulladmin.toggle.yes.confirm");
    }

    if (!v__confirm) {
      return;
    }

    if (window.confirm(v__confirm)) {
      let apiUrl = _c.apiUrl("admin.adminsToggle", {
        memberId: member.id,
        type: "fulladmin",
      });

      axios
        .patch(
          apiUrl,
          {},
          {
            headers: _c.getHeaders(),
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              window.location.href = _c.config("urls.admin.admins");
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "admin:admins:toggle:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "admin:admins:toggle");
          }
        });
    }
  };

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { member } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("member", member.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { member } = this.state;
    member[property] = value;
    this.setState({ member, changesWereMade: true });
  }

  handle__delete = () => {
    const { member } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.member", { id: member.id });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            let displayName;
            if (member.firstname && member.lastname) {
              displayName = `${member.firstname} ${member.lastname}`;
            } else if (member.firstname && !member.lastname) {
              displayName = member.firstname;
            }

            _c.createNotifictation(
              _c.translate("admin.members.messages.deleted.title"),
              _c
                .translate("admin.members.messages.deleted.text")
                .replace("{member}", displayName),
              "success"
            );
            this.props.history.push(_c.config("urls.admin.members"));
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:member:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:member:init");
      });
  };

  handle__cancel = () => {
    const { requestedId, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("admin.members.cancel.confirm"))) {
        if (requestedId === "new") {
          window.location = _c.config("urls.admin.members");
        } else {
          let { member, original } = this.state;
          member = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, member });
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = _c.config("urls.admin.members");
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { member, requestedId } = this.state;

    if (!member.firstname || !member.email) {
      window.alert(_c.translate("admin.members.errors.nameEmail"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let apiUrl = _c.apiUrl("admin.member", { id: requestedId });

      // Prepare Birthday
      if (member.birthday) {
        member.birthday = _c.formatDateForSaving(member.birthday);
      }

      axios
        .post(
          apiUrl,
          {
            member: member,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { member } = response.data;

          if (requestedId === "new" && member && member.id) {
            window.location = _c
              .custom("urls.admin.member")
              .replace(":id", member.id);
            return;
          }

          const original = JSON.parse(JSON.stringify(member));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: false,
              member,
              original,
            },
            () => {
              _c.cancelEditLock();
              _c.createNotifictation(
                _c.translate("admin.members.messages.saved.title"),
                _c.translate("admin.members.messages.saved.text"),
                "success"
              );
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  handle__toggleActive = () => {
    const _c = this.props.context;
    const { member } = this.state;

    const apiUrl = _c.apiUrl("admin.memberToggleActive", {
      memberId: member.id,
    });

    axios
      .patch(
        apiUrl,
        {},
        {
          headers: _c.headers,
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { member } = apiResponseData;
            const original = JSON.parse(JSON.stringify(member));

            this.setState({
              member,
              original,
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:member:toggle:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:member:toggle");
      });
  };

  handle__showConfirmationLink = () => {
    const { member } = this.state;

    if (member.password_set_at) {
      return null;
    }

    this.setState({ showConfirmationLinkPopup: true, showDotsMenu: false });
  };

  handle__sendWelcomeMail = () => {
    const _c = this.props.context;
    const { member } = this.state;

    if (
      member.password_set_at ||
      !window.confirm(_c.translate("admin.members.sendwelcomemail.confirm"))
    ) {
      return null;
    }

    const apiUrl = _c.apiUrl("admin.memberSendWelcomeMail", {
      memberId: member.id,
    });

    axios
      .get(apiUrl, {
        headers: _c.headers,
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            _c.cancelEditLock();
            _c.createNotifictation(
              _c.translate("admin.members.messages.welcomemailsent.title"),
              _c
                .translate("admin.members.messages.welcomemailsent.text")
                .replace("{email}", member.email),
              "success"
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:member:toggle:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:member:toggle");
      });
  };

  init() {
    const { requestedId } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.member", { id: requestedId });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { member, purchases, newPurchase, courses } = apiResponseData;

            if (newPurchase) {
              newPurchase.id = "new";
            }

            const original = cloneDeep(member);

            let pageTitle;
            if (requestedId === "new") {
              pageTitle = "Neues Mitglied";
            } else {
              pageTitle = `${member.displayname}`;
            }

            _c.setPageTitle(pageTitle);

            const bc1 = {
              id: "admin_member",
              to: _c.config("urls.admin.member").replace(":id", member.id),
              name: pageTitle,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            if (requestedId === "new") {
              this.setState({ editMode: true });
            }

            this.setState(
              {
                member,
                purchases,
                newPurchase,
                courses,
                original,
                breadcrumbs,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }

          this.setState({
            tabs: {
              info: {
                name: _c.translate("admin.members.tabs.info"),
                icon: "user",
                showNew: true,
              },
              purchases: {
                name: _c.translate("admin.members.tabs.purchases"),
                icon: "shopping-cart",
                showNew: false,
              },
              logs: {
                name: _c.translate("admin.members.tabs.logs"),
                icon: "clock-o",
                showNew: false,
              },
            },
          });
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:member:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:member:init");
      });
  }
}

export default withContext(AdminMember);
