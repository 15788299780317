import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import Layout1 from "./views/Layout1";
import Layout2 from "./views/Layout2";
import Layout3 from "./views/Layout3";
import { Icon } from "../../_foundation/_buttons";
import CourseLayout from "./CourseLayout";

class CourseLesson extends Component {
  state = {
    courseSlug: this.props.match.params.courseSlug,
    lessonSlug: this.props.match.params.lessonSlug,
    breadcrumbs: ["home", "courses"],
    course: null,
    lesson: null,
    lessons: null,
    downloads: [],
    status: "INIT",
    player: undefined,
    showNotePopup: false,
    selectedNote: null,
    showLessonListOnMobile: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("courses");
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { courseSlug, lessonSlug } = this.state;

    if (this.props.match.params.courseSlug !== courseSlug) {
      this.setState({ courseSlug: this.props.match.params.courseSlug }, () => {
        this.init();
      });
    }

    if (this.props.match.params.lessonSlug !== lessonSlug) {
      this.setState({ lessonSlug: this.props.match.params.lessonSlug }, () => {
        this.init();
      });
    }
  }

  render() {
    const {
      course,
      lesson,
      lessons,
      downloads,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
      breadcrumbs,
    } = this.state;
    const _c = this.props.context;

    if (!lesson) {
      return null;
    }

    let layout = _c.setting("course_lesson_layout");
    if (!layout) {
      layout = "layout1";
    }

    let v__content;
    if (status === "COURSE_NOTFOUND") {
      v__content = this.view__showNotFound("course");
    } else if (status === "LESSON_NOTFOUND") {
      v__content = this.view__showNotFound("lesson");
    } else {
      if (layout === "layout1") {
        v__content = (
          <div className={`course-${layout}`}>
            <Layout1
              course={course}
              lesson={lesson}
              lessons={lessons}
              downloads={downloads}
              status={status}
              showNotePopup={showNotePopup}
              selectedNote={selectedNote}
              player={player}
              showLessonListOnMobile={showLessonListOnMobile}
              onUpdateLayout={this.onUpdateLayout}
            />
          </div>
        );
      } else if (layout === "layout2") {
        v__content = (
          <div className={`course-${layout}`}>
            <Layout2
              course={course}
              lesson={lesson}
              lessons={lessons}
              downloads={downloads}
              status={status}
              showNotePopup={showNotePopup}
              selectedNote={selectedNote}
              player={player}
              showLessonListOnMobile={showLessonListOnMobile}
              onUpdateLayout={this.onUpdateLayout}
            />
          </div>
        );
      } else if (layout === "layout3") {
        v__content = (
          <div className={`course-${layout}`}>
            <Layout3
              course={course}
              lesson={lesson}
              lessons={lessons}
              downloads={downloads}
              status={status}
              showNotePopup={showNotePopup}
              selectedNote={selectedNote}
              player={player}
              showLessonListOnMobile={showLessonListOnMobile}
              onUpdateLayout={this.onUpdateLayout}
            />
          </div>
        );
      }
    }

    let buttons, menu;
    if (_c.isAdmin()) {
      buttons = [
        {
          class: "primary hollow",
          label: _c.translate("breadcrumbs.course.edit"),
          href: _c.config("urls.admin.course").replace(":id", lesson.course),
        },
      ];
    }

    return (
      <div className="course-course">
        <CourseLayout
          status={status}
          course={course}
          lesson={lesson}
          lessons={lessons}
          downloads={downloads}
          breadcrumbs={breadcrumbs}
          buttons={buttons}
          menu={menu}
          help="course"
        >
          {v__content}
        </CourseLayout>
      </div>
    );
  }

  view__showNotFound(type) {
    const { course } = this.state;
    const _c = this.props.context;

    if (type === "course") {
      let courseOverviewUrl = _c.config("urls.courses");

      return (
        <div className="notfound notfound-course">
          <h1>{_c.translate("courses.notfound.title")}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("courses.notfound.text"),
            }}
          />
          <div>
            <a href={courseOverviewUrl} className="primary button">
              <Icon icon="angle-left" left />{" "}
              {_c.translate("courses.notfound.button.label")}
            </a>
          </div>
        </div>
      );
    } else if (type === "lesson") {
      let courseUrl = _c.config("urls.course");
      courseUrl = courseUrl.replace(":courseSlug", course.slug);

      return (
        <div className="notfound notfound-lesson">
          <h1>{_c.translate("courses.lesson.notfound.title")}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("courses.lesson.notfound.text"),
            }}
          />
          <div>
            <a href={courseUrl} className="primary button">
              <Icon icon="angle-left" left />{" "}
              {_c.translate("courses.lesson.notfound.button.label")}
            </a>
          </div>
        </div>
      );
    }
  }

  onUpdateLayout = (
    course,
    lesson,
    lessons,
    status,
    showNotePopup,
    selectedNote,
    player,
    showLessonListOnMobile
  ) => {
    this.setState({
      course,
      lesson,
      lessons,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
    });
  };

  init() {
    const { courseSlug, lessonSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.lesson", {
      courseSlug,
      lessonSlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { course, lessons, lesson, downloads } = response.data.data;

            const bc1 = {
              id: "course",
              to: `${_c
                .custom("urls.course")
                .replace(":courseSlug", course.slug)}`,
              name: course.name,
            };

            const bc2 = {
              id: "course_lesson",
              to: `${_c
                .custom("urls.courseLesson")
                .replace(":courseSlug", course.slug)
                .replace(":lessonSlug", lesson.slug)}`,
              name: lesson.name,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);
            breadcrumbs.push(bc2);

            this.setState(
              {
                course,
                lessons,
                lesson,
                downloads,
                status: "LOADED",
                breadcrumbs,
              },
              () => {
                _c.setPageTitle(`${lesson.name} [${course.name}]`);
              }
            );
          } else if (status === "COURSE_NOTFOUND") {
            this.setState({ status }, () => {
              _c.setPageTitle(`${_c.translate("courses.notfound.title")}`);
            });
          } else if (status === "LESSON_NOTFOUND") {
            const { course } = response.data.data;
            this.setState({ course, status }, () => {
              _c.setPageTitle(
                `${_c.translate("courses.lesson.notfound.title")} [${
                  course.name
                }]`
              );
            });
          } else if (status === "ERROR") {
            return _c.handleError(response.data, "course:lesson:init");
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:lesson:init:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "course:lesson:init");
        }
      });
  }
}

export default withContext(CourseLesson);
