import React, { Component } from "react";
import withContext from "../../context/contextHOC";

class Footer extends Component {
  render() {
    const _c = this.props.context;

    return (
      <footer>
        <div className="default-footer">
          <p className="text-center">
            v{_c.getVersion()} |{" "}
            <a
              href="https://membermate.de/impressum"
              target="_blank"
              rel="noopener noreferrer"
            >
              {_c.translate("pageTitles.legalinfo")}
            </a>{" "}
            |{" "}
            <a
              href="https://membermate.de/datenschutz"
              target="_blank"
              rel="noopener noreferrer"
            >
              {_c.translate("pageTitles.dataprivacy")}
            </a>
          </p>
        </div>
      </footer>
    );
  }
}

export default withContext(Footer);
