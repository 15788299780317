import React, { useEffect, useState } from "react";
import Box from "../../../layout/Box";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import countryList from "../../../../config/countries.json";
import { Icon } from "../../../_foundation/_buttons";
import { cloneDeep } from "lodash";
import validator from "validator";

const required = [
  "firstname",
  "lastname",
  "email",
  "company",
  "street",
  "zip",
  "city",
  "country",
  "bank_iban",
  "bank_holder",
];

const formats = {
  email: "email",
  bank_iban: "iban",
};

function SignupForm({ affiliate, buttonStatus, actions }) {
  const [missing, setMissing] = useState([]);
  const [wrongFormat, setWrongFormat] = useState([]);
  const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(false);

  useEffect(() => {
    if (buttonStatus === "CLICKED") checkForm();
  }, [buttonStatus]);

  useEffect(() => {
    checkForm();
  }, [affiliate]);

  const checkForm = () => {
    const missing = [];
    const wrongFormat = [];
    required.map((requiredProperty) => {
      if (!affiliate[requiredProperty]) missing.push(requiredProperty);
      else if (
        formats[requiredProperty] &&
        !validate(affiliate[requiredProperty], formats[requiredProperty])
      )
        wrongFormat.push(requiredProperty);
      return null;
    });

    setMissing(cloneDeep(missing));
    setWrongFormat(cloneDeep(wrongFormat));

    if (missing.length === 0 && wrongFormat.length === 0) {
      //console.log("missing", missing);
      //console.log("wrong format", wrongFormat);
      setFormIsReadyToSubmit(true);
      actions.onFormCheck(true);
    } else {
      //console.log("missing", missing);
      //console.log("wrong format", wrongFormat);
      setFormIsReadyToSubmit(false);
      actions.onFormCheck(false);
    }
  };

  const validate = (value, type) => {
    let hasCorrectFormat = true;

    if (type === "email" && !validator.isEmail(value)) hasCorrectFormat = false;
    else if (type === "iban" && !validator.isIBAN(value))
      hasCorrectFormat = false;

    return hasCorrectFormat;
  };

  const onEdit = (property, value) => {
    actions.onEdit(property, value);
    checkForm();
  };

  const isMissing = (property) => {
    if (buttonStatus !== "CLICKED") return false;
    return missing.includes(property);
  };

  const hasWrongFormat = (property) => {
    if (buttonStatus !== "CLICKED") return false;
    return wrongFormat.includes(property);
  };

  const propertyHasError = (property) => {
    if (isMissing(property) || hasWrongFormat(property)) return true;
    else return false;
  };

  actions = {
    ...actions,
    propertyHasError,
    isMissing,
    hasWrongFormat,
  };

  return (
    <div className="affiliate-signup-form">
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={24}>
            <h2>Persönliche Informationen</h2>
            <Box>
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="Vorname"
                      property="firstname"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="Nachname"
                      property="lastname"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="email"
                      label="E-Mail-Adresse"
                      property="email"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Box>
            <h2>Adresse</h2>
            <Box>
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="Firma"
                      property="company"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="Straße + Hausnr."
                      property="street"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={8} md={6}>
                    <InputField
                      type="text"
                      label="PLZ"
                      property="zip"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                  <Cell sm={16} md={18}>
                    <InputField
                      type="text"
                      label="Stadt"
                      property="city"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="country"
                      label="Land"
                      property="country"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Box>
            <h2>Steuerliche Informationen</h2>
            <p>
              Bitte gib an, ob Du umsatzsteuerpflichtiger Unternehmer bist. In
              diesem Fall erfolgen die Auszahlungen der Provision (bei
              Firmensitz in Deutschland) mit ausgewiesener Umsatzsteuer. Bei
              Firmensitz im EU-Ausland gib bitte Deine USt-IdNr. an, damit die
              Auszahlungen im Zuge des Reverse-Charge-Verfahrens erfolgen
              können.
            </p>
            <Box>
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="checkbox"
                      label="Ich bin umsatzsteuerpflichtiger Unternehmer."
                      property="business"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                {affiliate.country !== "Deutschland" && (
                  <Row margin="xy">
                    <Cell sm={24}>
                      <InputField
                        type="text"
                        label="USt-IdNr."
                        property="ustidnr"
                        affiliate={affiliate}
                        actions={actions}
                        onEdit={onEdit}
                      />
                    </Cell>
                  </Row>
                )}
              </Grid>
            </Box>
            <h2>Bankkonto für Auszahlung</h2>
            <p>
              Deine Provisionen werden nach den unten stehenden Konditionen auf
              das nachfolgende Bankkonto überwiesen. Es wird{" "}
              <strong>ausdrücklich kein SEPA-Lastschriftmandat</strong> erteilt.
              Die Angabe des Kontos dient ausschließlich der Auszahlung
              anfallender Provisionszahlungen. Wir werden von diesem Konto{" "}
              <strong>kein Geld einziehen</strong>.
            </p>
            <p className="ssl-connection">
              <Icon icon="lock" left /> Die Übermittlung der Bankdaten erfolgt
              mittels verschlüsselter SSL-Verbindung.
            </p>
            <Box>
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="Kontoinhaber"
                      property="bank_holder"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                <Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="IBAN"
                      property="bank_iban"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>
                {/*<Row margin="xy">
                  <Cell sm={24}>
                    <InputField
                      type="text"
                      label="BIC/SWIFT"
                      property="bank_bic"
                      affiliate={affiliate}
                      actions={actions}
                      onEdit={onEdit}
                    />
                  </Cell>
                </Row>*/}
              </Grid>
            </Box>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

const InputField = ({
  type,
  label,
  placeholder,
  property,
  affiliate,
  actions,
  onEdit,
}) => {
  if (type === "text") {
    return (
      <div
        className={`${
          actions.propertyHasError(property) && `missing `
        } affiliates-signup-input-field`}
      >
        <label>
          <div>
            {label}{" "}
            {required.includes(property) && (
              <span className="required-asterisk">*</span>
            )}
          </div>
          <input
            type="text"
            value={affiliate[property] || ""}
            placeholder={placeholder || label}
            onChange={(e) => onEdit(property, e.target.value)}
          />
        </label>
        {actions.hasWrongFormat(property) && (
          <ShowWrongFormat type={formats[property]} />
        )}
      </div>
    );
  } else if (type === "email") {
    return (
      <div
        className={`${
          actions.propertyHasError(property) && `missing `
        } affiliates-signup-input-field`}
      >
        <label>
          <div>
            {label}{" "}
            {required.includes(property) && (
              <span className="required-asterisk">*</span>
            )}
          </div>
          <input
            type="email"
            value={affiliate[property] || ""}
            placeholder={placeholder || label}
            onChange={(e) => onEdit(property, e.target.value)}
          />
        </label>
        {actions.hasWrongFormat(property) && (
          <ShowWrongFormat type={formats[property]} />
        )}
      </div>
    );
  } else if (type === "checkbox") {
    return (
      <div
        className={`${
          actions.propertyHasError(property) && `missing `
        } affiliates-signup-input-field`}
      >
        <label>
          <input
            type="checkbox"
            value={affiliate[property] || ""}
            placeholder={placeholder || label}
            onChange={() => actions.onToggleCheckbox(property)}
          />
          {label}{" "}
          {required.includes(property) && (
            <span className="required-asterisk">*</span>
          )}
        </label>
        {actions.hasWrongFormat(property) && (
          <ShowWrongFormat type={formats[property]} />
        )}
      </div>
    );
  } else if (type === "country") {
    return (
      <div
        className={`${
          actions.propertyHasError(property) && `missing `
        } affiliates-signup-input-field`}
      >
        <label>
          <div>
            {label}{" "}
            {required.includes(property) && (
              <span className="required-asterisk">*</span>
            )}
          </div>
          <select
            value={affiliate[property] || ""}
            onChange={(e) => onEdit(property, e.target.value)}
          >
            {countryList.map((country, index) => {
              return (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              );
            })}
          </select>
        </label>
        {actions.hasWrongFormat(property) && (
          <ShowWrongFormat type={formats[property]} />
        )}
      </div>
    );
  }

  return null;
};

const ShowWrongFormat = ({ type }) => {
  if (type === "email")
    return (
      <div className="small warning callout">
        Bitte gib eine korrekte E-Mail-Adresse an.
      </div>
    );
  else if (type === "iban")
    return (
      <div className="small warning callout">
        Bitte gib eine korrekte IBAN an.
      </div>
    );

  return null;
};

export default SignupForm;
