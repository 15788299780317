import React, { Component } from "react";
import { Grid, Row, Cell } from "../../../_foundation/_grid";
import MainTopbar from "../../../topbars/MainTopbar";
import Breadcrumbs from "../../../topbars/Breadcrumbs";
import withContext from "../../../../context/contextHOC";
import Footer from "../../../layout/Footer";
import Layout1 from "./main/Layout1";
import Layout2 from "./main/Layout2";
import MMPopup from "../../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../../_foundation/_buttons";
import axios from "axios";
import { Link } from "react-router-dom";

class CommunityLayout extends Component {
  state = {
    _updatableProps: [
      "view",
      "layout",
      "breadcrumbs",
      "user",
      "buttons",
      "menu",
    ],
    view: this.props.view,
    layout: this.props.layout,
    breadcrumbs: this.props.breadcrumbs,
    help: this.props.help,
    user: this.props.user,
    buttons: this.props.buttons,
    menu: this.props.menu,
    usernameFound: false,
    username: "",
    usernameStatus: "SUCCESS",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });

        if (propKey === "user") {
          const user = this.props[propKey];
          if (user) {
            const { username } = user;
            if (!username) {
              this.setState({
                usernameFound: false,
                username: user.suggestedUsername,
              });
            } else {
              this.setState({ usernameFound: true });
            }
          }
        }
      }
      return null;
    });
  }

  render() {
    let {
      view,
      layout,
      breadcrumbs,
      user,
      usernameFound,
      help,
      buttons,
      menu,
    } = this.state;
    const _c = this.props.context;

    const showHelp = _c.custom("general.showHelp");

    let v__breadcrumbs = null;
    let classesBreadcrumbs = "";
    if (breadcrumbs) {
      v__breadcrumbs = (
        <Breadcrumbs breadcrumbs={breadcrumbs} buttons={buttons} menu={menu} />
      );
      classesBreadcrumbs = "page-with-breadcrumbs";
    }

    if (!view) {
      view = "areas";
    }

    let mainContentStyles = {};

    if (view) {
      let backgroundUrl = _c.setting(`design_bg_community_${view}`);
      if (backgroundUrl) {
        mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
      }
    }

    let v__content;
    if (layout === "layout1") {
      v__content = <Layout1>{this.props.children}</Layout1>;
    } else if (layout === "layout2") {
      v__content = <Layout2>{this.props.children}</Layout2>;
    }

    if (user && user.email && !usernameFound) {
      v__content = (
        <React.Fragment>{this.view__showPopupNoUsername()}</React.Fragment>
      );
    }

    if (showHelp === true && help) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={20}>
              <div
                className={`page page-community-${view} ${classesBreadcrumbs}`}
              >
                <MainTopbar type="page" />
                {v__breadcrumbs}
                <main className="content" style={mainContentStyles}>
                  <Grid>
                    <Row>
                      <Cell sm={24} md={24}>
                        {v__content}
                      </Cell>
                    </Row>
                  </Grid>
                </main>
                <Footer />
              </div>
            </Cell>
            <Cell md={4} className="hide-for-small-only global-help">
              <div className="global-help-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate(`help.${help}.text`),
                  }}
                />
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <div className={`page page-community-${view} ${classesBreadcrumbs}`}>
          <MainTopbar type="page" />
          {v__breadcrumbs}
          <main className="content" style={mainContentStyles}>
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  {v__content}
                </Cell>
              </Row>
            </Grid>
          </main>
          <Footer />
        </div>
      );
    }
  }

  view__showPopupNoUsername() {
    const { username, usernameStatus } = this.state;
    const _c = this.props.context;

    let v__icon, v__message;
    if (usernameStatus === "LOADING") {
      v__icon = (
        <span className="community-nousername-checkicon-loading">
          <Icon icon="circle-o-notch fa-spin" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-loading">
          <Icon icon="circle-o-notch fa-spin" left />{" "}
          {_c.translate("community.noUsername.popup.messages.loading")}
        </span>
      );
    } else if (usernameStatus === "SUCCESS") {
      v__icon = (
        <span className="community-nousername-checkicon-success">
          <Icon icon="check" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-success">
          <Icon icon="check" left />{" "}
          {_c.translate("community.noUsername.popup.messages.success")}
        </span>
      );
    } else if (usernameStatus === "ERROR") {
      v__icon = (
        <span className="community-nousername-checkicon-error">
          <Icon icon="exclamation-triangle" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-error">
          <Icon icon="exclamation-triangle" left />{" "}
          {_c.translate("community.noUsername.popup.messages.error")}
        </span>
      );
    }

    return (
      <MMPopup
        show={true}
        size="small"
        handleClose={this.handleClose}
        showCloseButton={false}
      >
        <h3>{_c.translate("community.noUsername.popup.title")}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: _c.translate("community.noUsername.popup.text"),
          }}
        />
        <Grid type="full">
          <Row margin="y">
            <Cell sm={22} md={22}>
              <input
                type="text"
                value={username}
                onChange={(e) => {
                  this.handle__editUsername(e.target.value);
                }}
                placeholder={_c.translate(
                  "community.noUsername.popup.form.username.placeholder"
                )}
              />
            </Cell>
            <Cell sm={2} md={2} className="community-nousername-checkicon">
              {v__icon}
            </Cell>
          </Row>
          <Row margin="y">
            <Cell sm={22} md={22}>
              <div className="community-nousername-checkmessage">
                {v__message}
              </div>
            </Cell>
          </Row>
        </Grid>
        <div className="popup-buttons">
          <Link
            className="small primary hollow button"
            to={_c.config("urls.home")}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </Link>
          <button
            className="small primary button"
            onClick={this.handle__save}
            disabled={usernameStatus === "SUCCESS" ? false : true}
          >
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      </MMPopup>
    );
  }

  handle__editUsername(newValue) {
    newValue = newValue.replace(/[^a-z0-9_.]+|\s+/gim, "").toLowerCase();

    this.setState({ username: newValue }, () => {
      const { username } = this.state;

      if (!username) {
        this.setState({ usernameStatus: "ERROR" });
      } else {
        this.handle__checkUsername();
      }
    });
  }

  handleClose = () => {
    //
  };

  handle__checkUsername() {
    const { username } = this.state;
    const _c = this.props.context;

    this.setState({ usernameStatus: "LOADING" });

    let apiUrl = _c.apiUrl("community.checkUsername", { username });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          this.setState({ usernameStatus: status });
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:checkusername:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:checkusername:init");
      });
  }

  handle__save = () => {
    const { username } = this.state;
    const _c = this.props.context;

    this.setState({ usernameStatus: "LOADING" });

    let apiUrl = _c.apiUrl("community.checkUsername", { username });

    axios
      .post(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            window.location.reload();
          } else {
            this.setState({ usernameStatus: status });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:saveusername:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:saveusername:init");
      });
  };

  init() {
    const _c = this.props.context;
    _c.init();
  }
}

export default withContext(CommunityLayout);
