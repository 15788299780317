import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { Link } from "react-router-dom";
import DefaultLayout from "../../layout/DefaultLayout";

class ChangePassword extends Component {
  state = {
    showMsgSaved: false,
    showErrorEmpty: false,
    showErrorOldWrong: false,
    showErrorNewWrong: false,
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <DefaultLayout
        help="changePassword"
        breadcrumbs={["account", "password"]}
        page="change-password"
        background="account"
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14}>
              <h2>{_c.translate("changePassword.title")}</h2>
              <p className="lead">{_c.translate("changePassword.text")}</p>
              {this.view__showMsgSaved()}
              {this.view__showErrorEmpty()}
              {this.view__showErrorOldWrong()}
              {this.view__showErrorNewWrong()}
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={14}>
              <div className="box">
                <div className="box-content">
                  <label>
                    {_c.translate("changePassword.form.old.label")}
                    <input
                      type="password"
                      placeholder={_c.translate(
                        "changePassword.form.old.placeholder"
                      )}
                      value={this.state.oldPassword}
                      onChange={this.handle__edit("oldPassword")}
                    />
                  </label>
                </div>
              </div>

              <div className="box">
                <div className="box-content">
                  <label>
                    {_c.translate("changePassword.form.new.label")}
                    <input
                      type="password"
                      placeholder={_c.translate(
                        "changePassword.form.new.placeholder"
                      )}
                      value={this.state.newPassword}
                      onChange={this.handle__edit("newPassword")}
                    />
                  </label>
                  <div>&nbsp;</div>
                  <label>
                    {_c.translate("changePassword.form.new2.label")}
                    <input
                      type="password"
                      placeholder={_c.translate(
                        "changePassword.form.new2.placeholder"
                      )}
                      value={this.state.newPassword2}
                      onChange={this.handle__edit("newPassword2")}
                    />
                  </label>
                </div>
              </div>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={14}>
              <div className="page-buttons">
                <Link to="/dashboard" className="primary hollow button">
                  <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                </Link>
                <button
                  className="primary button"
                  onClick={() => {
                    this.checkUserInput();
                  }}
                >
                  <Icon icon="check" left />{" "}
                  {_c.translate("changePassword.form.buttons.save.label")}
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }

  view__showMsgSaved() {
    const _c = this.props.context;

    if (this.state.showMsgSaved === true) {
      return (
        <div className="success callout">
          <strong>{_c.translate("changePassword.messages.saved")}</strong>
        </div>
      );
    }
  }

  view__showErrorEmpty() {
    const _c = this.props.context;

    if (this.state.showErrorEmpty === true) {
      return (
        <div className="alert callout">
          <strong>{_c.translate("changePassword.errors.empty")}</strong>
        </div>
      );
    }
  }

  view__showErrorOldWrong() {
    const _c = this.props.context;

    if (this.state.showErrorOldWrong === true) {
      return (
        <div className="alert callout">
          <strong>{_c.translate("changePassword.errors.oldWrong")}</strong>
        </div>
      );
    }
  }

  view__showErrorNewWrong() {
    const _c = this.props.context;

    if (this.state.showErrorNewWrong === true) {
      return (
        <div className="alert callout">
          <strong>{_c.translate("changePassword.errors.newWrong")}</strong>
        </div>
      );
    }
  }

  handle__edit = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  resetMessages(callback) {
    this.setState({
      showErrorEmpty: false,
      showErrorOldWrong: false,
      showErrorNewWrong: false,
      showMsgSaved: false,
    });

    callback();
  }

  checkUserInput() {
    const { oldPassword, newPassword, newPassword2 } = this.state;

    if (!oldPassword || !newPassword || !newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorEmpty: true });
      });

      return;
    }

    if (newPassword !== newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorNewWrong: true });
      });
      return;
    }

    // If Old Password is correct will be checked in save()
    this.save();
  }

  save() {
    const { oldPassword, newPassword } = this.state;
    const _c = this.props.context;

    _c.setIsSaving(true, 1);

    axios
      .patch(
        _c.apiUrl("password.change"),
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        { headers: _c.headers }
      )
      .then((response) => {
        if (response.data === "oldWrong") {
          this.resetMessages(() => {
            this.setState({ showErrorOldWrong: true });
          });
        } else if (response.data === "OK") {
          _c.setIsSaving(false);

          this.resetMessages(() => {
            this.setState({
              showMsgSaved: true,
              oldPassword: "",
              newPassword: "",
              newPassword2: "",
            });
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiUrl("home.home"), {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === 200) {
            _c.handleApiResponse(response.data, true);
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "account:password:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "account:password:init");
      });
  }
}

export default withContext(ChangePassword);
