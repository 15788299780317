import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./stylesheets/membermate.theme.scss";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./components/user/auth/Login";
import PasswordReset from "./components/user/auth/PasswordReset";
import ChangePassword from "./components/user/auth/ChangePassword";
import AdminDashboard from "./components/admin/dashboard/AdminDashboard";
import AdminMembers from "./components/admin/members/AdminMembers";
import AdminMember from "./components/admin/members/AdminMember";
import MyAccount from "./components/user/MyAccount";
import AdminLiveCalls from "./components/admin/livecalls/AdminLiveCalls";
import AdminLiveCall from "./components/admin/livecalls/AdminLiveCall";
import CourseLesson from "./components/modules/courses/CourseLesson";
import CourseOverview from "./components/modules/courses/CourseOverview";
import CourseDirectAccess from "./components/modules/courses/CourseDirectAccess";
import Index from "./components/website/Index";
import Home from "./components/home/Home";
import CommunityAreas from "./components/modules/community/CommunityAreas";

import membermateConfig from "./config/membermate.config.json";
import CommunityBoard from "./components/modules/community/CommunityBoard";
import CommunityThread from "./components/modules/community/CommunityThread";
import NewThread from "./components/modules/community/NewThread";
import Live from "./components/modules/live/Live";
import Profile from "./components/modules/community/Profile";
import Privacy from "./components/user/Privacy";
import AdminCommunity from "./components/admin/community/AdminCommunity";
import AdminCourses from "./components/admin/courses/AdminCourses";
import AdminCourse from "./components/admin/courses/AdminCourse";
import Signup from "./components/user/auth/Signup";
import Visionboard from "./components/modules/visionboard/Visionboard";
import AdminSettings from "./components/admin/settings/AdminSettings";
import Routines from "./components/modules/routines/Routines";
import LegalInfo from "./components/pages/LegalInfo";
import DataPrivacy from "./components/pages/DataPrivacy";
import AdminAdmins from "./components/admin/members/AdminAdmins";
import AdminLicense from "./components/admin/license/AdminLicense";
import AdminTools from "./components/admin/tools/AdminTools";
import AdminToolImport from "./components/admin/tools/AdminToolImport";
import AdminDesign from "./components/admin/design/AdminDesign";
import GetStarted from "./components/pages/getstarted/GetStarted";
import Affiliate from "./components/pages/affiliate/Affiliate";
import AffiliateSignup from "./components/pages/affiliate/signup/AffiliateSignup";

class App extends Component {
  render() {
    let urls = membermateConfig.urls;

    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/password/reset"
            render={(props) => <PasswordReset {...props} />}
          />
          <Route
            path="/login/password"
            render={(props) => <Login {...props} view="forgotten" />}
          />

          <Route
            path="/r/:affiliateIdentifier"
            render={(props) => <Affiliate {...props} />}
          />

          <Route
            path="/affiliates/apply"
            render={(props) => <AffiliateSignup {...props} />}
          />

          {/* 
            Onboarding
            /welcome
          */}
          {/*<Route
            path={urls.onboarding.onboarding}
            render={(props) => <Onboarding {...props} />}
          />*/}

          {/* 
            Onboarding v2
            /getstarted
          */}
          <Route
            path={urls.getstarted.getstarted}
            render={(props) => <GetStarted {...props} />}
          />

          {/* 
            Onboarding v2
            /getstarted
          */}
          <Route
            path={urls.getstarted.noidentifier}
            render={(props) => <GetStarted {...props} />}
          />

          {/* 
            Impressum / Legal Info
            /impressum
          */}
          <Route
            path={urls.legalinfo}
            render={(props) => <LegalInfo {...props} />}
          />

          {/* 
            Datenschutz / Data Privacy
            /datenschutz
          */}
          <Route
            path={urls.dataprivacy}
            render={(props) => <DataPrivacy {...props} />}
          />

          {/* 
            Login Page
            /login
          */}
          <Route
            path={urls.auth.login}
            render={(props) => <Login {...props} view="login" />}
          />

          {/* 
            Signup Page
            /signup
          */}
          <Route
            path={urls.auth.signup}
            render={(props) => <Signup {...props} view="signup" />}
          />

          {/* 
            Change Password
            /password
          */}
          <Route
            path={urls.password}
            render={(props) => <ChangePassword {...props} />}
          />

          {/* 
            Routinentracker Selected Month
            /routines
          */}
          <Route
            path={urls.routinesMonth}
            render={(props) => <Routines {...props} />}
          />

          {/* 
            Routinentracker
            /routines
          */}
          <Route
            path={urls.routines}
            render={(props) => <Routines {...props} />}
          />

          {/* 
            Next Live Call
            /live
          */}
          <Route
            path={urls.live.next}
            render={(props) => <Live {...props} />}
          />

          {/* 
            Visionboard
            /visionboard
          */}
          <Route
            path={urls.visionboard}
            render={(props) => <Visionboard {...props} />}
          />

          {/* 
            Community Profile
            /community/@:username
          */}
          <Route
            path={urls.communityProfile}
            render={(props) => <Profile {...props} />}
          />

          {/* 
            Community New Thread
            /community/:boardSlug/new
          */}
          <Route
            path={urls.communityNewThread}
            render={(props) => <NewThread {...props} />}
          />

          {/* 
            Community Thread
            /community/:boardSlug/:threadSlug
          */}
          <Route
            path={urls.communityThread}
            render={(props) => <CommunityThread {...props} />}
          />

          {/* 
            Community Board (Thread Overview)
            /community/:boardSlug
          */}
          <Route
            path={urls.communityBoard}
            render={(props) => <CommunityBoard {...props} />}
          />

          {/* 
            Community Areas (Main Page)
            /community
          */}
          <Route
            path={urls.communityAreas}
            render={(props) => <CommunityAreas {...props} />}
          />

          {/* 
            Course Lesson
            /course/:courseSlug/:lessonSlug
          */}
          <Route
            path={urls.courseLesson}
            render={(props) => <CourseLesson {...props} />}
          />

          {/* 
            Course Direct URL
            /course/:courseSlug
          */}
          <Route
            path={urls.course}
            render={(props) => <CourseDirectAccess {...props} />}
          />

          {/* 
            Courses Overview
            /courses
          */}
          <Route
            path={urls.courses}
            render={(props) => <CourseOverview {...props} />}
          />

          <Route
            path="/admin/members/:id"
            render={(props) => <AdminMember {...props} />}
          />
          <Route
            path="/admin/members"
            render={(props) => <AdminMembers {...props} />}
          />

          {/* 
            Admin membermate License
            /admin/license
          */}
          <Route
            path={urls.admin.license}
            render={(props) => <AdminLicense {...props} />}
          />

          {/* 
            Admin Admins
            /admin/admins
          */}
          <Route
            path={urls.admin.admins}
            render={(props) => <AdminAdmins {...props} />}
          />

          <Route
            path="/admin/livecalls/:id"
            render={(props) => <AdminLiveCall {...props} />}
          />
          <Route
            path="/admin/livecalls"
            render={(props) => <AdminLiveCalls {...props} />}
          />

          {/* 
            Admin Community
            /admin/community
          */}
          <Route
            path={urls.admin.community}
            render={(props) => <AdminCommunity {...props} />}
          />

          {/* 
            Admin Course
            /admin/courses/:id
          */}
          <Route
            path={urls.admin.course}
            render={(props) => <AdminCourse {...props} />}
          />

          {/* 
            Admin Courses
            /admin/courses
          */}
          <Route
            path={urls.admin.courses}
            render={(props) => <AdminCourses {...props} />}
          />

          {/* 
            Admin Settings
            /admin/settings
          */}
          <Route
            path={urls.admin.settings}
            render={(props) => <AdminSettings {...props} />}
          />

          {/* 
            Admin Design
            /admin/design
          */}
          <Route
            path={urls.admin.design}
            render={(props) => <AdminDesign {...props} />}
          />

          {/* 
            Admin Tools: CSV Import
            /admin/tools/import
          */}
          <Route
            path={urls.admin.tools.import}
            render={(props) => <AdminToolImport {...props} />}
          />

          {/* 
            Admin Tools
            /admin/tools
          */}
          <Route
            path={urls.admin.tools.tools}
            render={(props) => <AdminTools {...props} />}
          />

          {/* 
            Admin Dashboard
            /admin
          */}
          <Route
            path="/admin"
            render={(props) => <AdminDashboard {...props} />}
          />

          <Route
            path="/home"
            render={(props) => <Home {...props} access={"secured"} />}
          />

          {/* 
            My own profile / account edit
            /courses
          */}
          <Route
            path={urls.account}
            render={(props) => <MyAccount {...props} />}
          />

          {/* 
            Own privacy settings
            /privacy
          */}
          <Route
            path={urls.privacy}
            render={(props) => <Privacy {...props} />}
          />

          <Route path="/" render={(props) => <Index {...props} />} />
        </Switch>
        <ScrollToTop />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
