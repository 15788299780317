import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

class HomeItemVisionboardImage extends Component {
  state = {
    homeInfo: this.props.homeInfo,
    sortMode: this.props.sortMode,
    options: this.props.options,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.homeInfo !== prevProps.homeInfo) {
      this.setState({ homeInfo: this.props.homeInfo });
    }
    if (this.props.sortMode !== prevProps.sortMode) {
      this.setState({ sortMode: this.props.sortMode });
    }
    if (this.props.options !== prevProps.options) {
      this.setState({ options: this.props.options });
    }
  }

  render() {
    const _c = this.props.context;

    const { options, sortMode } = this.state;

    let styles = {};
    if (options && options.image) {
      styles.backgroundImage = `url('${options.image}')`;
    }

    let v__output;
    let v__content = (
      <div className="home-item-content">
        <div className="home-item-text"></div>
      </div>
    );

    if (sortMode === true) {
      v__output = (
        <div className="home-item home-item-visionboard" style={styles}>
          {v__content}
        </div>
      );
    } else {
      v__output = (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <Link
            to={_c.config("urls.visionboard")}
            className="home-item home-item-visionboard"
            style={styles}
            data-tip={_c.translate("home.items.visionboard")}
          >
            {v__content}
          </Link>
        </React.Fragment>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }
}

export default withContext(HomeItemVisionboardImage);
