import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";
import { Link } from "react-router-dom";

class LoginForm extends Component {
  state = {
    _updatableProps: ["status", "show", "input"],
    status: this.props.status,
    show: this.props.show,
    input: this.props.input,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return <React.Fragment>{this.view__showForm()}</React.Fragment>;
  }

  view__showForm() {
    const _c = this.props.context;
    const loginLayout = _c.setting("login_layout");

    let v__output;

    if (loginLayout === "horizontal") {
      v__output = (
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={12}>
              {this.view__showFormFieldEmail()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showFormFieldPassword()}
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>{this.view__showFormButtons()}</Cell>
          </Row>
        </Grid>
      );
    } else {
      v__output = (
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={24}>
              <div style={{ marginBottom: "10px" }}>
                {this.view__showFormFieldEmail()}
              </div>
            </Cell>
            <Cell sm={24} md={24}>
              <div style={{ marginBottom: "10px" }}>
                {this.view__showFormFieldPassword()}
              </div>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>{this.view__showFormButtons()}</Cell>
          </Row>
        </Grid>
      );
    }

    return v__output;
  }

  view__showFormFieldEmail() {
    const { input } = this.state;
    const { email } = input;
    const _c = this.props.context;

    return (
      <label>
        {_c.translate("auth.login.form.fields.email.label")}
        <input
          type="email"
          placeholder={_c.translate("auth.login.form.fields.email.placeholder")}
          value={email}
          onChange={(e) => {
            this.props.onUpdateInputChange("email", e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              this.props.onAttemptLogin();
            }
          }}
        />
      </label>
    );
  }

  view__showFormFieldPassword() {
    const { input } = this.state;
    const { password } = input;
    const _c = this.props.context;

    return (
      <label>
        {_c.translate("auth.login.form.fields.password.label")}
        <input
          type="password"
          placeholder={_c.translate(
            "auth.login.form.fields.password.placeholder"
          )}
          value={password}
          onChange={(e) => {
            this.props.onUpdateInputChange("password", e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              this.props.onAttemptLogin();
            }
          }}
        />
      </label>
    );
  }

  view__showFormButtons() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <p className="text-center">
          <Link to="/login/password" className="login-password-forgotten">
            {_c.translate("auth.login.form.forgotten.label")}
          </Link>
        </p>
        <div className="login-buttons">
          <button
            className="primary button"
            onClick={this.props.onAttemptLogin}
          >
            {_c.translate("auth.login.form.buttons.login.label")}{" "}
            <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withContext(LoginForm);
