import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../_foundation/foundation";
import Loading from "../_reusables/Loading";

import imageCloseButton from "../../images/close-button.png";

class Search extends Component {
  state = {
    show: this.props.show,
    s: "",
    delayTimer: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show: this.props.show });
    }
  }

  render() {
    const { show } = this.state;

    if (show === true) {
      return (
        <div className="main-search-wrapper">
          <div className="main-search">
            {this.view__showSearchField()}
            {this.view__showSearchResults()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  view__showSearchField() {
    const { s } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="search-field">
          <Grid>
            <Row margin="xy">
              <Cell sm={1} md={1}>
                <div className="search-icon">
                  <Icon icon="search" />
                </div>
              </Cell>
              <Cell sm={22} md={21}>
                <input
                  type="text"
                  value={s}
                  onChange={(e) => {
                    const s = e.target.value;
                    this.setState({ s }, () => {
                      if (s === "") {
                        _c.resetSearch();
                      } else {
                        this.search();
                      }
                    });
                  }}
                  placeholder={_c.translate("search.form.search.placeholder")}
                />
              </Cell>
              <Cell sm={1} md={2} className="text-right">
                <img
                  className="search-close-button"
                  src={imageCloseButton}
                  alt="X"
                  onClick={this.toggleSearch}
                />
              </Cell>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  view__showSearchResults() {
    const { s } = this.state;
    const _c = this.props.context;

    const search = _c.getSearchResult();

    if (s !== "" && !search) {
      return <Loading show={true} />;
    }

    let v__searchResults;
    if (s === "") {
      v__searchResults = (
        <p className="search-results-pleaseinput">
          {_c.translate("search.results.pleaseinput")}
        </p>
      );
    } else {
      if (search && search.results && search.results.length === 0) {
        v__searchResults = (
          <p className="search-results-pleaseinput">
            {_c.translate("search.results.noresults")}
          </p>
        );
      } else {
        v__searchResults = (
          <ul className="search-results-list">
            {search.results.map((result, index) => {
              let v__type;
              let v__text = (
                <span>{_c.translate("search.results.nodescription")}</span>
              );
              if (result.text) {
                v__text = <span>{result.text}</span>;
              }

              v__type = _c.translate(`search.results.types.${result.type}`);

              v__type = <div className="search-result-type">{v__type}</div>;

              let v__image;
              if (result.image) {
                v__image = (
                  <div className="float-left search-result-image">
                    <img src={result.image} alt="" />
                  </div>
                );
              } else {
                if (
                  result.type === "community_thread" ||
                  result.type === "community_post"
                ) {
                  v__image = (
                    <div className="float-left search-result-icon">
                      <div className="center-vertically">
                        <Icon icon="comments-o" />
                      </div>
                    </div>
                  );
                }
              }

              const url = this.getUrl(result);

              return (
                <li key={index}>
                  <a href={url}>
                    {v__image}
                    {v__type}
                    <div className="search-result-title">{result.title}</div>
                    <div className="search-result-text">{v__text}</div>
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }
    }

    return (
      <div className="search-results">
        <Grid>
          <Row margin="y">
            <Cell sm={24}>
              <h1>{_c.translate("search.results.title")}</h1>
            </Cell>
          </Row>
          <Row margin="y">
            <Cell sm={24}>{v__searchResults}</Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  toggleSearch = () => {
    if (this.props.toggleSearch) {
      this.props.toggleSearch();
    }
  };

  getUrl = (result) => {
    const _c = this.props.context;

    let url = "/";

    if (result.type === "course") {
      url = _c.config("urls.course").replace(":courseSlug", result.slug);
    } else if (result.type === "lesson") {
      url = _c
        .custom("urls.courseLesson")
        .replace(":courseSlug", result.courseslug)
        .replace(":lessonSlug", result.slug);
    } else if (result.type === "course_category") {
      url = _c.config("urls.course").replace(":courseSlug", result.courseslug);
    } else if (result.type === "course_download") {
      url = _c.config("urls.course").replace(":courseSlug", result.courseslug);
    } else if (result.type === "livecall") {
      url = _c.config("urls.live.next");
    } else if (result.type === "community_thread") {
      url = _c
        .custom("urls.communityThread")
        .replace(":boardSlug", result.boardSlug)
        .replace(":threadSlug", result.threadSlug);
    } else if (result.type === "community_post") {
      url = `${_c
        .custom("urls.communityThread")
        .replace(":boardSlug", result.boardSlug)
        .replace(":threadSlug", result.threadSlug)}#post_${result.postSlug}`;
    }

    return url;
  };

  search() {
    let { delayTimer, s } = this.state;
    const _c = this.props.context;

    clearTimeout(delayTimer);
    delayTimer = setTimeout(function () {
      _c.resetSearch();
      _c.search(s);
    }, 350);
    this.setState({ delayTimer });
  }
}

export default withContext(Search);
