import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Link } from "react-router-dom";

class HomeItemCourses extends Component {
  state = {
    sortMode: this.props.sortMode,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sortMode !== prevProps.sortMode) {
      this.setState({ sortMode: this.props.sortMode });
    }
  }

  render() {
    const { sortMode } = this.state;
    const _c = this.props.context;

    let v__output;
    let v__content = (
      <div className="home-item-content">
        <div className="home-item-text">
          {_c.translate("home.items.courses")}
        </div>
      </div>
    );

    if (sortMode === true) {
      v__output = (
        <div className="home-item home-item-courses">{v__content}</div>
      );
    } else {
      v__output = (
        <Link
          to={_c.config("urls.courses")}
          className="home-item home-item-courses"
        >
          {v__content}
        </Link>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }
}

export default withContext(HomeItemCourses);
