import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Link } from "react-router-dom";
import Countdown from "../../../_reusables/Countdown";
import { Icon } from "../../../_foundation/_buttons";
import { DateTime } from "luxon";

class HomeItemNextLivecall extends Component {
  state = {
    homeInfo: this.props.homeInfo,
    sortMode: this.props.sortMode,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.homeInfo !== prevProps.homeInfo) {
      this.setState({ homeInfo: this.props.homeInfo });
    }
    if (this.props.sortMode !== prevProps.sortMode) {
      this.setState({ sortMode: this.props.sortMode });
    }
  }

  render() {
    const { homeInfo, sortMode } = this.state;
    const { nextLivecall } = homeInfo;
    const _c = this.props.context;

    let v__homeItem = (
      <div className="home-item home-item-nextlivecall home-item-nonextlivecall">
        <div className="home-item-content">
          <div className="home-item-text">
            {_c.translate("home.items.noLivecall")}
          </div>
        </div>
      </div>
    );

    if (nextLivecall) {
      if (nextLivecall.status === "ONAIR") {
        let v__content = (
          <div className="home-item-content">
            <div className="home-item-text">
              <span className="home-item-nextlivecall-started">
                <Icon icon="youtube-play" left />{" "}
                {_c.translate("home.items.livecallStarted")}
              </span>
            </div>
          </div>
        );

        if (sortMode === true) {
          v__homeItem = (
            <div className="home-item home-item-nextlivecall">{v__content}</div>
          );
        } else {
          v__homeItem = (
            <Link
              to={_c.config("urls.live.next")}
              className="home-item home-item-nextlivecall"
            >
              {v__content}
            </Link>
          );
        }
      } else if (nextLivecall.status === "UPCOMING") {
        let v__content = (
          <div className="home-item-content">
            <div className="home-item-text">
              {_c.translate("home.items.nextLivecall")}
              <div>
                <Countdown
                  date={DateTime.fromISO(nextLivecall.countdown_date, {
                    setZone: true,
                  })}
                  onZero={() => {
                    nextLivecall.status = "ONAIR";
                    homeInfo.nextLivecall = nextLivecall;
                    this.setState({ homeInfo });
                  }}
                  type="home"
                />
              </div>
            </div>
          </div>
        );

        if (sortMode === true) {
          v__homeItem = (
            <div className="home-item home-item-nextlivecall">{v__content}</div>
          );
        } else {
          v__homeItem = (
            <Link
              to={_c.config("urls.live.next")}
              className="home-item home-item-nextlivecall"
            >
              {v__content}
            </Link>
          );
        }
      }
    }

    return v__homeItem;
  }

  init() {
    const { homeInfo } = this.state;
    let { nextLivecall } = homeInfo;

    if (nextLivecall) {
      homeInfo.nextLivecall = nextLivecall;
    }

    this.setState({ homeInfo });
  }
}

export default withContext(HomeItemNextLivecall);
