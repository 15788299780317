import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../../../_foundation/foundation";
import Box from "../../../layout/Box";
import LoginForm from "./LoginForm";
import PasswordForgottenForm from "./PasswordForgottenForm";
import Loading from "../../../_reusables/Loading";

class LoginView extends Component {
  state = {
    _updatableProps: ["status", "view", "errors", "messages", "input"],
    status: this.props.status,
    view: this.props.view,
    errors: this.props.errors,
    messages: this.props.messages,
    input: this.props.input,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { status, view, input } = this.state;
    const _c = this.props.context;

    let v__logo;
    const logoLogin = _c.setting("logo_login");

    if (logoLogin) {
      v__logo = <img src={logoLogin} alt="Logo" />;
    }

    let mainContentStyles = {};

    let backgroundUrl = _c.setting(`design_bg_login`);
    if (backgroundUrl) {
      mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
    }

    return (
      <div className="page page-login">
        <main className="content" style={mainContentStyles}>
          <Grid>
            <Row>
              <Cell sm={24} md={12} mdo={6}>
                <Box>
                  <div className="login-logo">
                    <Link to="/">{v__logo}</Link>
                  </div>

                  {this.view__showTitle()}
                  {this.view__showIntro()}

                  {this.view__showMessages()}
                  {this.view__showErrors()}

                  <Loading show={status === "LOADING"} />

                  <LoginForm
                    show={view === "login" && status !== "LOADING"}
                    status={status}
                    input={input}
                    onUpdateInputChange={this.props.onUpdateInputChange}
                    onUpdateStatus={this.props.onUpdateStatus}
                    onUpdateView={this.props.onUpdateView}
                    onAttemptLogin={this.props.onAttemptLogin}
                  />

                  <PasswordForgottenForm
                    show={
                      view === "forgotten" &&
                      status !== "SUCCESS" &&
                      status !== "LOADING"
                    }
                    status={status}
                    input={input}
                    onUpdateInputChange={this.props.onUpdateInputChange}
                    onUpdateStatus={this.props.onUpdateStatus}
                    onUpdateView={this.props.onUpdateView}
                    onAttemptReset={this.props.onAttemptReset}
                  />
                </Box>
              </Cell>
            </Row>
          </Grid>
        </main>
      </div>
    );
  }

  view__showTitle() {
    const { view } = this.state;
    const _c = this.props.context;

    let v__title = "[Unknown]";

    if (view === "forgotten") {
      v__title = _c.translate("auth.login.views.forgotten.title");
    } else if (view === "login") {
      v__title = _c.translate("auth.login.views.login.title");
    }

    return (
      <div className="login-title">
        <h1>{v__title}</h1>
      </div>
    );
  }

  view__showIntro() {
    const { view } = this.state;
    const _c = this.props.context;

    let v__intro = "";
    let v__introSignup;

    if (view === "forgotten") {
      v__intro = _c.translate("auth.login.views.forgotten.intro");
    } else if (view === "login") {
      v__intro = _c.translate("auth.login.views.login.intro");

      if (_c.setting("signup_enabled") === true) {
        v__introSignup = (
          <div className="callout">
            <div
              dangerouslySetInnerHTML={{
                __html: _c
                  .translate("auth.login.views.login.introSignup")
                  .replace("{signupUrl}", _c.config("urls.auth.signup")),
              }}
            />
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <div
          className="login-intro"
          dangerouslySetInnerHTML={{ __html: v__intro }}
        />
        {v__introSignup}
      </React.Fragment>
    );
  }

  view__showMessages() {
    let { messages } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(messages).map((messageKey) => {
      if (messages[messageKey] === true) {
        let v__error = (
          <div className="login-message success callout" key={messageKey}>
            <div>
              <strong>
                {_c.translate(`auth.login.messages.${messageKey}.title`)}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate(`auth.login.messages.${messageKey}.text`),
              }}
            />
          </div>
        );

        if (messageKey === "success_passwordforgotten") {
          v__output.push(v__error);
        }
      }
      return null;
    });

    return <div className="login-messages">{v__output}</div>;
  }

  view__showErrors() {
    let { errors } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(errors).map((errorKey) => {
      let errorColor = "alert";
      if (errorKey === "exists") {
        errorColor = "warning";
      }

      if (errors[errorKey] === true) {
        let v__error = (
          <div className={`login-error ${errorColor} callout`} key={errorKey}>
            <div>
              <strong>
                {_c.translate(`auth.login.errors.${errorKey}.title`)}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c
                  .translate(`auth.login.errors.${errorKey}.text`)
                  .replace("{supportemail}", _c.setting("supportemail")),
              }}
            />
          </div>
        );

        if (
          errorKey === "notfound" ||
          errorKey === "invalid" ||
          errorKey === "empty" ||
          errorKey === "forgottenempty" ||
          errorKey === "denied" ||
          errorKey === "exists"
        ) {
          v__output.push(v__error);
        }
      }
      return null;
    });

    return <div className="login-errors">{v__output}</div>;
  }
}

export default withContext(LoginView);
