import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";
import AdminLog from "../AdminLog";

import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdminTags from "../AdminTags";
import AdminCourseLessons from "./AdminCourseLessons";
import AdminDigistoreIds from "./AdminDigistoreIds";
import AdminCourseDownloads from "./AdminCourseDownloads";
import { cloneDeep } from "lodash";
import AdminElopageIds from "./AdminElopageIds";

class AdminCourse extends Component {
  state = {
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_courses"],
    tabs: {},
    selectedTab: "course",
    course: undefined,
    original: undefined,
    newLesson: null,
    newCategory: null,
    newDownload: null,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    uploadImageStatus: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 16 / 10,
    },
    showDuplicatePopup: false,
    duplicate: {},
    originalDuplicate: {
      name: "",
      info: true,
      categories: true,
      lessons: true,
      downloads: true,
    },
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("courses");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    return (
      <AdminLayout nav="courses" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={_c.config("urls.admin.courses")} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, livecall } = this.state;
    const _c = this.props.context;

    if (!livecall) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.livecalls.delete.confirm", {
                  livecallTitle: livecall.title,
                }),
              }}
            />
          }
          button_1={{
            title: _c.translate("admin.livecalls.delete.buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("admin.livecalls.delete.buttons.yes"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { course, original, requestedId } = this.state;
    const _c = this.props.context;

    if (!course) {
      return _c.loading();
    }

    if (course && requestedId === "new") {
      return <h1>{_c.translate("admin.courses.addCourse")}</h1>;
    } else if (course && course.id && course.name) {
      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">
            {_c.translate("admin.courses.course.course")}
          </div>
          <h1>{original.name}</h1>
        </React.Fragment>
      );
    }
  }

  view__showPageActions() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="small primary button-group no-gaps">
          <button
            className="primary hollow button"
            onClick={() => {
              this.setState({ showDialogDelete: true });
            }}
          >
            <Icon icon="trash" left />{" "}
            {_c.translate("admin.courses.delete.button.label")}
          </button>
          <button
            className="primary hollow button"
            onClick={() => {
              this.setState({ showDuplicatePopup: true });
            }}
          >
            <Icon icon="files-o" left />{" "}
            {_c.translate("admin.courses.duplicate.button.label")}
          </button>
          <button className="button" onClick={this.handle__toggleEditMode}>
            <Icon icon="cog" left />{" "}
            {_c.translate("admin.courses.edit.button.label")}
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip={_c.translate(
              "admin.courses.course.buttons.cancel.tooltip"
            )}
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button
            className="small primary button"
            data-tip={_c.translate("admin.courses.course.buttons.save.tooltip")}
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;
    const _c = this.props.context;

    if (editLock === true) {
      const message = _c
        .translate("editLock.message")
        .replace("{editLockUser}", editLockUser);

      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { course, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(course)) {
      return _c.loading();
    } else if (course === null) {
      return _c.notfound(_c.translate("admin.courses.course.notfound"));
    }

    if (selectedTab === "course") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxCourseInfo()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxCourseImage()}
                {this.view__showBoxDigistoreIds()}
                {this.view__showBoxTags()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "lessons") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                {this.view__showBoxLessons()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "downloads") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxDownloads()}
              </Cell>
              <Cell sm={24} md={12}>
                &nbsp;
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxCourseInfo() {
    const { course, editMode } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>{_c.translate("admin.courses.course.info")}</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.name}
                  editValue={course.name}
                  editType="text"
                  label={_c.translate("admin.courses.course.name")}
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("name", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.description}
                  editValue={course.description}
                  editType="textarea"
                  label={_c.translate("admin.courses.course.description")}
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("description", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>
                  {_c.translate("admin.courses.course.purchaseUrl.title")}
                </h3>
                <p>{_c.translate("admin.courses.course.purchaseUrl.text")}</p>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.purchase_url}
                  editValue={course.purchase_url}
                  editType="text"
                  label={_c.translate("admin.courses.course.purchaseUrl.title")}
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("purchase_url", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  view__showBoxCourseImage() {
    const { course, editMode } = this.state;
    const _c = this.props.context;

    let courseImage = (
      <div className="admin-course-noimage wrapper">
        <div className="center-vertically">
          {_c.translate("admin.courses.course.image.noImage")}
        </div>
      </div>
    );
    if (course.image) {
      courseImage = <img src={course.image} alt="Preview" />;
    }

    let uploadButton = (
      <button className="button" onClick={this.showPopupImageUpload}>
        <Icon icon="upload" left />{" "}
        {_c.translate("admin.courses.course.image.uploadImage")}
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupImageDelete}>
        <Icon icon="trash" left />{" "}
        {_c.translate("admin.courses.course.image.deleteImage")}
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !course.image) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.courses.course.image.title")}</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-course-image">{courseImage}</div>
              <div className="admin-course-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showPopups() {
    const {
      uploadImageStatus,
      cropUrl,
      crop,
      showDuplicatePopup,
      duplicate,
      course,
    } = this.state;
    const _c = this.props.context;

    if (!duplicate || !course) {
      return;
    }

    if (showDuplicatePopup === true) {
      return (
        <React.Fragment>
          <MMPopup
            size="small"
            show={true}
            handleClose={() => {
              this.setState({ showDuplicatePopup: false });
            }}
          >
            <h3>{_c.translate("admin.courses.duplicate.popup.title")}</h3>
            <Grid type="full">
              <Row>
                <Cell sm={24} md={24}>
                  <AdminDataField
                    value={duplicate.name}
                    editValue={duplicate.name}
                    editType="text"
                    label={_c.translate(
                      "admin.courses.duplicate.popup.form.name.label"
                    )}
                    placeholder={_c.translate(
                      "admin.courses.duplicate.popup.form.name.placeholder"
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      duplicate.name = newValue;
                      this.setState({ duplicate });
                    }}
                  />
                </Cell>
              </Row>
            </Grid>
            <hr />
            <p>{_c.translate("admin.courses.duplicate.popup.text")}</p>
            <Grid type="full">
              <Row>
                <Cell sm={24} md={8} mdo={8}>
                  <label>
                    <input
                      className="admin-course-duplicate-checkbox"
                      type="checkbox"
                      checked={duplicate.info === true}
                      disabled={true}
                      onChange={() => {
                        this.handle__toggleDuplicate("info");
                      }}
                    />{" "}
                    {_c.translate("admin.courses.duplicate.popup.options.info")}
                  </label>
                  <label>
                    <input
                      className="admin-course-duplicate-checkbox"
                      type="checkbox"
                      checked={duplicate.categories === true}
                      onChange={() => {
                        this.handle__toggleDuplicate("categories");
                      }}
                    />{" "}
                    {_c.translate(
                      "admin.courses.duplicate.popup.options.categories"
                    )}
                  </label>
                  <label>
                    <input
                      className="admin-course-duplicate-checkbox"
                      type="checkbox"
                      checked={duplicate.lessons === true}
                      onChange={() => {
                        this.handle__toggleDuplicate("lessons");
                      }}
                    />{" "}
                    {_c.translate(
                      "admin.courses.duplicate.popup.options.lessons"
                    )}
                  </label>
                  <label>
                    <input
                      className="admin-course-duplicate-checkbox"
                      type="checkbox"
                      checked={duplicate.downloads === true}
                      onChange={() => {
                        this.handle__toggleDuplicate("downloads");
                      }}
                    />{" "}
                    {_c.translate(
                      "admin.courses.duplicate.popup.options.downloads"
                    )}
                  </label>
                </Cell>
              </Row>
            </Grid>
            <p style={{ marginTop: "10px" }}>
              {_c.translate("admin.courses.duplicate.popup.text2")}
            </p>
            <div className="popup-buttons">
              <button
                className="small primary hollow button"
                onClick={() => {
                  this.setState({ showDuplicatePopup: false });
                }}
              >
                <Icon icon="times" left /> {_c.translate("buttons.cancel")}
              </button>
              <button
                className="small primary button"
                onClick={this.handle__duplicate}
              >
                <Icon icon="check" left />{" "}
                {_c.translate("admin.courses.duplicate.button.label")}
              </button>
            </div>
          </MMPopup>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <MMPopup
            show={uploadImageStatus === "upload"}
            size="medium"
            nopadding
            handleClose={this.hidePopupImageUpload}
          >
            <Dropzone
              onDrop={(acceptedFiles) => {
                const picture = acceptedFiles[0];

                this.setState({ cropImage: picture });

                const reader = new FileReader();

                reader.addEventListener(
                  "load",
                  function () {
                    this.setState({ cropUrl: reader.result });
                    this.hidePopupImageUpload();
                    this.showPopupImageCrop();
                  }.bind(this),
                  false
                );

                if (picture) {
                  reader.readAsDataURL(picture);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div id="avatarDropZone">
                      <h3>
                        {_c.translate(
                          "admin.courses.course.image.uploadPopup.title"
                        )}
                      </h3>
                      <div id="avatarDropZoneText">
                        <p>
                          <Icon icon="upload" />
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: _c.translate(
                              "admin.courses.course.image.uploadPopup.text"
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </MMPopup>

          <MMPopup
            show={uploadImageStatus === "crop"}
            size="small"
            nopadding
            handleClose={() => {
              this.hidePopupImageCrop();
            }}
          >
            <div className="avatar-crop-heading">
              <h3>
                {_c.translate("admin.courses.course.image.cropPopup.title")}
              </h3>
            </div>

            <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

            <Grid>
              <Row>
                <Cell sm={24}>
                  <div className="avatar-crop-buttons">
                    <button
                      className="hollow primary button"
                      onClick={() => {
                        this.hidePopupImageCrop();
                      }}
                    >
                      <Icon icon="times" left />{" "}
                      {_c.translate(
                        "admin.courses.course.image.cropPopup.buttons.cancel"
                      )}
                    </button>
                    <button
                      className="primary button"
                      disabled={this.isCropSaveButtonDisabled()}
                      onClick={this.uploadImage}
                    >
                      <Icon icon="check" left />{" "}
                      {_c.translate(
                        "admin.courses.course.image.cropPopup.buttons.ok"
                      )}
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </MMPopup>
          <MMDialog
            show={uploadImageStatus === "delete"}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: _c.translate(
                    "admin.courses.course.image.deletePopup.text"
                  ),
                }}
              />
            }
            button_1={{
              title: _c.translate(
                "admin.courses.course.image.deletePopup.buttons.cancel"
              ),
              icon: <Icon icon="times" left />,
              type: "primary hollow",
            }}
            button_2={{
              title: _c.translate(
                "admin.courses.course.image.deletePopup.buttons.ok"
              ),
              icon: <Icon icon="trash" left />,
              type: "primary",
            }}
            onButton_1={() => {
              this.setState({ uploadImageStatus: null });
            }}
            onButton_2={this.handle__deleteImage}
          />
        </React.Fragment>
      );
    }
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  handle__deleteImage = () => {
    const { course } = this.state;
    course.image = null;
    this.setState({ course, uploadImageStatus: null });

    /*const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then(response => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });*/
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  uploadImage = () => {
    const { cropImage, crop, course, requestedId } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    const apiUrl = _c.apiUrl("admin.courseImageUpload", { id: requestedId });

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          course.image = path;
          this.setState({ course, changesWereMade: true });
          this.hidePopupImageCrop();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  view__showBoxDigistoreIds() {
    const { course, editMode } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>
                  {_c.translate("admin.courses.course.digistoreIds.title")}
                </h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDigistoreIds
                  tags={course.digistore_product_ids || []}
                  editMode={editMode}
                  onUpdate={(tags) => {
                    course.digistore_product_ids = tags;
                    this.setState({ course });
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>{_c.translate("admin.courses.course.elopageIds.title")}</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminElopageIds
                  tags={course.elopage_product_ids || []}
                  editMode={editMode}
                  onUpdate={(tags) => {
                    course.elopage_product_ids = tags;
                    this.setState({ course });
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  view__showBoxTags() {
    const { course, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.tags.title")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={course.tags}
                editMode={editMode}
                onUpdate={(tags) => {
                  course.tags = tags;
                  this.setState({ course });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxLessons() {
    const { course, newLesson, newCategory } = this.state;

    if (!course || !course.categories) {
      return;
    }

    return (
      <AdminCourseLessons
        course={course}
        newLesson={newLesson}
        newCategory={newCategory}
      />
    );
  }

  view__showBoxDownloads() {
    const { course, newDownload } = this.state;

    if (!course || !course.downloads) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminCourseDownloads
          course={course}
          downloads={course.downloads}
          newDownload={newDownload}
        />
      </React.Fragment>
    );
  }

  view__showLogs() {
    const { course } = this.state;

    if (!course || !course.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={course.logs} />
      </React.Fragment>
    );
  }

  handle__toggleDuplicate = (property) => {
    const { duplicate } = this.state;

    if (duplicate[property] === true) {
      duplicate[property] = false;
    } else {
      duplicate[property] = true;
    }

    if (property === "categories" && duplicate[property] === false) {
      duplicate.lessons = false;
    }

    if (property === "lessons" && duplicate[property] === true) {
      duplicate.categories = true;
    }

    this.setState({ duplicate });
  };

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { course } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("course", course.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { course } = this.state;
    course[property] = value;
    this.setState({ course, changesWereMade: true });
  }

  handle__delete = () => {
    const { course } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.course", { id: course.id });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              _c.translate("admin.courses.messages.deleted.title"),
              _c
                .translate("admin.courses.messages.deleted.text")
                .replace("{course}", course.name),
              "success"
            );
            this.props.history.push(`${_c.config("urls.admin.courses")}`);
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:course:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:course:delete");
      });
  };

  handle__cancel = () => {
    const { requestedId, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("admin.courses.course.cancel.message"))) {
        if (requestedId === "new") {
          window.location = _c.config("urls.admin.courses");
        } else {
          let { livecall, original } = this.state;
          livecall = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, livecall });
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = _c.config("urls.admin.courses");
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__duplicate = () => {
    const _c = this.props.context;
    const { course, duplicate } = this.state;

    if (!duplicate.name) {
      window.alert(_c.translate("admin.courses.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.courseDuplicate", { id: course.id });

      axios
        .post(
          apiUrl,
          {
            options: duplicate,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              window.location.href = _c
                .custom("urls.admin.course")
                .replace(":id", course.id);
              return;
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:duplicate:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:duplicate");
        });
    }, 200);
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, requestedId } = this.state;

    if (!course.name) {
      window.alert(_c.translate("admin.courses.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.course", { id: requestedId });

      axios
        .post(
          apiUrl,
          {
            course,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              if (requestedId === "new" && course && course.id) {
                window.location.href = _c
                  .custom("urls.admin.course")
                  .replace(":id", course.id);
                return;
              }

              const original = JSON.parse(JSON.stringify(course));
              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  editMode: false,
                  course,
                  original,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.courses.messages.saved.title"),
                    _c.translate("admin.courses.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:save");
        });
    }, 200);
  };

  init() {
    const { requestedId } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.course", { id: requestedId });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const {
              course,
              newLesson,
              newCategory,
              newDownload,
            } = apiResponseData;

            newLesson.id = "new";
            newCategory.id = "new";
            newDownload.id = "new";

            const duplicate = cloneDeep(this.state.originalDuplicate);
            duplicate.name = `${course.name} ${_c.translate(
              "admin.courses.duplicate.copy"
            )}`;

            const original = JSON.parse(JSON.stringify(course));

            course.start = new Date(course.start);

            let pageTitle;
            if (requestedId === "new") {
              pageTitle = "Neuer Kurs";
            } else {
              pageTitle = `${course.name}`;
            }

            this.setState({
              tabs: {
                course: {
                  name: _c.translate("admin.courses.tabs.course"),
                  icon: "graduation-cap",
                },
                lessons: {
                  name: _c.translate("admin.courses.tabs.lessons"),
                  icon: "youtube-play",
                },
                downloads: {
                  name: _c.translate("admin.courses.tabs.downloads"),
                  icon: "download",
                },
                logs: {
                  name: "Log",
                  icon: "clock-o",
                },
              },
            });

            _c.setPageTitle(pageTitle);

            const bc1 = {
              id: "admin_course",
              to: `${_c.config("urls.admin.courses")}/${course.id}`,
              name: pageTitle,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            this.setState({
              course,
              original,
              newLesson,
              newCategory,
              newDownload,
              breadcrumbs,
              duplicate,
            });

            if (requestedId === "new") {
              this.setState({ editMode: true });
            }

            this.setState({
              course,
              original,
              status: "LOADED",
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin.course:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin.course:init");
      });
  }
}

export default withContext(AdminCourse);
