import React, { useContext } from "react";
import { PtContext } from "../../../context/ptProvider";

function Footer(props) {
  const now = new Date();

  const _c = useContext(PtContext);

  return (
    <footer>
      v{_c.version} | &copy; {now.getFullYear()}{" "}
      <a
        href="https://www.membermate.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        membermate
      </a>{" "}
      |{" "}
      <a
        href="https://www.membermate.de/impressum"
        target="_blank"
        rel="noopener noreferrer"
      >
        Impressum
      </a>{" "}
      |{" "}
      <a
        href="https://www.membermate.de/datenschutz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Datenschutz
      </a>{" "}
      |{" "}
      <a
        href="https://www.membermate.de/agb"
        target="_blank"
        rel="noopener noreferrer"
      >
        AGB
      </a>
    </footer>
  );
}

export default Footer;
