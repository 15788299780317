import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Link } from "react-router-dom";

class HomeItemRoutines extends Component {
  state = {
    sortMode: this.props.sortMode,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sortMode !== prevProps.sortMode) {
      this.setState({ sortMode: this.props.sortMode });
    }
  }

  render() {
    const { sortMode } = this.state;
    const _c = this.props.context;

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;

    let v__output;
    let v__content = (
      <div className="home-item-content">
        <div className="home-item-text">
          {_c.translate("home.items.routines")}
        </div>
      </div>
    );

    if (sortMode === true) {
      v__output = (
        <div className="home-item home-item-routines">{v__content}</div>
      );
    } else {
      v__output = (
        <Link
          to={_c
            .custom("urls.routinesMonth")
            .replace(":year", year)
            .replace(":month", month)}
          className="home-item home-item-routines"
        >
          {v__content}
        </Link>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }
}

export default withContext(HomeItemRoutines);
