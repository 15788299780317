import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { PtContext } from "../../../../context/ptProvider";
import Box from "../../../layout/Box";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import Loading from "../../../_reusables/Loading";
import AffiliateHelper from "../AffiliateHelper";
import Footer from "../Footer";
import AffiliateTerms from "./AffiliateTerms";
import SignupForm from "./SignupForm";

function AffiliateSignup(props) {
  const _c = useContext(PtContext);

  const [affiliate, setAffiliate] = useState(null);
  const [checkboxAgb, setCheckboxAgb] = useState(false);
  const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(false);
  const [signupIsReady, setSignupIsReady] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(null);
  const [signupStatus, setSignupStatus] = useState(null);

  useEffect(() => {
    setAffiliate(AffiliateHelper.loadAffiliateRegisterCache());
    _c.hideLoadingScreen();
    _c.setPageTitle("membermate Weiterempfehlungsprogramm", true);
  }, []);

  useEffect(() => {
    checkForm();
  }, [formIsReadyToSubmit, checkboxAgb]);

  const onEdit = (property, value) => {
    affiliate[property] = value;
    setAffiliate(cloneDeep(affiliate));
    AffiliateHelper.saveAffiliateRegisterCache(affiliate);
  };

  const onToggleCheckbox = (property) => {
    affiliate[property] = !affiliate[property];
    setAffiliate(cloneDeep(affiliate));
  };

  const checkForm = () => {
    let isFormReady = false;
    if (formIsReadyToSubmit && checkboxAgb) isFormReady = true;
    setSignupIsReady(isFormReady);
    setSignupStatus(null);
  };

  const onFormCheck = (formIsReadyToSubmit) => {
    setFormIsReadyToSubmit(formIsReadyToSubmit);
  };

  const onCancel = () => {
    if (
      window.confirm(
        "Möchtest Du die Registrierung als membermate Affiliate wirklich abbrechen?"
      )
    )
      window.location.href = "https://www.membermate.de";
  };

  const buttonClicked = () => {
    setButtonStatus("CLICKED");
    checkForm();
  };

  const buttonReset = () => {
    setButtonStatus(null);
  };

  const onSignup = () => {
    buttonClicked();
    if (signupIsReady) {
      setButtonStatus("LOADING");
      setSignupStatus(null);
      AffiliateHelper.signupAffiliate(_c, affiliate, ({ status }) => {
        setSignupStatus(status);
        setButtonStatus(null);
      });
    }
  };

  const actions = {
    onEdit,
    onToggleCheckbox,
    onFormCheck,
    buttonClicked,
    buttonReset,
  };

  if (!affiliate)
    return (
      <div className="text-center" style={{ padding: "100px 0" }}>
        <Loading show={true} />
      </div>
    );

  return (
    <div className="affiliate-signup">
      <Grid>
        <Row margin="xy">
          <Cell sm={24} md={12} mdo={6}>
            <div className="affiliate-signup-logo text-center">
              <img
                src="https://static.membermate.net/images/logos/membermate.png"
                alt="membermate"
              />
            </div>
            <h1>Weiterempfehlungsprogramm</h1>
            <p className="lead">
              Empfehle membermate weiter und verdiene für jeden geworbenen
              Neukunden Provision. Fülle einfach das unten stehende Formular
              aus, wir senden Dir im Anschluss alle Informationen per E-Mail.
            </p>
            {signupStatus === "SUCCESS" ? (
              <div className="success callout">
                <p>
                  <strong>Herzlichen Dank!</strong>
                  <br />
                  Wir haben Deine Registrierung erhalten. Du erhältst in Kürze
                  eine E-Mail mit weiteren Informationen.
                </p>
              </div>
            ) : (
              <>
                <SignupForm
                  affiliate={affiliate}
                  buttonStatus={buttonStatus}
                  actions={actions}
                />
                <AffiliateTerms />
                <div
                  className={`${
                    !checkboxAgb && buttonStatus === "CLICKED" && `missing `
                  } affiliate-signup-checkboxes`}
                >
                  <Box>
                    <label>
                      <input
                        type="checkbox"
                        value={checkboxAgb}
                        onChange={() => setCheckboxAgb(!checkboxAgb)}
                      />{" "}
                      Ich habe die oben stehenden Konditionen, die{" "}
                      <a
                        href="https://www.membermate.de/agb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        allgemeinen membermate AGB
                      </a>{" "}
                      sowie die{" "}
                      <a
                        href="https://www.membermate.de/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Datenschutzerklärung
                      </a>{" "}
                      gelesen und akzeptiere sie.
                    </label>
                  </Box>
                </div>
                {!signupIsReady && buttonStatus === "CLICKED" && (
                  <div className="affiliate-signup-form-errors">
                    <div className="warning callout">
                      <Icon icon="exclamation-triangle" /> Bitte fülle alle
                      benötigten Felder korrekt aus.
                    </div>
                  </div>
                )}
                {signupStatus === "ALREADYEXISTS" && (
                  <div className="warning callout">
                    <Icon icon="info-circle" /> Es existiert bereits ein
                    Affiliate-Konto mit dieser E-Mail-Adresse. Wir haben Dir
                    gerade nochmal eine E-Mail mit Deinem persönlichen
                    Affiliate-Link und weiteren Informationen zugeschickt.
                  </div>
                )}
                {signupStatus === "ERROR" && (
                  <div className="alert callout">
                    <p>
                      <Icon icon="exclamation-triangle" />{" "}
                      <strong>Fehler beim Anlegen des Affiliate-Kontos</strong>
                    </p>
                    <p>
                      Leider ist beim Versuch Dein Affiliate-Konto anzulegen ein
                      Fehler aufgetreten. Bitte versuche es später erneut. Falls
                      es auch dann nicht funktionieren sollte, schreibe uns
                      bitte eine kurze E-mail an affiliates@membermate.de.
                    </p>
                    <div>Vielen Dank für Dein Verständnis!</div>
                  </div>
                )}
                <div className="text-center medium-text-right">
                  {buttonStatus === "LOADING" ? (
                    <>
                      <button
                        className="primary hollow button"
                        onClick={onCancel}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button
                        className="primary button"
                        disabled={true}
                        style={{ width: "215px" }}
                      >
                        <Icon icon="circle-o-notch fa-spin" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="primary hollow button"
                        onClick={onCancel}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button className="primary button" onClick={onSignup}>
                        <Icon icon="check" left /> Registrierung abschließen
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </Cell>
        </Row>
      </Grid>
      <Footer />
    </div>
  );
}

export default AffiliateSignup;
