import React from "react";
import withContext from "../../../context/contextHOC";
import DefaultLayout from "../../layout/DefaultLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Icon } from "../../_foundation/_buttons";
import axios from "axios";

class GetStarted extends React.Component {
  state = {
    view: null,
    identifier: this.props.match.params.identifier,
    domains: ["membermate.de", "membermate.at"],
    input: {
      domain: "membermate.de",
      subDomain: "",
      firstname: "",
      lastname: "",
      email: "",
    },
    domainCheck: null,
    delayTimer: null,
    errors: {
      email: false,
      subDomain: false,
    },
    buttonLoading: false,
  };

  componentDidMount() {
    const { identifier } = this.state;
    const _c = this.props.context;

    console.log("Found identifier:", identifier);

    if (!identifier) {
      console.log("redirect to membermate.de...");
      window.location.href = "https://membermate.de";
    } else {
      _c.initFinished();
      _c.setPageTitle("Einrichtung");
      this.init();
    }
  }

  render() {
    const { view } = this.state;

    let v__intro = (
      <>
        <div className="onboarding-logo">
          <img
            src="https://static.membermate.net/images/logos/membermate.png"
            alt="membermate"
          />
        </div>
        <h2>
          Herzlich willkommen in
          <br />
          der membermate-Familie!
        </h2>
        <p className="lead">
          Tausend Dank, dass Du uns Dein Vertrauen schenkst! Lass uns jetzt
          gemeinsam das Fundament für den zukünftigen Erfolg Deines neuen
          Mitgliederbereiches bauen!
        </p>
        <p className="lead">
          Wähle nachfolgend die Subdomain für Deinen Mitgliederbereich aus.
        </p>
        <p className="lead">
          Du kannst die Subdomain später jederzeit ändern und auf Wunsch auch
          kostenfrei Deine eigene Subdomain/Domain verknüpfen.
        </p>
      </>
    );

    let showIntro = false;

    let v__content;
    if (view === "done") {
      v__content = this.view__showDone();
    } else if (view === "domain") {
      showIntro = true;
      v__content = this.view__showSelectDomain();
    } else if (view === "notfound") {
      v__content = this.view__showNotFound();
    } else {
      v__content = this.view__showOnboarding();
    }

    return (
      <DefaultLayout page="onboarding">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              {showIntro ? v__intro : null}
              {v__content}
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }

  view__showNotFound() {
    return (
      <div className="warning callout">
        <p>
          <strong>Es ist ein Fehler aufgetreten</strong>
        </p>
        <p>
          Es konnte kein Eintrag unter der angegebenen ID gefunden werden.
          Möglicherweise ist der Bestätigungslink abgelaufen oder Du hast Deinen
          Mitgliederbereich bereits eingerichtet.
        </p>
        <p>
          Du kannst jederzeit eine neue kostenfreie Testphase auf{" "}
          <a href="https://membermate.de">membermate.de</a> starten.
        </p>
        <p>
          Bei Fragen wende Dich gern an unseren Support unter{" "}
          <a href="mailto:support@membermate.de">support@membermate.de</a>.
        </p>
      </div>
    );
  }

  view__showSelectDomain() {
    const { input, errors, domains } = this.state;

    let classesSubDomain = "";
    if (errors && errors.subDomain === true) {
      classesSubDomain = `has-error ${classesSubDomain}`;
    }

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={11}>
                <input
                  type="text"
                  value={input.subDomain}
                  className={classesSubDomain}
                  onChange={(e) => {
                    this.handle__edit("subDomain", e.target.value);
                  }}
                />
              </Cell>
              <Cell sm={21} md={11}>
                <select
                  value={input.domain}
                  onChange={(e) => {
                    this.handle__edit("domain", e.target.value);
                  }}
                >
                  {domains.map((membermateDomain, index) => {
                    return (
                      <option key={index} value={membermateDomain}>
                        .{membermateDomain}
                      </option>
                    );
                  })}
                </select>
              </Cell>
              <Cell sm={3} md={2} className="text-right">
                {this.view__showValid("domain")}
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                {this.view__showDomain()}
              </Cell>
            </Row>
          </Grid>
        </Box>
        {this.view__showButton()}
      </React.Fragment>
    );
  }

  view__showDomain() {
    const { input, domainCheck } = this.state;

    let v__content = (
      <div>
        <em>Domain nicht verfügbar.</em>
      </div>
    );

    if (domainCheck === null) {
      v__content = (
        <div>
          <em>
            <Icon icon="circle-o-notch fa-spin" left /> Domain wird überprüft
            ...
          </em>
        </div>
      );
    }

    if (this.isValid("domain")) {
      v__content = (
        <div>
          <div>
            <strong>
              https://{input.subDomain}.{input.domain}
            </strong>
          </div>
          <div
            className="small secondary callout text-left"
            style={{ marginTop: "20px", marginBottom: "0" }}
          >
            <div>
              <strong>Wichtiger Hinweis:</strong> Bitte beachte, dass wir uns
              das Recht vorbehalten, die von Dir ausgewählte Domain auch im
              Nachhinein abzulehnen, wenn sie z.B. gegen geltendes Recht
              verstößt, anzüglich, beleidigend oder rassistisch klingt oder zu
              generisch sein sollte (z.B. test oder abcd). In so einem Fall
              setzen wir uns natürlich mit Dir in Verbindung.
            </div>
            <div style={{ paddingTop: "10px" }}>
              Für einen hohen Wiedererkennungswert solltest Du Deinen Namen bzw.
              den Namen Deines Produktes / Deiner Community wählen.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="text-center" style={{ marginTop: "20px" }}>
        <h3>Die Adresse Deines Mitgliederbereiches lautet:</h3>
        {v__content}
      </div>
    );
  }

  view__showButton() {
    const { buttonLoading } = this.state;

    let buttonDisabled = true;
    if (this.isValid("domain")) {
      buttonDisabled = false;
    }

    if (buttonLoading) {
      buttonDisabled = true;
    }

    return (
      <div className="text-right">
        <button
          onClick={() => {
            if (buttonDisabled === false) {
              this.save();
            }
          }}
          className="primary button"
          style={{ width: "240px" }}
          disabled={buttonDisabled}
        >
          {buttonLoading ? (
            <Icon icon="circle-o-notch fa-spin" />
          ) : (
            <>
              <Icon icon="check" left /> Mitgliederbereich einrichten
            </>
          )}
        </button>
      </div>
    );
  }

  view__showValid = (property) => {
    let output;

    const v__valid = (
      <span className="onboarding-valid">
        <Icon icon="check" />
      </span>
    );
    const v__invalid = (
      <span className="onboarding-invalid">
        <Icon icon="times" />
      </span>
    );
    const v__loading = (
      <span className="onboarding-loading">
        <Icon icon="circle-o-notch fa-spin" />
      </span>
    );

    if (property === "email") {
      if (this.isValid(property)) {
        output = v__valid;
      }
    } else if (property === "domain") {
      if (this.isValid(property) === true) {
        output = v__valid;
      } else if (this.isValid(property) === false) {
        output = v__invalid;
      } else {
        output = v__loading;
      }
    }

    return output;
  };

  isValid = (property) => {
    const { domainCheck } = this.state;

    let isValid = false;

    if (property === "domain") {
      if (domainCheck === true) {
        isValid = true;
      } else if (domainCheck === false) {
        isValid = false;
      } else {
        isValid = null;
      }
    }

    return isValid;
  };

  view__showDone() {
    const { input } = this.state;

    return (
      <>
        <div className="onboarding-logo">
          <img
            src="https://static.membermate.net/images/logos/membermate.png"
            alt="membermate"
          />
        </div>
        <div className="success callout">
          <h2>Gleich ist es soweit!</h2>
          <p className="lead">
            Herzlichen Dank, Deine Angaben wurden gespeichert!
          </p>
          <p className="lead">
            Dein Mitgliederbereich wird jetzt gerade im Hintergrund
            eingerichtet. Sobald die Einrichtung abgeschlossen ist, erhältst Du
            eine E-Mail mit einem Link, über den Du Dein Passwort zu Deinem
            eigenen Mitgliederbereich festlegen kannst. Die Einrichtung dauert
            in der Regel nur ein paar Minuten.
          </p>
          <p>
            Dein Benutzerkonto ist von Haus aus mit vollen
            Administrationsrechten ausgestattet, sodass Du sofort loslegen
            kannst! Solltest Du Unterstützung benötigen, findest Du im
            Administrationsbereich den Menüpunkt <em>Support</em>. Dort findest
            Du Anleitungen, Tutorials und alle Kontaktmöglichkeiten zum
            membermate Team!
          </p>
          <p>
            Wir wünschen Dir sehr viel Spaß und riesigen Erfolg mit Deinem neuen
            Mitgliederbereich! Falls Du Fragen haben solltest, zögere nicht,
            Dich mit uns in Verbindung zu setzen!
          </p>
          <p>
            Dein membermate Team{" "}
            <span role="img" aria-label="Rocket Emoji">
              🚀
            </span>
          </p>
          <p className="text-center">
            Sobald Dein Mitgliederbereich vollständig eingerichtet ist,
            <br className="hide-for-small-only" />
            kannst Du ihn über folgenden Button aufrufen:
          </p>
          <p className="text-center" style={{ marginBottom: "0" }}>
            <a
              href={`https://${input.subDomain}.${input.domain}`}
              className="primary button"
            >
              Zu Deinem Mitgliederbereich
            </a>
          </p>
        </div>
      </>
    );
  }

  view__showOnboarding() {
    return <div></div>;
  }

  handle__edit = (property, value) => {
    const { input } = this.state;

    if (property === "subDomain") {
      let { delayTimer } = this.state;
      input[property] = value;
      clearTimeout(delayTimer);
      this.setState({ domainCheck: null, input }, () => {
        delayTimer = setTimeout(this.checkDomain, 1000);
        this.setState({ delayTimer });
      });
    } else if (property === "domain") {
      input[property] = value;
      this.setState({ domainCheck: null, input }, () => {
        this.checkDomain();
      });
    }
  };

  checkDomain = () => {
    const { identifier, input } = this.state;

    if (!input.subDomain) {
      this.setState({ domainCheck: false });
      return null;
    }

    console.log("Checking...");
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("domainCheckv2", {
      identifier,
      domain: input.domain,
      subDomain: input.subDomain,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { subDomain, checkResult } = response.data.data;
            const { errors } = this.state;
            if (checkResult === true) {
              errors.subDomain = false;
            } else {
              errors.subDomain = true;
            }

            input.subDomain = subDomain;

            this.setState({ input, domainCheck: checkResult, errors });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "checkdomain:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "checkdomain");
        }
      });
  };

  save = () => {
    const { identifier, input, domainCheck } = this.state;

    if (!input.subDomain || !domainCheck) {
      this.setState({ domainCheck: false });
      return null;
    }

    const _c = this.props.context;

    this.setState({ buttonLoading: true });

    let apiUrl = _c.apiUrl("onboardingSavev2", {
      identifier,
    });

    axios
      .post(
        apiUrl,
        {
          identifier,
          subDomain: input.subDomain,
          domain: input.domain,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            this.setState({ view: "done" });
          } else if (status === "NOTFOUND") {
            this.setState({ view: "notfound" });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "onboarding:save:response"
          );
        }

        this.setState({ buttonLoading: false });
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "onboarding:save");
        }
      });
  };

  init = () => {
    const { identifier, input } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("onboardingv2", {
      identifier: identifier,
      domain: input.domain,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { subDomain, checkResult, email, firstname, lastname } =
              response.data.data;
            input.firstname = firstname;
            input.lastname = lastname;
            input.email = email;
            input.subDomain = subDomain;

            this.setState({
              input,
              domainCheck: checkResult,
              view: "domain",
            });
          } else if (status === "NOTFOUND") {
            this.setState({ view: "notfound" });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "welcome:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "welcome");
        }
      });
  };
}

export default withContext(GetStarted);
