import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Icon } from "../../../_foundation/_buttons";
import axios from "axios";

class FinishButton extends Component {
  state = {
    _updatableProps: ["show", "lesson"],
    show: this.props.show,
    lesson: this.props.lesson,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { show, lesson } = this.state;
    const _c = this.props.context;

    if (lesson.lessonStatus.finished) {
      return (
        <button
          className="primary hollow button course-lesson-finished"
          onClick={() => {
            if (
              window.confirm(
                _c.translate("courses.lesson.markedAsFinished.question")
              )
            ) {
              this.handle__unfinishLesson();
            }
          }}
        >
          <Icon icon="check" left />{" "}
          {_c.translate("courses.lesson.markedAsFinished.button")}
        </button>
      );
    } else {
      return (
        <button
          className="primary button"
          style={{
            marginRight: "10px",
            display:
              show === true && !lesson.lessonStatus.finished
                ? "inline-block"
                : "none",
          }}
          onClick={() => {
            if (
              window.confirm(
                _c.translate("courses.lesson.markAsFinished.question")
              )
            ) {
              this.handle__finishLesson();
            }
          }}
        >
          {_c.translate("courses.lesson.markAsFinished.button")}{" "}
          <Icon icon="angle-right" right />
        </button>
      );
    }
  }

  handle__unfinishLesson() {
    const { lesson } = this.state;
    const { lessonStatus } = lesson;
    const _c = this.props.context;

    lessonStatus.finished = null;

    let url = _c.apiUrl("courses.lessonStatus", {
      lessonId: lesson.id,
    });

    axios
      .patch(
        url,
        {
          lessonStatus: lessonStatus,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            window.location.reload();
          }
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }

  handle__finishLesson() {
    const { lesson } = this.state;
    const { lessonStatus } = lesson;
    const _c = this.props.context;

    lessonStatus.finished = "NOW";

    let url = _c.apiUrl("courses.lessonStatus", {
      lessonId: lesson.id,
    });

    axios
      .patch(
        url,
        {
          lessonStatus: lessonStatus,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { nextLesson, course } = response.data.data;
            let nextLessonUrl = _c
              .custom("urls.courseLesson")
              .replace(":courseSlug", course.slug)
              .replace(":lessonSlug", nextLesson.slug);
            window.location.href = nextLessonUrl;
          }
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }
}

export default withContext(FinishButton);
