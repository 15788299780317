import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { isValid } from "date-fns";
import withContext from "../../context/contextHOC";

registerLocale("de", de);

class AdminDataField extends Component {
  state = {
    value: this.props.value, // The value to be displayed in View Mode
    editValue: this.props.editValue, // The value to be displayed in Edit Mode
    label: this.props.label, // The Label underneath the Value
    selectValues: this.props.selectValues, // If editType === "select" -> these are the options vor the Select
    editable: this.props.editable, // Is this Field editable?
    edit: this.props.edit, // EditMode: true / false
    editType: this.props.editType || "text", // select, date, textarea, singleCheckbox, number
    size: this.props.size || "small", // small, medium, large (for textarea only)
    min: this.props.min || null, // for type=number
    max: this.props.max || null, // for type=number
    placeholder: this.props.placeholder || "",
    isClearable: this.props.isClearable || false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value ||
      this.props.editValue !== prevProps.editValue ||
      this.props.edit !== prevProps.edit ||
      this.props.label !== prevProps.label ||
      this.props.editType !== prevProps.editType ||
      this.props.selectValues !== prevProps.selectValues ||
      this.props.size !== prevProps.size ||
      this.props.min !== prevProps.min ||
      this.props.max !== prevProps.max ||
      this.props.placeholder !== prevProps.placeholder
    ) {
      this.setState({
        value: this.props.value,
        editValue: this.props.editValue,
        edit: this.props.edit,
        editType: this.props.editType,
        selectValues: this.props.selectValues,
        label: this.props.label,
        size: this.props.size,
        min: this.props.min,
        max: this.props.max,
        placeholder: this.props.placeholder,
      });
    }
  }

  render() {
    const { value, editType, label } = this.state;

    if (editType === "singleCheckbox") {
      return <React.Fragment>{this.view__showEditForm()}</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <div>
            <div className="admin-data-value" style={this.view("view")}>
              {value || <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
            </div>
            <div className="admin-data-edit-value" style={this.view("edit")}>
              {this.view__showEditForm()}
            </div>
            <div className="admin-data-label">{label}</div>
          </div>
        </React.Fragment>
      );
    }
  }

  view__showEditForm() {
    const {
      editType,
      selectValues,
      placeholder,
      label,
      isClearable,
      min,
      max,
    } = this.state;
    const _c = this.props.context;

    let { editValue } = this.state;

    if (editType === "select") {
      return (
        <select
          value={editValue}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
        >
          {Object.keys(selectValues).map((key, index) => {
            let optionValue = key;
            if (parseInt(key) === parseInt(index)) {
              optionValue = selectValues[key];
            }
            return (
              <option key={index} value={optionValue}>
                {selectValues[key]}
              </option>
            );
          })}
        </select>
      );
    } else if (editType === "date") {
      let editDate;
      if (editValue) {
        editDate = new Date(editValue);
        if (!isValid(editDate)) {
          editDate = null;
        }
      } else {
        editDate = null;
      }

      return (
        <DatePicker
          selected={editDate || null}
          onChange={(value) => {
            this.handle__editDate(value);
          }}
          dateFormat="dd.MM.yyyy"
          locale="de"
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
          isClearable={isClearable}
        />
      );
    } else if (editType === "datetime") {
      let editDate;
      if (editValue) {
        editDate = new Date(editValue);
        if (!isValid(editDate)) {
          editDate = null;
        }
      } else {
        editDate = null;
      }

      return (
        <DatePicker
          selected={editDate || null}
          onChange={(value) => {
            this.handle__editDate(value);
          }}
          dateFormat="dd.MM.yyyy - HH:mm"
          locale="de"
          showTimeSelect
          timeIntervals={5}
          timeFormat="HH:mm"
          timeCaption={_c.translate("general.time")}
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
          isClearable={isClearable}
        />
      );
    } else if (editType === "textarea") {
      const { size } = this.state;

      let rows = 4;
      if (size === "medium") {
        rows = 8;
      } else if (size === "large") {
        rows = 12;
      }

      return (
        <textarea
          rows={rows}
          value={editValue || ""}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
          placeholder={placeholder}
        />
      );
    } else if (editType === "singleCheckbox") {
      return (
        <label>
          <input
            type="checkbox"
            checked={editValue}
            onChange={(e) => {
              if (editValue === true) {
                editValue = false;
              } else {
                editValue = true;
              }
              this.props.onUpdate(editValue);
            }}
          />{" "}
          {label}
        </label>
      );
    } else if (editType === "number") {
      return (
        <input
          type="number"
          value={editValue || ""}
          min={min}
          max={max}
          onChange={(e) => {
            if (!max || parseInt(e.target.value) <= parseInt(max)) {
              this.handle__edit(e.target.value);
            }
          }}
          placeholder={placeholder}
        />
      );
    } else {
      return (
        <input
          type="text"
          value={editValue || ""}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
          placeholder={placeholder}
        />
      );
    }
  }

  view(mode) {
    const { edit, editable } = this.state;

    if (
      (editable === true && mode === "edit" && edit === true) ||
      (mode === "view" && edit === false) ||
      (editable === false && mode === "view" && edit === true)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  handle__edit(value) {
    this.setState({ editValue: value, value });
    this.props.onUpdate(value);
  }

  handle__editDate(value) {
    this.setState({ editValue: value });
    this.props.onUpdate(value);
  }
}

export default withContext(AdminDataField);
