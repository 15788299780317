import React from "react";
import AffiliateHelper from "../AffiliateHelper";

function AffiliateTerms(props) {
  return (
    <div className="affiliate-terms">
      <h2>Konditionen</h2>

      <p>
        Für das Weiterempfehlungsprogramm werden folgenden Konditionen
        vereinbart:
      </p>
      <ol>
        <li>
          <p>
            Du erhältst eine Provision pro vermitteltem zahlungspflichtigen
            Kunden (nachfolgend Geworbener) in Höhe von{" "}
            <strong className="affiliate-term-important">
              {AffiliateHelper.getTerm("commission")}&nbsp;%
            </strong>{" "}
            auf den Nettopaketpreis, den der Geworbene zahlt, und wird Dir für
            dieselbe Periode gutgeschrieben, für die der Geworbene selbst zahlt
            (monatlich oder jährlich).
          </p>
          <p>
            <small>
              Beispiel 1: Der Geworbene zahlt netto 69 Euro pro Monat (Paket
              Basic). Deine monatliche Provision für diesen Geworbenen beträgt
              13,80 Euro.
              <br />
              Beispiel 2: Der Geworbene zahlt netto 708 Euro pro Jahr (Paket
              Basic). Deine jährliche Provision für diesen Geworbenen beträgt
              141,60 Euro.
            </small>
          </p>
        </li>
        <li>
          Dein Anspruch auf die Provision bleibt solange bestehen, wie der
          Geworbene Kunde bei membermate bleibt, und verfällt, sobald der
          bezahlte Zeitraum des Geworbenen endet.
        </li>
        <li>
          Die Höhe Deiner Provision ist direkt an die Höhe des vom Geworbenen
          bezahlten Paketpreises gebunden. Sollte der Geworbene zwischenzeitlich
          das Paket wechseln (unabhängig, ob in ein günstigeres oder teureres),
          passt sich Deine Provision entsprechend an.
        </li>
        <li>
          Ein Anspruch auf eine Provision besteht nur dann, wenn der Geworbene
          die membermate-Website über Deinen individuellen
          Affiliate-/Referer-Link aufgerufen hat, der Beginn der kostenfreien
          Testphase im selben Browser erfolgt und der Geworbene die
          LocalStorage-Funktion seines Browsers nicht deaktiviert oder
          eingeschränkt hat. Der Provisionsanspruch entsteht erst in dem Moment,
          in dem der Geworbene ein kostenpflichtiges Paket bucht und noch nicht
          mit Beginn der kostenfreien Testphase.
        </li>
        <li>
          Der Provisionanspruch besteht für alle Geworbenen, die innerhalb von{" "}
          <strong className="affiliate-term-important">
            {AffiliateHelper.getTerm("timelimit")}&nbsp;Tagen
          </strong>{" "}
          nach erstmaligem Aufruf der membermate-Website über Deinen
          individuellen Affiliate-Link ein kostenpflichtiges membermate-Paket
          buchen. Wenn der Geworbene vor dem Registrieren für die kostenfreie
          Testphase verschiedene Affiliate-Links aufgerufen hat, wird dieser
          Geworbene dem jeweils zuletzt aufgerufenen Affiliate-Link zugeordnet.
        </li>
        <li>
          Die Auszahlung der Provision erfolgt zum aktuellen Zeitpunkt{" "}
          <strong className="affiliate-term-important">
            {AffiliateHelper.getTerm("billing_period")}
          </strong>{" "}
          jeweils{" "}
          <strong className="affiliate-term-important">
            {AffiliateHelper.getTerm("billing_period_dates")}
          </strong>
          . Sollte zum anstehenden Auszahlungszeitpunkt der Betrag Deiner
          Provisionsansprüche{" "}
          <strong className="affiliate-term-important">
            {AffiliateHelper.getTerm("billing_min")}&nbsp;Euro
          </strong>{" "}
          unterschreiten, erfolgt die Auszahlung erst zum darauffolgenden
          Auszahlungszeitpunkt, sofern der o.g. Mindestbetrag dann erreicht
          wird. Es ist geplant, diese Periode im Laufe des Jahres 2022 auf eine
          monatliche Auszahlung zu verkürzen. Sobald dies möglich ist, erhältst
          Du eine entsprechende Mitteilung an Deine o.g. E-Mail-Adresse.
        </li>
        <li>
          Deinen aktuellen Provisionsstand, die Höhe und den Zeitpunkt Deiner
          nächsten Auszahlung sowie alle weiteren Informationen können zum
          aktuellen Zeitpunkt über unseren Affiliates Service per E-Mail an{" "}
          <strong className="affiliate-term-important">
            affiliates@membermate.de
          </strong>{" "}
          abgefragt werden. Es ist geplant, im Laufe des Jahres 2022 eine eigene
          Affiliate-Plattform einzurichten. Sobald dies möglich ist, erhältst Du
          eine entsprechende Mitteilung an Deine o.g. E-Mail-Adresse.
        </li>
        <li>
          Der Betreiber des Weiterempfehlungsprogramms hält sich das Recht vor,
          registrierte Affiliates jederzeit ohne Angabe von Gründen fristlos vom
          Weiterempfehlungsprogramm auszuschließen. Eventuell bestehendes
          Guthaben wird in diesem unwahrscheinlichen Fall unverzüglich auf das
          angegebene Konto ausgezahlt.
        </li>
      </ol>
    </div>
  );
}

export default AffiliateTerms;
