import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import HomeGrid1 from "../grids/HomeGrid1";

class HomeLayout1 extends Component {
  state = {
    _updatableProps: ["homeInfo"],
    homeInfo: this.props.homeInfo,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    const user = _c.getUser();

    if (!homeInfo || !user || !user.firstname) {
      return _c.loading();
    }

    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="y">
            <Cell sm={24} md={24} className="text-center">
              <div className="home-hello">
                {_c.translate("home.hello").replace("{name}", user.firstname)}
              </div>
            </Cell>
          </Row>
        </Grid>
        {this.view__showGrid()}
        {this.view__showCourses()}
      </React.Fragment>
    );
  }

  view__showGrid() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    let v__grid;

    let layout = _c.setting("home_layout") || "layout1";

    if (layout === "layout1") {
      v__grid = <HomeGrid1 homeInfo={homeInfo} onUpdate={this.onUpdate} />;
    }

    return (
      <Grid type="full">
        <Row margin="y">
          <Cell sm={24} md={24}>
            {v__grid}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showCourses() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    if (!homeInfo.purchasedCourses) {
      return;
    }

    let v__courses;

    const homeAllowCourses = _c.setting("home_allow_courses");
    if (!homeAllowCourses) {
      return null;
    }

    const { purchasedCourses } = homeInfo;

    let md = 8;

    v__courses = (
      <Grid type="full">
        <Row margin="xy">
          {purchasedCourses.map((course, index) => {
            let courseUrl = _c
              .custom("urls.course")
              .replace(":courseSlug", course.slug);

            let v__image = (
              <div className="course-no-image">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate("courses.overview.noimage"),
                  }}
                />
              </div>
            );

            if (course.image) {
              v__image = <img src={course.image} alt={course.name} />;
            }

            v__image = <a href={courseUrl}>{v__image}</a>;

            return (
              <Cell sm={24} md={md} key={index}>
                <div className="home-courses-course">
                  <div className="course-image">{v__image}</div>
                  <div className="course-content">
                    <h3>
                      <a href={courseUrl}>{course.name}</a>
                    </h3>
                  </div>
                </div>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );

    return (
      <div className="home-courses">
        <h2>{_c.translate("home.sections.courses.title")}</h2>
        <Grid type="full">
          <Row>
            <Cell sm={24}>{v__courses}</Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  /*view__showVisionboard() {
  
    const { homeInfo } = this.state;
    const _c = this.props.context;

    if (!homeInfo.visionboardImages) {
      return;
    }

    let v__visionboard;

    const homeAllowVisionboard = _c.setting("home_allow_visionboard");
    if (!homeAllowVisionboard) {
      return null;
    }

    const { visionboardImages } = homeInfo;

    let md = 8;
    let classes = "big";
    if (
      visionboardImages.length === 4 ||
      Math.round(visionboardImages.length / 4) === visionboardImages.length / 4
    ) {
      md = 6;
      classes = "small";
    }

    v__visionboard = (
      <Grid type="full">
        <Row margin="xy">
          {visionboardImages.map((image, index) => {
            return (
              <Cell sm={24} md={md} key={index}>
                <div
                  className={`${classes} visionboard-image`}
                  style={{ backgroundImage: `url(${image.url})` }}
                  onClick={() => {
                    window.location.href = _c.config("urls.visionboard");
                  }}
                ></div>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );

    return (
      <div className="home-visionboard">
        <Grid type="full">
          <Row>
            <Cell sm={24}>{v__visionboard}</Cell>
          </Row>
        </Grid>
      </div>
    );
  }*/

  onUpdate = ({ homeInfo }) => {
    if (this.props.onUpdate) {
      this.props.onUpdate({ homeInfo });
    }
  };
}

export default withContext(HomeLayout1);
