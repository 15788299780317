import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Link } from "react-router-dom";
//import { Icon } from "../_foundation/_buttons";
import ReplyForm from "./parts/ReplyForm";
import Box from "../../layout/Box";

class NewThread extends Component {
  state = {
    boardSlug: this.props.match.params.boardSlug,
    board: null,
    thread: null,
    post: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
    editorState: undefined,
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("community_main_layout");
    if (!layout) {
      layout = "layout1";
    }

    return (
      <div className="community-board">
        <div className={`community-${layout}`}>
          <CommunityLayout
            view="areas"
            layout={layout}
            breadcrumbs={breadcrumbs}
          >
            {this.view__showContent()}
          </CommunityLayout>
        </div>
      </div>
    );
  }

  view__showContent() {
    const { status } = this.state;
    const _c = this.props.context;

    let v__content;

    if (status === "LOADED") {
      v__content = (
        <React.Fragment>
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} className="text-center medium-text-left">
                {this.view__showTitle()}
              </Cell>
            </Row>
          </Grid>
          {this.view__showForm()}
        </React.Fragment>
      );
    } else {
      v__content = _c.loading();
    }

    return v__content;
  }

  view__showTitle() {
    const { board } = this.state;
    const _c = this.props.context;

    return (
      <div className="community-header">
        <h1>{_c.translate("community.board.newThread")}</h1>
        <div className="thread-author">
          {_c.translate("community.newThread.name.inBoard")}{" "}
          <Link
            to={_c
              .custom("urls.communityBoard")
              .replace(":boardSlug", board.slug)}
          >
            {board.name}
          </Link>
        </div>
      </div>
    );
  }

  view__showForm() {
    const { thread, editorState } = this.state;
    const _c = this.props.context;

    return (
      <Grid type="full">
        <Row margin="y">
          <Cell sm={24} md={24}>
            <Box>
              <h3>
                {_c.translate("community.newThread.form.threadName.label")}
              </h3>
              <input
                type="text"
                value={thread.name}
                onChange={this.handle__changeThreadName}
                placeholder={_c.translate(
                  "community.newThread.form.threadName.placeholder"
                )}
              />
            </Box>
          </Cell>
        </Row>
        <Row margin="y">
          <Cell sm={24} md={24}>
            <Box>
              <h3>{_c.translate("community.newThread.form.post.label")}</h3>
              <ReplyForm
                thread={thread}
                type="newThread"
                replyForm={""}
                editorState={editorState}
                onUpdate={(thread) => {
                  this.setState({ thread });
                  this.props.onUpdate(thread, editorState);
                }}
                onChange={this.onChangePost}
                onSaveThread={this.onSaveThread}
              />
            </Box>
          </Cell>
        </Row>
      </Grid>
    );
  }

  handle__changeThreadName = (event) => {
    const { thread } = this.state;
    const newValue = event.target.value;
    thread.name = newValue;

    this.setState({ thread });
  };

  onChangePost = (post) => {
    this.setState({ post });
  };

  onSaveThread = () => {
    const { board, thread, post } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("community.newThread", {
      boardSlug: board.slug,
    });

    axios
      .post(
        apiUrl,
        {
          thread: thread,
          post: post,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const { board, thread } = response.data.data;

            const url = _c
              .custom("urls.communityThread")
              .replace(":boardSlug", board.slug)
              .replace(":threadSlug", thread.slug);
            this.props.history.push(url);
          }
        } catch (error) {
          console.log(error);
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:newthread:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:newthread");
      });
  };

  init() {
    const { boardSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("community.board", {
      boardSlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { board } = communityData;

            const thread = _c.createEmptyThread(board);
            const post = _c.createEmptyPost(thread);

            const newBreadcrumb = {
              to: _c
                .custom("urls.communityBoard")
                .replace(":boardSlug", board.slug),
              id: "communityBoard",
              name: board.name,
            };

            const newBreadcrumb2 = {
              to: "/",
              id: "communityNewThread",
              name: _c.translate("community.board.newThread"),
            };

            let { breadcrumbs } = this.state;
            breadcrumbs.push(newBreadcrumb, newBreadcrumb2);

            this.setState(
              {
                post,
                thread,
                board,
                status: "LOADED",
                breadcrumbs,
              },
              () => {
                _c.initFinished();
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:newthread:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:newthread:init");
      });
  }
}

export default withContext(NewThread);
