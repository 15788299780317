import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";
import DefaultLayout from "../layout/DefaultLayout";
import HomeLayout1 from "./layouts/HomeLayout1";

class Home extends Component {
  state = {
    access: this.props.access,
    homeInfo: {},
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("home_layout") || "layout1";

    let v__homeLayout;

    if (layout === "layout1") {
      v__homeLayout = (
        <HomeLayout1 homeInfo={homeInfo} onUpdate={this.onUpdate} />
      );
    }

    return (
      <React.Fragment>
        <DefaultLayout page="home" help="home" breadcrumbs={["home"]}>
          {v__homeLayout}
        </DefaultLayout>
      </React.Fragment>
    );
  }

  onUpdate = ({ homeInfo }) => {
    this.setState({ homeInfo });
  };

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("home.home");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === 200) {
            _c.handleApiResponse(response.data, true);
          }

          const { homeInfo } = response.data.data;
          _c.setPageTitle(_c.translate("pageTitles.home"));
          this.setState({ homeInfo });
        } catch (error) {
          console.log(error);
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "home:init:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "home:init");
        }
      });
  }
}

export default withContext(Home);
