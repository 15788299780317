import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";
import AdminLog from "../AdminLog";

import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdminTags from "../AdminTags";
import AdminAccessSelect from "../AdminAccessSelect";

class AdminLiveCall extends Component {
  state = {
    loadingError: false,
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_livecalls"],

    tabs: {
      info: {
        name: "Informationen",
        icon: "user",
      },
      logs: {
        name: "Log",
        icon: "clock-o",
      },
    },
    selectedTab: "info",
    livecall: undefined,
    original: undefined,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    uploadImageStatus: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 16 / 10,
    },
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("live");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    return (
      <AdminLayout nav="livecalls" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={_c.config("urls.admin.livecalls")} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, livecall } = this.state;
    const _c = this.props.context;

    if (!livecall) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.livecalls.delete.confirm", {
                  livecallTitle: livecall.title,
                }),
              }}
            />
          }
          button_1={{
            title: _c.translate("admin.livecalls.delete.buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("admin.livecalls.delete.buttons.yes"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { livecall, original, requestedId } = this.state;
    const _c = this.props.context;

    if (isUndefined(livecall)) {
      return null;
    } else if (livecall === null) {
      return null;
    } else if (livecall && requestedId === "new") {
      return <h1>{_c.translate("admin.livecalls.addLivecall")}</h1>;
    } else if (livecall && livecall.id && livecall.title) {
      let v__status;
      if (livecall.status) {
        if (livecall.status === "ONAIR") {
          v__status = (
            <span className="admin-live-onair">
              {_c.translate("live.onair")}
            </span>
          );
        }
      }

      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">Live Call</div>
          <h1>
            {original.title} {v__status}
          </h1>
        </React.Fragment>
      );
    } else {
      return <h1>{_c.trsnslate("errors.unknownError")}</h1>;
    }
  }

  view__showPageActions() {
    const { editMode, livecall } = this.state;
    const _c = this.props.context;

    if (editMode === true || !livecall) {
      return null;
    }

    let v__stopButton;
    if (livecall.status) {
      if (livecall.status === "ONAIR") {
        v__stopButton = (
          <button
            className="small alert button"
            style={{ marginRight: "60px" }}
            onClick={this.handle__stop}
          >
            <Icon icon="stop-circle" left /> {_c.translate("live.stop.button")}
          </button>
        );
      }
    }

    return (
      <div className="admin-page-actions text-center medium-text-right">
        {v__stopButton}
        <button
          className="small primary hollow button"
          onClick={() => {
            this.setState({ showDialogDelete: true });
          }}
        >
          <Icon icon="trash" left />{" "}
          {_c.translate("admin.livecalls.delete.buttonLabel")}
        </button>
        <button
          className="small primary button"
          onClick={this.handle__toggleEditMode}
        >
          <Icon icon="cog" left />{" "}
          {_c.translate("admin.livecalls.edit.buttonLabel")}
        </button>
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip="Abbrechen und zurück zur Übersicht"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button
            className="small primary button"
            data-tip="Änderungen speichern"
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;
    const _c = this.props.context;

    if (editLock === true) {
      const message = _c
        .translate("editLock.message")
        .replace("{editLockUser}", editLockUser);
      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { livecall, loadingError, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(livecall)) {
      return _c.loading(loadingError);
    } else if (livecall === null) {
      return _c.notfound(_c.translate("admin.livecalls.notfound"));
    }

    if (selectedTab === "info") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxLiveInfo()}
                {this.view__showBoxLiveId()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxLiveImage()}
                {this.view__showBoxAccess()}
                {this.view__showBoxTags()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxLiveInfo() {
    const { livecall, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.livecalls.titles.info")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={livecall.start_date}
                editValue={livecall.start}
                editType="datetime"
                label="Startzeit"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("start", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={livecall.title}
                editValue={livecall.title}
                editType="text"
                label="Titel"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("title", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxLiveId() {
    const { livecall, editMode } = this.state;
    const _c = this.props.context;

    const livecallTypes = _c.getLivecallTypes();
    let selectValues = {};
    Object.keys(livecallTypes).map((livecallTypeKey) => {
      selectValues[livecallTypeKey] = livecallTypes[livecallTypeKey].title;
      return null;
    });

    let v__livecall_id, v__livecallContentEmpty;

    if (livecall.type && !livecall.external_id) {
      v__livecallContentEmpty = (
        <div className="small warning callout" style={{ margin: "10px 0 0 0" }}>
          {_c.translate(`admin.livecalls.noContent.${livecall.type}`)}
        </div>
      );
    }

    if (livecall.type === "youtube") {
      v__livecall_id = (
        <Row margin="x">
          <Cell sm={24}>
            <AdminDataField
              value={livecall.external_id}
              editValue={livecall.external_id}
              editType="text"
              label="Youtube Live URL"
              editable={true}
              edit={editMode}
              onUpdate={(newValue) => {
                this.handle__edit("external_id", newValue);
              }}
            />
            {v__livecallContentEmpty}
          </Cell>
        </Row>
      );
    } else if (livecall.type === "vimeo") {
      v__livecall_id = (
        <Row margin="x">
          <Cell sm={24}>
            <AdminDataField
              value={livecall.external_id}
              editValue={livecall.external_id}
              editType="text"
              label="Vimeo Live Id"
              editable={true}
              edit={editMode}
              onUpdate={(newValue) => {
                this.handle__edit("external_id", newValue);
              }}
            />
            {v__livecallContentEmpty}
          </Cell>
        </Row>
      );
    } else {
      livecall.type = "embed";
      v__livecall_id = (
        <Row margin="x">
          <Cell sm={24}>
            <AdminDataField
              value={livecall.external_id}
              editValue={livecall.external_id}
              editType="textarea"
              label="HTML Embed Code"
              editable={true}
              edit={editMode}
              onUpdate={(newValue) => {
                this.handle__edit("external_id", newValue);
              }}
            />
            {v__livecallContentEmpty}
          </Cell>
        </Row>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.livecalls.titles.type")}</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={_c.getLivecallType(livecall.type)}
                editValue={livecall.type}
                editType="select"
                label="Live Call Typ"
                selectValues={selectValues}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("type", newValue);
                }}
              />
            </Cell>
          </Row>
          {v__livecall_id}
        </Grid>
      </Box>
    );
  }

  view__showBoxLiveImage() {
    const { livecall, editMode } = this.state;
    const _c = this.props.context;

    let livecallImage = (
      <div className="admin-livecall-noimage wrapper">
        <div className="center-vertically">
          {_c.translate("admin.livecalls.image.noImage")}
        </div>
      </div>
    );
    if (livecall.image) {
      livecallImage = <img src={livecall.image} alt="Vorschaubild" />;
    }

    let uploadButton = (
      <button className="button" onClick={this.showPopupImageUpload}>
        <Icon icon="upload" left />{" "}
        {_c.translate("admin.livecalls.image.uploadImage")}
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupImageDelete}>
        <Icon icon="trash" left />{" "}
        {_c.translate("admin.livecalls.image.deleteImage")}
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !livecall.image) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.livecalls.titles.image")}</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-livecall-image">{livecallImage}</div>
              <div className="admin-livecall-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showPopups() {
    const { uploadImageStatus, cropUrl, crop } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h3>
                      {_c.translate("admin.livecalls.image.uploadPopup.title")}
                    </h3>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate(
                            "admin.livecalls.image.uploadPopup.text"
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>{_c.translate("admin.livecalls.image.cropPopup.title")}</h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate(
                      "admin.livecalls.image.cropPopup.buttons.save.label"
                    )}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
        <MMDialog
          show={uploadImageStatus === "delete"}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.livecalls.image.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("buttons.deletePermanently"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ uploadImageStatus: null });
          }}
          onButton_2={this.handle__deleteImage}
        />
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  handle__deleteImage = () => {
    const { livecall } = this.state;
    livecall.image = null;
    this.setState({ livecall, uploadImageStatus: null });

    /*const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then(response => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });*/
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  uploadImage = () => {
    const { cropImage, crop, livecall, requestedId } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    const apiUrl = _c.apiUrl("admin.livecallImageUpload", { id: requestedId });

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          livecall.image = path;
          this.setState({ livecall, changesWereMade: true });
          this.hidePopupImageCrop();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  view__showBoxAccess() {
    const { livecall, editMode, courses, changesWereMade } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.livecalls.titles.access")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminAccessSelect
                accessSettingKey="livecall"
                courses={courses}
                access_granted={livecall.access_granted}
                access_granted_for={livecall.access_granted_for}
                changesWereMade={changesWereMade}
                editMode={editMode}
                onUpdate={({ access_granted, access_granted_for }) => {
                  livecall.access_granted = access_granted;
                  livecall.access_granted_for = access_granted_for;
                  this.setState({ livecall });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxTags() {
    const { livecall, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.livecalls.titles.tags")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={livecall.tags}
                editMode={editMode}
                onUpdate={(tags) => {
                  livecall.tags = tags;
                  this.setState({ livecall });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showLogs() {
    const { livecall } = this.state;

    if (!livecall || !livecall.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={livecall.logs} />
      </React.Fragment>
    );
  }

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { livecall } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("livecall", livecall.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { livecall } = this.state;
    livecall[property] = value;
    this.setState({ livecall, changesWereMade: true });
  }

  handle__stop = () => {
    let { livecall } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        _c
          .translate("live.stop.confirm")
          .replace("{livecallLength}", _c.setting("livecall_length"))
      )
    ) {
      const apiUrl = _c.apiUrl("admin.livecallStop", { id: livecall.id });

      axios
        .patch(
          apiUrl,
          {},
          {
            headers: _c.getHeaders(),
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { livecall } = apiResponseData;

              const original = JSON.parse(JSON.stringify(livecall));
              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  editMode: false,
                  livecall,
                  original,
                },
                () => {
                  //
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:livecall:stop:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:stop:delete");
        });
    }
  };

  handle__delete = () => {
    const { livecall } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.livecall", { id: livecall.id });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              _c.translate("admin.livecalls.messages.deleted.title"),
              _c
                .translate("admin.livecalls.messages.deleted.text")
                .replace("{livecall}", livecall.title),
              "success"
            );
            this.props.history.push(`${_c.config("urls.admin.livecalls")}`);
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:livecall:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:livecall:delete");
      });
  };

  handle__cancel = () => {
    const { requestedId, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("admin.livecalls.cancel.confirm"))) {
        if (requestedId === "new") {
          window.location = _c.config("urls.admin.livecalls");
        } else {
          let { livecall, original } = this.state;
          livecall = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, livecall });
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = _c.config("urls.admin.livecalls");
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { livecall, requestedId } = this.state;

    if (!livecall.title) {
      let extraText;
      if (!livecall.start) {
        extraText = _c.translate("admin.livecalls.errors.noStart");
      }
      window.alert(
        `${_c.translate("admin.livecalls.errors.noTitle")}${extraText}`
      );
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.livecall", { id: requestedId });

      // Prepare Start
      if (livecall.start) {
        livecall.start = _c.formatDatetimeForSaving(livecall.start);
      }

      axios
        .post(
          apiUrl,
          {
            livecall: livecall,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { livecall } = apiResponseData;

              if (requestedId === "new" && livecall && livecall.id) {
                window.location.href = _c
                  .custom("urls.admin.livecall")
                  .replace(":id", livecall.id);

                return;
              }

              const original = JSON.parse(JSON.stringify(livecall));
              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  editMode: false,
                  livecall,
                  original,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.livecalls.messages.saved.title"),
                    _c.translate("admin.livecalls.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:livecall:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:livecall:save");
        });
    }, 200);
  };

  init() {
    const { requestedId } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.livecall", { id: requestedId });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { livecall, courses } = apiResponseData;

            const original = JSON.parse(JSON.stringify(livecall));

            livecall.start = new Date(livecall.start);

            let pageTitle;
            if (requestedId === "new") {
              pageTitle = "Neuer Live Call";
            } else {
              pageTitle = `${livecall.title}`;
            }

            _c.setPageTitle(pageTitle, true);

            const bc1 = {
              id: "admin_livecall",
              to: `${_c.config("urls.admin.livecalls")}/${livecall.id}`,
              name: pageTitle,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            this.setState({ livecall, courses, original, breadcrumbs });

            if (requestedId === "new") {
              this.setState({ editMode: true });
            }

            this.setState({
              livecall,
              original,
              status: "LOADED",
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin.livecall:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin.livecall:init");
      });
  }
}

export default withContext(AdminLiveCall);
