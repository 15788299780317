import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import { Link } from "react-router-dom";
import { Icon } from "../../_foundation/_buttons";

class AdminTools extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_tools"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="tools" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              <h1>{_c.translate("admin.tools.title")}</h1>
              <p className="lead">{_c.translate("admin.tools.text")}</p>
            </Cell>
            <Cell
              sm={24}
              md={12}
              className="text-center medium-text-right"
            ></Cell>
          </Row>
        </Grid>
        {this.view__showTools()}
      </AdminLayout>
    );
  }

  view__showTools() {
    const _c = this.props.context;

    return (
      <div className="admin-tools">
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={8}>
              <Link to={_c.config("urls.admin.tools.import")} className="box">
                <div className="box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={6}>
                        <div className="wrapper admin-tools-icon">
                          <div className="center-vertically">
                            <Icon icon="upload" />
                          </div>
                        </div>
                      </Cell>
                      <Cell sm={24} md={18}>
                        <h3>{_c.translate("admin.tools.import.title")}</h3>
                        <div>{_c.translate("admin.tools.import.text")}</div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </Link>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.settings");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            //
          }
          _c.setPageTitle(_c.translate("pageTitles.admin.tools.tools"));
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:tools:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:tools:init");
      });
  }
}

export default withContext(AdminTools);
