import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import HomeItemCourses from "./items/HomeItemCourses";
import HomeItemCommunity from "./items/HomeItemCommunity";
import HomeItemNextLivecall from "./items/HomeItemNextLivecall";
import HomeItemRoutines from "./items/HomeItemRoutines";
import HomeItemVisionboardImage from "./items/HomeItemVisionboardImage";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";

import GridLayout from "react-grid-layout";
import axios from "axios";

class HomeGrid1 extends Component {
  state = {
    _updatableProps: ["homeInfo"],
    homeInfo: this.props.homeInfo,
    layout: [],
    gridColumns: 3,
    sortMode: false,
    layoutLoaded: false,
  };

  componentDidMount() {
    this.generateLayout();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] }, () => {
          this.generateLayout();
        });
      }
      return null;
    });
  }

  render() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    if (!homeInfo || !homeInfo.homeItems) {
      return _c.loading();
    }

    return (
      <div className="home-grid">
        <div className="show-for-small-only">{this.view__showMobileGrid()}</div>

        <div className="hide-for-small-only">
          {this.view__showSortSwitch()}
          {this.view__showGrid()}
        </div>
      </div>
    );
  }

  view__showSortSwitch() {
    const { sortMode } = this.state;
    const _c = this.props.context;

    let v__buttons;

    if (sortMode === true) {
      v__buttons = (
        <React.Fragment>
          <button
            className="small hollow secondary button"
            onClick={this.reset}
          >
            <Icon icon="refresh" left />{" "}
            {_c.translate("home.sort.switch.reset.button")}
          </button>
          &nbsp;
          <button
            className="small hollow primary button"
            onClick={() => {
              this.setState({ sortMode: false });
            }}
          >
            <Icon icon="times-circle" left />{" "}
            {_c.translate("home.sort.switch.on")}
          </button>
        </React.Fragment>
      );
    } else {
      v__buttons = (
        <React.Fragment>
          <button
            className="small hollow primary button"
            onClick={() => {
              this.setState({ sortMode: true });
            }}
          >
            <Icon icon="th" left /> {_c.translate("home.sort.switch.off")}
          </button>
        </React.Fragment>
      );
    }

    return <div className="home-grid-sort-switch">{v__buttons}</div>;
  }

  view__showMobileGrid() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    if (!homeInfo || !homeInfo.homeItems) {
      return _c.loading();
    }

    return (
      <Grid type="full">
        <Row margin="xy">
          {homeInfo.homeItems.map((homeItem, index) => {
            let v__itemContent = this.view__showHomeItem(homeItem);

            return (
              <Cell key={index} sm={24} md={8}>
                <div className="home-item-wrapper">{v__itemContent}</div>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );
  }

  view__showGrid() {
    const { homeInfo, layout, gridColumns, sortMode } = this.state;

    if (layout.length === 0 || !homeInfo || !homeInfo.homeItems) {
      return null;
    }

    const { homeItems } = homeInfo;

    return (
      <GridLayout
        className="home-sortable-grid hide-for-small-only"
        layout={layout}
        cols={gridColumns}
        rowHeight={220}
        width={1170}
        isResizable={false}
        isDraggable={sortMode}
        margin={[20, 20]}
        containerPadding={[0, 20]}
        compactType="horizontal"
        onLayoutChange={this.onLayoutChange}
      >
        {layout.map((layoutItem) => {
          let v__itemContent;

          let homeItemId = layoutItem.i.replace("grid_item_", "");

          let homeItem = null;
          homeItems.map((item) => {
            if (parseInt(item.id) === parseInt(homeItemId)) {
              homeItem = item;
            }
            return null;
          });

          if (homeItem !== null) {
            v__itemContent = this.view__showHomeItem(homeItem);

            let classSortMode = "";
            let wiggle;
            if (sortMode === true) {
              classSortMode = "home-sortable-grid-item-sortmode";
              wiggle = "wiggle";
            }

            return (
              <div
                className={`home-sortable-grid-item ${classSortMode}`}
                key={layoutItem.i}
              >
                <div className={wiggle}>
                  <div className="home-item-wrapper">{v__itemContent}</div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </GridLayout>
    );
  }

  view__showHomeItem(homeItem) {
    const { sortMode, homeInfo } = this.state;
    let v__itemContent;

    if (homeItem.type === "courses") {
      v__itemContent = <HomeItemCourses sortMode={sortMode} />;
    } else if (homeItem.type === "community") {
      v__itemContent = <HomeItemCommunity sortMode={sortMode} />;
    } else if (homeItem.type === "nextlivecall") {
      v__itemContent = (
        <HomeItemNextLivecall sortMode={sortMode} homeInfo={homeInfo} />
      );
    } else if (homeItem.type === "routines") {
      v__itemContent = <HomeItemRoutines sortMode={sortMode} />;
    } else if (homeItem.type === "visionboardimage") {
      v__itemContent = (
        <HomeItemVisionboardImage
          sortMode={sortMode}
          homeInfo={homeInfo}
          options={homeItem.options}
        />
      );
    }

    return v__itemContent;
  }

  generateLayout() {
    const { homeInfo, gridColumns } = this.state;

    if (!homeInfo || !homeInfo.homeItems) {
      return;
    }

    const layout = [];

    let lastX = 0;
    let lastY = 0;

    homeInfo.homeItems.map((item) => {
      const newItem = {
        i: `grid_item_${item.id}`,
        w: 1,
        h: 1,
      };

      if (
        item.options &&
        parseInt(item.options.x) >= 0 &&
        parseInt(item.options.y) >= 0
      ) {
        newItem.x = item.options.x;
        newItem.y = item.options.y;
      } else {
        newItem.x = lastX;
        newItem.y = lastY;
      }

      layout.push(newItem);

      lastX++;
      if (lastX >= gridColumns) {
        lastX = 0;
        lastY++;
      }

      return null;
    });

    this.setState({ layout });
  }

  onLayoutChange = (layout) => {
    const { layoutLoaded } = this.state;

    if (!layoutLoaded) {
      this.setState({ layoutLoaded: true });
      return;
    }

    const _c = this.props.context;

    //console.log(layout);

    const apiUrl = _c.apiUrl("home.sort");

    axios
      .patch(
        apiUrl,
        { layout: layout },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            _c.handleApiResponse(response.data, true);

            const { homeInfo } = response.data.data;
            if (this.props.onUpdate) {
              this.props.onUpdate({ homeInfo });
              this.generateLayout();
              /*_c.createNotifictation(
                _c.translate("home.sort.messages.sort.title"),
                _c.translate("home.sort.messages.sort.text"),
                "success"
              );*/
            }
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "home:sort:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "home:sort");
        }
      });
  };

  reset = () => {
    const _c = this.props.context;

    if (window.confirm(_c.translate("home.sort.switch.reset.confirm"))) {
      const apiUrl = _c.apiUrl("home.reset");

      axios
        .get(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              _c.handleApiResponse(response.data, true);

              const { homeInfo } = response.data.data;
              if (this.props.onUpdate) {
                this.props.onUpdate({ homeInfo });
                this.generateLayout();
                /*_c.createNotifictation(
                _c.translate("home.sort.messages.reset.title"),
                _c.translate("home.sort.messages.reset.text"),
                "success"
              );*/
              }
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "home:reset:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "home:reset");
          }
        });
    }
  };
}

export default withContext(HomeGrid1);
