import React, { Component } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../_foundation/_grid";
import Box from "../layout/Box";
import AdminDataField from "../admin/AdminDataField";
import { Icon } from "../_foundation/_buttons";
import { isUndefined } from "util";
import MMDialog from "../mmcomponents/mmdialog/MMDialog";

import MMPopup from "../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import cloneDeep from "lodash/cloneDeep";
import MMTabs from "../mmcomponents/mmtabs/MMTabs";
import ReactTooltip from "react-tooltip";

class MyAccount extends Component {
  state = {
    account: undefined,
    original: undefined,
    tabs: {},
    selectedTab: "account",
    editMode: true,
    loadingError: false,
    changesWereMade: false,
    popupImageUpload: false,
    popupImageCrop: false,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 1 / 1,
    },
    showDialogDeleteAvatar: false,
    selectedLink: null,
    newLink: null,
    linkChangesWereMade: false,
    showDialogDeleteLink: false,
    selectedLinkForDelete: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <DefaultLayout breadcrumbs={["account"]} page="account" help="myAccount">
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h1>{_c.translate("pageTitles.account")}</h1>
              {this.view__showPageButtons()}
            </Cell>
          </Row>
          {this.view__showTabs()}
          {this.view__showContent()}
        </Grid>
        {this.view__showPageButtons()}
        {this.view__showPopups()}
      </DefaultLayout>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="page-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button className="small primary button" onClick={this.handle__save}>
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      );
    }
  }

  view__showPopups() {
    const {
      popupImageUpload,
      popupImageCrop,
      cropUrl,
      crop,
      showDialogDeleteAvatar,
    } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={popupImageUpload}
          size="medium"
          nopadding
          handleClose={() => {
            this.hidePopupImageUpload();
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h2>{_c.translate("account.avatar.popup.title")}</h2>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate("account.avatar.popup.text"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={popupImageCrop}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h2>{_c.translate("account.avatar.popup.cropTitle")}</h2>
          </div>

          <ReactCrop
            src={cropUrl}
            crop={crop}
            onChange={this.onCrop}
            circularCrop={true}
          />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadAvatar}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate("account.avatar.popup.cropButton")}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
        <MMDialog
          show={showDialogDeleteAvatar}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("account.avatar.deleteMessage"),
              }}
            />
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDeleteAvatar: false });
          }}
          onButton_2={this.handle__deleteAvatar}
        />
      </React.Fragment>
    );
  }

  view__showContent() {
    const { account, loadingError, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(account)) {
      return _c.loading(loadingError);
    } else if (account === null) {
      return _c.notfound("Account nicht gefunden");
    }

    if (selectedTab === "account") {
      return (
        <Row margin="xy">
          <Cell sm={24} md={12}>
            {this.view__showBoxNameInfo()}
            {this.view__showBoxContactInfo()}
            {this.view__showBoxAddressInfo()}
          </Cell>
          <Cell sm={24} md={12}>
            {this.view__showBoxAvatar()}
            {this.view__showBoxPersonalInfo()}
          </Cell>
        </Row>
      );
    } else if (selectedTab === "profile") {
      return (
        <Row margin="xy">
          <Cell sm={24} md={12}>
            {this.view__showBoxBio()}
            {this.view__showBoxOffering()}
            {this.view__showBoxSearching()}
          </Cell>
          <Cell sm={24} md={12}>
            {this.view__showBoxProfession()}
          </Cell>
        </Row>
      );
    } else if (selectedTab === "links") {
      return (
        <Row margin="xy">
          <Cell sm={24} md={12}>
            {this.view__showBoxLinks()}
          </Cell>
          <Cell sm={24} md={12}></Cell>
        </Row>
      );
    }
  }

  view__showBoxLinks() {
    const { account, selectedLink, newLink, showDialogDeleteLink } = this.state;
    const _c = this.props.context;

    const platforms = _c.custom("community.platforms");

    let v__popup;

    if (selectedLink) {
      let v__title = _c.translate("account.profile.links.popup.edit.title");

      if (selectedLink.id === "new") {
        v__title = _c.translate("account.profile.links.popup.add.title");
      }

      let types = _c.custom("community.platforms");

      v__popup = (
        <MMPopup
          size="medium"
          show={selectedLink ? true : false}
          handleClose={() => {
            //
          }}
        >
          <h3>{v__title}</h3>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={10}>
                <label>
                  <select
                    value={selectedLink.type}
                    onChange={(e) => {
                      selectedLink.type = e.target.value;

                      const { linkChangesWereMade } = this.state;
                      if (
                        platforms[selectedLink.type] &&
                        linkChangesWereMade === false
                      ) {
                        selectedLink.url =
                          platforms[selectedLink.type].defaultUrl;
                      }

                      this.setState({ selectedLink });
                    }}
                  >
                    <option value="unknown"></option>
                    {Object.keys(types).map((typeKey, index) => {
                      return (
                        <option key={index} value={typeKey}>
                          {types[typeKey].name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </Cell>
              <Cell sm={24} md={14}>
                <label>
                  <input
                    type="text"
                    placeholder=""
                    value={selectedLink.url}
                    onChange={(e) => {
                      selectedLink.url = e.target.value;

                      let linkChangesWereMade = true;
                      if (!selectedLink.url) {
                        linkChangesWereMade = false;
                      }

                      this.setState({
                        selectedLink,
                        linkChangesWereMade,
                      });
                    }}
                  />
                </label>
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={24}>
                <div className="popup-buttons">
                  <button
                    className="small primary hollow button"
                    onClick={this.handle__cancelEditLink}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="small primary button"
                    onClick={this.handle__saveLink}
                  >
                    <Icon icon="check" left /> {_c.translate("buttons.save")}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      );
    }

    let v__links;

    if (account && account.profile && account.profile.links) {
      v__links = (
        <React.Fragment>
          {account.profile.links.map((link, index) => {
            let v__linkIcon = <Icon icon="link" />;
            let v__linkType = link.type;

            if (platforms[link.type]) {
              v__linkType = platforms[link.type].name;
            }

            if (platforms[link.type]) {
              v__linkIcon = <Icon icon={platforms[link.type].icon} />;
            }

            return (
              <Box size="small" key={index}>
                <ReactTooltip
                  place={_c.getToolTipSetting("position")}
                  type={_c.getToolTipSetting("type")}
                  effect={_c.getToolTipSetting("effect")}
                />
                <Grid type="full">
                  <Row>
                    <Cell sm={4} md={2}>
                      <div className="account-profile-link-icon">
                        {v__linkIcon}
                      </div>
                    </Cell>
                    <Cell sm={20} md={6}>
                      <div className="account-profile-link-type">
                        {v__linkType}
                      </div>
                    </Cell>
                    <Cell sm={24} md={13}>
                      <div className="account-profile-link-url">
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.url}
                        </a>
                      </div>
                    </Cell>
                    <Cell sm={24} md={3} className="text-right">
                      <button
                        onClick={() => {
                          this.setState({
                            showDialogDeleteLink: true,
                            selectedLinkForDelete: link,
                          });
                        }}
                        className="account-profile-link-button"
                        data-tip={_c.translate(
                          "account.profile.links.buttons.delete.tooltip"
                        )}
                      >
                        <Icon icon="trash" />
                      </button>
                      <button
                        onClick={() => {
                          this.setState({ selectedLink: cloneDeep(link) });
                        }}
                        className="account-profile-link-button"
                        data-tip={_c.translate(
                          "account.profile.links.buttons.edit.tooltip"
                        )}
                      >
                        <Icon icon="edit" />
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            );
          })}
          <MMDialog
            show={showDialogDeleteLink}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: _c.translate("account.profile.links.delete.confirm"),
                }}
              />
            }
            button_1={{
              title: _c.translate("buttons.cancel"),
              icon: <Icon icon="times" left />,
              type: "primary hollow",
            }}
            button_2={{
              title: _c.translate("buttons.deletePermanently"),
              icon: <Icon icon="trash" left />,
              type: "primary",
            }}
            onButton_1={() => {
              this.setState({
                showDialogDeleteLink: false,
                selectedLinkForDelete: null,
              });
            }}
            onButton_2={this.handle__deleteLink}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3 style={{ marginBottom: "0px" }}>
                  {_c.translate("account.profile.links.title")}
                </h3>
              </Cell>
            </Row>
          </Grid>
        </Box>
        {v__links}
        <button
          className="account-profile-add-link"
          onClick={() => {
            this.setState({ selectedLink: cloneDeep(newLink) });
          }}
        >
          <Box size="small">
            <Grid type="full">
              <Row margin="x">
                <Cell sm={24}>
                  <Icon icon="plus" left />{" "}
                  {_c.translate("account.profile.links.add.label")}
                </Cell>
              </Row>
            </Grid>
          </Box>
        </button>

        {v__popup}
      </React.Fragment>
    );
  }

  view__showBoxBio() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.profile.bio.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.profile.bio}
                editValue={account.profile.bio}
                editType="textarea"
                size="large"
                label={_c.translate("account.profile.bio.title")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__profileEdit("bio", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxOffering() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.profile.offering.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.profile.offering}
                editValue={account.profile.offering}
                editType="textarea"
                size="medium"
                label={_c.translate("account.profile.offering.title")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__profileEdit("offering", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxSearching() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.profile.searching.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.profile.searching}
                editValue={account.profile.searching}
                editType="textarea"
                size="medium"
                label={_c.translate("account.profile.searching.title")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__profileEdit("searching", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxProfession() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.profile.profession.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.profile.profession}
                editValue={account.profile.profession}
                editType="text"
                label={_c.translate("account.profile.profession.title")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__profileEdit("profession", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxNameInfo() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.name.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.firstname}
                editValue={account.firstname}
                editType="text"
                label={_c.translate("account.name.firstname")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("firstname", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.lastname}
                editValue={account.lastname}
                editType="text"
                label={_c.translate("account.name.lastname")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("lastname", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxPersonalInfo() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    let selectValuesGenders = [];
    selectValuesGenders = _c.getGenders();

    let accountGender = account.gender;
    if (!accountGender) {
      accountGender = "";
    }

    let accountGender_f = _c.getGender(account.gender);
    if (!accountGender_f) {
      accountGender_f = "";
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.personal.title")}</h3>
            </Cell>
          </Row>

          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.birthday_date || ""}
                editValue={account.birthday || ""}
                editType="date"
                isClearable={true}
                label={_c.translate("account.personal.birthday")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("birthday", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={accountGender_f}
                editValue={accountGender}
                editType="select"
                label={_c.translate("account.personal.gender")}
                selectValues={selectValuesGenders}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("gender", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxContactInfo() {
    const { account, original, editMode } = this.state;
    const _c = this.props.context;

    let emailNote = null;
    if (editMode === true && account.email !== original.email) {
      emailNote = (
        <div className="small warning callout">
          <small>
            <strong>
              <Icon icon="exclamation-circle" />{" "}
              {_c.translate("account.contact.emailWarning.title")}:
            </strong>{" "}
            {_c.translate("account.contact.emailWarning.text")}
          </small>
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.contact.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              {emailNote}
              <AdminDataField
                value={account.email}
                editValue={account.email}
                editType="text"
                label={_c.translate("account.contact.email")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("email", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.phone}
                editValue={account.phone}
                editType="text"
                label={_c.translate("account.contact.phone")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("phone", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAddressInfo() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.address.title")}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.street}
                editValue={account.street}
                editType="text"
                label={_c.translate("account.address.street")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("street", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={6}>
              <AdminDataField
                value={account.zip}
                editValue={account.zip}
                editType="text"
                label={_c.translate("account.address.zip")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("zip", newValue);
                }}
              />
            </Cell>
            <Cell sm={24} md={18}>
              <AdminDataField
                value={account.city}
                editValue={account.city}
                editType="text"
                label={_c.translate("account.address.city")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("city", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={account.country}
                editValue={account.country}
                editType="text"
                label={_c.translate("account.address.country")}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("country", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAvatar() {
    const { account } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("account.avatar.title")}</h3>
            </Cell>
          </Row>
          <Row margein="x">
            <Cell sm={24} md={17}>
              <div
                style={{
                  display: account.avatar ? "block" : "none",
                }}
              >
                <button
                  className="account-delete-avatar"
                  onClick={() => {
                    this.setState({ showDialogDeleteAvatar: true });
                  }}
                >
                  {_c.translate("account.avatar.delete")}
                </button>
              </div>

              <div>
                <button
                  className="primary hollow button"
                  style={{ marginBottom: "0" }}
                  onClick={() => {
                    this.showPopupImageUpload();
                  }}
                >
                  {_c.translate("account.avatar.uploadButton")}
                </button>
              </div>
            </Cell>
            <Cell sm={24} md={7}>
              {this.view__showAvatar()}
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  view__showAvatar() {
    const { account } = this.state;
    if (account.avatar) {
      return (
        <img src={account.avatar} alt="" className="account-avatar-preview" />
      );
    } else {
      return null;
    }
  }

  handle__deleteAvatar = () => {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("user.avatar.delete");

    axios
      .patch(apiUrl, {}, { headers: _c.headers })
      .then((response) => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  showPopupImageUpload() {
    this.setState({ popupImageUpload: true });
  }

  hidePopupImageUpload() {
    this.setState({ popupImageUpload: false });
  }

  showPopupImageCrop() {
    this.setState({ popupImageCrop: true });
  }

  hidePopupImageCrop() {
    this.setState({ popupImageCrop: false });
  }

  uploadAvatar = () => {
    const { cropImage, crop, account } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    this.hidePopupImageCrop();
    _c.setIsSaving(true, 4);
    _c.setSavingType();

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    let apiUrl = _c.apiUrl("user.avatar.upload");

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          account.avatar = path;
          this.setState({ account });
          _c.user.avatar = account.avatar;
        }

        _c.setIsSaving(false);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  handle__edit(property, value) {
    const { account } = this.state;
    account[property] = value;
    this.setState({ account, changesWereMade: true });
  }

  handle__profileEdit(property, value) {
    const { account } = this.state;
    const { profile } = account;
    profile[property] = value;
    this.setState({ account, changesWereMade: true });
  }

  handle__cancel = () => {
    const { account, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("account.cancel.confirm"))) {
        this.props.history.push(
          _c
            .custom("urls.communityProfile")
            .replace(":username", account.username)
        );
      } else {
        return false;
      }
    } else {
      this.props.history.push(
        _c
          .custom("urls.communityProfile")
          .replace(":username", account.username)
      );
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { account } = this.state;

    if (!account.firstname || !account.email) {
      window.alert(_c.translate("account.errors.nameEmail"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let apiUrl = _c.apiUrl("user.save");

      // Prepare Birthday
      if (account.birthday) {
        account.birthday = _c.formatDateForSaving(account.birthday);
      }

      axios
        .patch(
          apiUrl,
          {
            account: account,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { account, user } = response.data;

          _c.updateUser(user);

          const original = JSON.parse(JSON.stringify(account));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: true,
              account,
              original,
            },
            () => {
              _c.createNotifictation(
                _c.translate("account.messages.saved.title"),
                _c.translate("account.messages.saved.text"),
                "success"
              );
              /*this.props.history.push(
                _c
                  .custom("urls.communityProfile")
                  .replace(":username", account.username)
              );*/
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  handle__deleteLink = () => {
    const { selectedLinkForDelete } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("user.profileLink", {
      id: selectedLinkForDelete.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { user } = response.data;

            _c.setIsSaving(false);
            this.setState(
              {
                account: user,
                selectedLinkForDelete: null,
                showDialogDeleteLink: false,
              },
              () => {
                _c.updateUser(user);
                _c.createNotifictation(
                  _c.translate("account.profile.links.messages.deleted.title"),
                  _c.translate("account.profile.links.messages.deleted.text"),
                  "success"
                );
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "account:profile:link:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "account:profile:link:delete");
      });
  };

  handle__cancelEditLink = () => {
    this.setState({ selectedLink: null, linkChangesWereMade: false });
  };

  handle__saveLink = () => {
    const _c = this.props.context;
    const { selectedLink } = this.state;

    if (
      !selectedLink.type ||
      selectedLink.type === "unknown" ||
      !selectedLink.url
    ) {
      window.alert(_c.translate("account.profile.links.errors.noTypeUrl"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let apiUrl = _c.apiUrl("user.profileLink", { id: selectedLink.id });

      axios
        .post(
          apiUrl,
          {
            link: selectedLink,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { user } = response.data;

          _c.setIsSaving(false);
          this.setState(
            {
              account: user,
              selectedLink: null,
              linkChangesWereMade: false,
            },
            () => {
              _c.updateUser(user);
              _c.createNotifictation(
                _c.translate("account.profile.links.messages.saved.title"),
                _c.translate("account.profile.links.messages.saved.text"),
                "success"
              );
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("user.load");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const data = response.data.data;
            const { account, newLink } = data;
            const original = cloneDeep(account);

            newLink.id = "new";

            const tabs = {
              account: {
                name: _c.translate("account.tabs.account"),
                icon: "user",
              },
              profile: {
                name: _c.translate("account.tabs.profile"),
                icon: "id-card-o",
              },
              links: {
                name: _c.translate("account.tabs.links"),
                icon: "instagram",
              },
            };

            if (_c.setting("community_profile_allow_sociallinks") !== true) {
              delete tabs.links;
            }

            this.setState(
              {
                tabs,
                account,
                newLink,
                original,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }
          _c.setPageTitle(_c.translate("pageTitles.account"));
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "user:account:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "user:account:init");
      });
  }
}

export default withContext(MyAccount);
