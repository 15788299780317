import React, { Component } from "react";
import { Icon } from "../../../_foundation/_buttons";
import withContext from "../../../../context/contextHOC";
import axios from "axios";

class DownloadList extends Component {
  state = {
    downloads: this.props.downloads,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }
  }

  render() {
    const { downloads } = this.state;
    const _c = this.props.context;

    if (!downloads) {
      return null;
    }

    return (
      <div className="course-downloads">
        <div className="course-category-title">
          <Icon icon="download" left />
          {_c.translate("courses.downloads.title")}
        </div>
        <ul>
          {downloads.map((download, index) => {
            let v__downloadIcon = _c.getDownloadType(download.type);

            return (
              <li key={index}>
                <a
                  href={download.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    this.handle__downloadClick(download.id);
                  }}
                >
                  {v__downloadIcon} {download.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  handle__downloadClick(downloadId) {
    const _c = this.props.context;

    let url = _c.apiUrl("courses.downloadClick", {
      downloadId,
    });

    axios
      .patch(
        url,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          //
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        //_c.checkError(error);
      });
  }
}

export default withContext(DownloadList);
