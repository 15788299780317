import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Link } from "react-router-dom";
import { Icon } from "../../_foundation/_buttons";

class CommunityAreas extends Component {
  state = {
    areas: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("community");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("community_main_layout");
    if (!layout) {
      layout = "layout1";
    }

    let buttons, menu;
    if (_c.isAdmin()) {
      buttons = [
        {
          class: "primary hollow",
          label: _c.translate("breadcrumbs.community.edit"),
          href: _c.config("urls.admin.community"),
        },
      ];
    }

    let v__content = (
      <div className={`community-${layout}`}>
        <CommunityLayout
          view="areas"
          layout={layout}
          breadcrumbs={breadcrumbs}
          user={_c.getUser()}
          help="communityAreas"
          buttons={buttons}
          menu={menu}
        >
          <div className="community-header">
            <h1>{_c.translate("community.title")}</h1>
          </div>
          {this.view__showAreas()}
        </CommunityLayout>
      </div>
    );

    return <div className="community-areas">{v__content}</div>;
  }

  view__showAreas() {
    const { areas, status } = this.state;
    const _c = this.props.context;

    if (!areas || status === "INIT" || status === "LOADING") {
      return _c.loading();
    }

    return (
      <React.Fragment>
        {areas.map((area, index) => {
          return (
            <div className="community-areas-area" key={index}>
              <h2>{area.name}</h2>
              {this.view__showBoardCellTitles()}
              <div className="community-area-boards">
                {this.view__showBoards(area)}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  view__showBoardCellTitles() {
    const _c = this.props.context;

    return (
      <div className="board-cell-titles hide-for-small-only">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              &nbsp;
            </Cell>
            <Cell sm={12} md={2}>
              {_c.translate("community.areas.cellTitles.threadCount")}
            </Cell>
            <Cell sm={12} md={2}>
              {_c.translate("community.areas.cellTitles.postCount")}
            </Cell>
            <Cell sm={12} md={8}>
              {_c.translate("community.areas.cellTitles.lastPost")}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showBoards(area) {
    const _c = this.props.context;

    let v__content;
    if (!area.boards || area.boards.length === 0) {
      v__content = (
        <div className="message">
          {_c.translate("community.areas.boards.messages.noboards")}
        </div>
      );
    } else {
      v__content = (
        <React.Fragment>
          {area.boards.map((board, index) => {
            let v__boardName = <div className="board-name">{board.name}</div>;
            if (board.locked === true) {
              v__boardName = (
                <div className="board-name">
                  <Icon icon="lock" /> {board.name}
                </div>
              );
            }

            let v__boardDescription;
            if (board.description) {
              v__boardDescription = (
                <div className="board-description">{board.description}</div>
              );
            }

            let boardUrl = _c.config("urls.communityBoard");
            boardUrl = boardUrl.replace(":boardSlug", board.slug);

            let v__link = (
              <div className="padding">
                {v__boardName}
                {v__boardDescription}
              </div>
            );

            let extraClasses;
            if (board.locked === true) {
              extraClasses = `${extraClasses} locked`;
              v__link = <div>{v__link}</div>;
            } else {
              v__link = (
                <Link to={boardUrl} className="clickable">
                  {v__link}
                </Link>
              );
            }

            let v__threadCount = board.thread_count;
            let v__postCount = board.post_count;

            let v__lastPost = <div>---</div>;
            if (board.last_post) {
              v__lastPost = (
                <div>
                  <div>
                    Von <span>{board.last_post.author.displayname}</span>
                  </div>
                  <div>am {board.last_post.created_at_f}</div>
                </div>
              );
            }

            return (
              <div
                className={`${extraClasses} community-area-boards-board`}
                key={index}
              >
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={12}>
                      <div>
                        <div className="wrapper">
                          <div className="center-vertically- padding">
                            {v__link}
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={12} md={2}>
                      <div className="wrapper">
                        <div className="center-vertically padding">
                          <div className="board-thread-count">
                            {v__threadCount}
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={12} md={2}>
                      <div className="wrapper">
                        <div className="center-vertically padding">
                          <div className="board-post-count">{v__postCount}</div>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={12} md={8}>
                      <div className="wrapper">
                        <div className="center-vertically padding">
                          <div className="board-last-post">{v__lastPost}</div>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return <div className="community-areas-boards">{v__content}</div>;
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("community.areas");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, userStatus } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { areas } = communityData;
            this.setState({
              areas,
              userStatus,
              status: "LOADED",
            });
            _c.setPageTitle(_c.translate("pageTitles.communityAreas"));
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:areas:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:areas:init");
      });
  }
}

export default withContext(CommunityAreas);
