import { cloneDeep } from "lodash";
import axios from "axios";

const localStorageKeys = {
  affiliateIdentifier: "mm_ref",
  affiliateRegisterCache: "mm_affiliate_cache",
};

const affiliateTerms = {
  commission: 20,
  billing_period: "alle 3 Monate",
  billing_period_dates:
    "zum 15.02., zum 15.05., zum 15.08. und zum 15.11. des Jahres",
  billing_min: 60,
  timelimit: 90,
};

const initAffiliate = {
  firstname: "",
  lastname: "",
  email: "",
  country: "Deutschland",
};

const AffiliateHelper = {
  setAffiliate: function (affiliateIdentifier) {
    try {
      localStorage.setItem(
        localStorageKeys.affiliateIdentifier,
        affiliateIdentifier
      );
    } catch (e) {
      //
    }
  },
  countClick: function (affiliateIdentifier, _c, callback) {
    const apiUrl = _c.apiUrl("affiliateClick");

    const referrer = document.referrer;

    axios
      .post(
        apiUrl,
        { affiliate: affiliateIdentifier, referrer },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        if (callback) callback();
      })
      .catch((error) => {
        if (callback) callback();
      });
  },
  saveAffiliateRegisterCache: function (affiliate) {
    try {
      localStorage.setItem(
        localStorageKeys.affiliateRegisterCache,
        JSON.stringify(affiliate)
      );
    } catch (e) {
      //
    }
  },
  clearAffiliateRegisterCache: function () {
    try {
      localStorage.removeItem(localStorageKeys.affiliateRegisterCache);
    } catch (e) {
      //
    }
  },
  loadAffiliateRegisterCache: function () {
    let affiliate = cloneDeep(initAffiliate);

    try {
      if (localStorage.getItem(localStorageKeys.affiliateRegisterCache))
        affiliate = localStorage.getItem(
          localStorageKeys.affiliateRegisterCache
        );
      if (affiliate) affiliate = JSON.parse(affiliate);
    } catch (e) {
      //
    }

    return affiliate;
  },
  getTerm: function (termKey) {
    return affiliateTerms[termKey];
  },
  signupAffiliate: function (_c, affiliate, callback) {
    let apiUrl = _c.apiUrl("affiliateSignup");

    axios
      .post(
        apiUrl,
        { affiliate },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        console.log("Status: ", response.data.status);
        if (response.data.status === "SUCCESS")
          this.clearAffiliateRegisterCache();
        if (callback) callback({ status: response.data.status });
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error:", _c.handleError(error, "signupAffiliate"));
          if (callback) callback({ status: "ERROR" });
        }
      });
  },
};

export default AffiliateHelper;
