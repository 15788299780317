import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import { cloneDeep } from "lodash";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import AdminDataField from "../AdminDataField";
import axios from "axios";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

class AdminCourseDownloads extends Component {
  state = {
    _updatableProps: ["course", "downloads", "newDownload"],
    course: this.props.course,
    downloads: this.props.downloads,
    newDownload: this.props.newDownload,
    selectedDownload: null,
    changesWereMade: false,
    showDialogDelete: false,
  };

  render() {
    const { downloads, course } = this.state;
    const _c = this.props.context;

    if (!downloads) {
      return null;
    }

    if (!course.id || course.id === "new") {
      return (
        <p className="text-center">
          <em>{_c.translate("admin.courses.lessons.noNew")}</em>
        </p>
      );
    }

    return (
      <React.Fragment>
        {this.view__showDownloads()}
        {this.view__showPopups()}
        {this.view__showDialogs()}
        <ul id="adminSortDownloads" className="admin-course-downloads" />
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  view__showDialogs() {
    const { showDialogDelete, selectedDownload } = this.state;
    const _c = this.props.context;

    if (!selectedDownload) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <p
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.courses.downloads.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("admin.courses.downloads.delete.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("admin.courses.downloads.delete.yes"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showPopups() {
    const { selectedDownload, showDialogDelete } = this.state;
    const _c = this.props.context;

    let output;

    if (selectedDownload && !showDialogDelete) {
      const downloadTypes = _c.getDownloadTypes("select");

      output = (
        <MMPopup show={true} size="small" handleClose={this.handle__cancel}>
          <h3>
            {selectedDownload.name ||
              _c.translate("admin.courses.downloads.download.download")}
          </h3>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.name}
                  editValue={selectedDownload.name}
                  editType="text"
                  label={_c.translate(
                    "admin.courses.downloads.download.name.title"
                  )}
                  placeholder={_c.translate(
                    "admin.courses.downloads.download.name.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("name", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.type}
                  editValue={selectedDownload.type}
                  editType="select"
                  selectValues={downloadTypes}
                  label={_c.translate("admin.courses.downloads.download.type")}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("type", newValue);
                  }}
                />
              </Cell>
            </Row>
            {/*<Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.url}
                  editValue={selectedDownload.url}
                  editType="text"
                  label={_c.translate(
                    "admin.courses.downloads.download.url.title"
                  )}
                  placeholder={_c.translate(
                    "admin.courses.downloads.download.url.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("url", newValue);
                  }}
                />
              </Cell>
                </Row>*/}
            <Row margin="y">
              <Cell sm={24} md={24}>
                <small style={{ wordWrap: "break-word" }}>
                  URL:{" "}
                  <a
                    href={selectedDownload.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedDownload.url}
                  </a>
                </small>
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <p>
                  <strong>Neue Datei hochladen (optional)</strong>
                </p>
                <input
                  type="file"
                  onChange={(e) => {
                    this.handle__edit("file", e.target.files[0]);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24} className="text-center">
                <AdminDataField
                  value={selectedDownload.published}
                  editValue={selectedDownload.published}
                  editType="singleCheckbox"
                  label={_c.translate(
                    "admin.courses.downloads.download.published"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("published", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24}>
                <div style={{ margin: "1rem 0" }}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={12}>
                        <button
                          className="small primary hollow button"
                          onClick={() => {
                            this.setState({ showDialogDelete: true });
                          }}
                        >
                          <Icon icon="trash" left />{" "}
                          {_c.translate(
                            "admin.courses.downloads.delete.button"
                          )}
                        </button>
                      </Cell>
                      <Cell sm={24} md={12} className="text-right">
                        <button
                          className="small primary hollow button"
                          onClick={this.handle__cancel}
                        >
                          <Icon icon="times" left />{" "}
                          {_c.translate("buttons.cancel")}
                        </button>
                        <button
                          className="small primary button"
                          onClick={this.handle__save}
                        >
                          <Icon icon="check" left />{" "}
                          {_c.translate("buttons.save")}
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      );
    }

    return <React.Fragment>{output}</React.Fragment>;
  }

  view__showDownloads() {
    const { downloads, newDownload } = this.state;
    const _c = this.props.context;

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-downloads">{children}</ul>;
    });

    const SortableItem = sortableElement(({ download }) => {
      let v__type = _c.getDownloadType(download.type);

      let v__published;
      if (download.published === true) {
        v__published = (
          <span>
            <Icon icon="check" left />{" "}
            {_c.translate("admin.courses.downloads.download.isPublished")}
          </span>
        );
      } else {
        v__published = (
          <span>
            <Icon icon="minus" left />{" "}
            {_c.translate("admin.courses.downloads.download.isNotPublished")}
          </span>
        );
      }

      return (
        <li className="admin-course-download">
          <Grid type="full">
            <Row>
              <Cell sm={2} md={2}>
                <div className="course-categories-drag-handle-wrapper">
                  <DragHandle />
                </div>
              </Cell>
              <Cell sm={22} md={22}>
                <Box
                  size="small"
                  onClick={() => {
                    this.setState({ selectedDownload: cloneDeep(download) });
                  }}
                >
                  <h3>{download.name}</h3>
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={24} md={24} className="text-center">
                        {v__published}
                      </Cell>
                    </Row>
                    <Row margin="xy">
                      <Cell sm={24} md={12}>
                        <div className="admin-course-download-info">
                          {v__type}
                        </div>
                      </Cell>
                      <Cell sm={24} md={12} className="text-right">
                        {_c.translate(
                          "admin.courses.downloads.download.clicks"
                        )}
                        : {download.clicks}
                      </Cell>
                    </Row>
                    <Row margin="xy">
                      <Cell sm={24} md={24}>
                        URL: {download.url}
                      </Cell>
                    </Row>
                  </Grid>
                </Box>
              </Cell>
            </Row>
          </Grid>
        </li>
      );
    });

    return (
      <React.Fragment>
        <SortableContainer
          onSortEnd={this.onDownloadsSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSortDownloads")}
        >
          {downloads.map((download, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                download={download}
              />
            );
          })}
        </SortableContainer>
        <div className="admin-courses-add-download">
          <Box
            size="small"
            onClick={() => {
              this.setState({ selectedDownload: cloneDeep(newDownload) });
            }}
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.courses.downloads.download.add")}
          </Box>
        </div>
      </React.Fragment>
    );
  }

  onDownloadsSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    let { downloads, course } = this.state;
    downloads = arrayMove(downloads, oldIndex, newIndex);

    const newOrder = [];
    downloads.map((download) => {
      return newOrder.push(download.id);
    });

    this.setState({ downloads }, () => {
      const apiUrl = _c.apiUrl("admin.courseSortDownloads", {
        courseId: course.id,
      });

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    course,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.courses.downloads.messages.sorted.title"
                      ),
                      _c.translate(
                        "admin.courses.downloads.messages.sorted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:course:downloads:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:course:downloads:sort");
          });
      }, 200);
    });
  };

  handle__edit(property, value) {
    const { selectedDownload } = this.state;
    selectedDownload[property] = value;
    this.setState({ selectedDownload, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let confirmCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(_c.translate("admin.courses.downloads.cancel.confirm"))
      ) {
        confirmCancel = true;
      }
    } else {
      confirmCancel = true;
    }

    if (confirmCancel === true) {
      this.setState({
        selectedDownload: null,
        changesWereMade: false,
      });
    }

    return confirmCancel;
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, selectedDownload } = this.state;

    if (!selectedDownload.name) {
      window.alert(_c.translate("admin.courses.downloads.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.courseDownload", {
        courseId: course.id,
        downloadId: selectedDownload.id,
      });

      const formData = new FormData();

      if (selectedDownload.file) {
        formData.append("file", selectedDownload.file);
        formData.append("name", selectedDownload.file.name);
      }
      formData.append("download", JSON.stringify(selectedDownload));

this.setState({selectedDownload: null})

      axios
        .post(apiUrl, formData, {
          headers: _c.headers,
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedDownload: null,
                  showDialogDelete: false,
                  course,
                  downloads: course.downloads,
                },
                () => {
                  if (this.props.onUpdate) {
                    this.props.onUpdate(course);
                  }
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate(
                      "admin.courses.downloads.messages.saved.title"
                    ),
                    _c.translate("admin.courses.downloads.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:download:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:download:save");
        });
    }, 200);
  };

  handle__delete = () => {
    const { course, selectedDownload } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.courseDownload", {
      courseId: course.id,
      downloadId: selectedDownload.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { course } = apiResponseData;

            _c.setIsSaving(false);

            this.setState(
              {
                changesWereMade: false,
                selectedDownload: null,
                showDialogDelete: false,
                course,
                downloads: course.downloads,
              },
              () => {
                if (this.props.onUpdate) {
                  this.props.onUpdate(course);
                }
                _c.cancelEditLock();
                _c.createNotifictation(
                  _c.translate(
                    "admin.courses.downloads.messages.deleted.title"
                  ),
                  _c.translate("admin.courses.downloads.messages.deleted.text"),
                  "success"
                );
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:course:download:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:course:download:delete");
      });
  };
}

export default withContext(AdminCourseDownloads);
